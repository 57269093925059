import React, { useState, useRef, useEffect } from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { toast } from "react-hot-toast";
import Moment from "react-moment";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";

import DateRangePicker from "react-bootstrap-daterangepicker";
import Multiselect from "multiselect-react-dropdown";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import {
  dashboardSelector,
  clearState,
  fetchdashboard,
} from "../../app/features/Dashboard/DashboardSlice";
import {
  CommonSelector,
  updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";
import {
  campaignManagerSelector,
  dealdetail,
  deal_currency,
  getowners,
  pipelinefilter,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import {
  LineItemsSelector,
  getInventories
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import Loader from "../Loader";
import Subtitle from "../Subtitle/subtitle";
const Home = () => {
  const navigate = useNavigate()
  const [disabledatepicker, setdiabledpicker] = useState(true);
  const { deal_detail, dealCurrency } = useSelector(campaignManagerSelector);
  const [daterange, setrange] = useState("");
  const [startend, setStartend] = useState({ start: "", end: "" });
  const [campaignfilter, setCampaignFilter] = useState("");
  const [formatfilter, setFormatFilter] = useState("");
  const [periodfilter, setperiodfilter] = useState("");
  const [linegraph, setlinegraph] = useState({});
  const pickerref = useRef(null);
  const [loadershown, setloadershown] = useState(true);
  const [advertiseData, setAdvertiseData] = useState([]);
  const [advertiser, setAdveriser] = useState([])
  const [inventorie, setInventories] = useState("")
  const [markets, setMarkets] = useState([])
  const [dashboardData, setDashboardData] = useState([])
  const [pipelineOption, setPipelineOption] = useState([])
  const [inventoryTypeId, setInventoryTypeId] = useState("")
  const [creation, setCreation] = useState(null)
  const [pageNo, setPageNo] = useState(1)
  const [pageLimit, setPageLimit] = useState(5)
  const [page_Count, setPage_count] = useState(0)
  const [current_Page, setCurrent_Page] = useState(1)
  const [MarketFilter, setMarketFilter] = useState([])
  const permission = JSON.parse(localStorage.getItem('userdata'));





  const dispatch = useDispatch();
  const {
    overview,
    cpcv,
    egRate,
    ctr,
    views,
    impressions,
    device,
    format,
    graph,
    formats,
    campaign,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useSelector(dashboardSelector);

  const {
    inventories
  } = useSelector(LineItemsSelector);

  console.log(inventories, "inventoriesinventories")
  // useEffect(() => {
  //   dispatch(fetchdashboard({ filter: "", token: localStorage.getItem('token') }))
  // searchfilter();
  // }, []);

  // const [filter, setFilter] = useState({

  //   pipeline: {
  //     name: "Market",
  //     type: "dropdown",
  //     values: [],
  //     shown: true,
  //     multiselect: false,
  //   },
  // });

  // console.log(filter, "this is from home page")

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("DD/MM/YYYY") +
      " - " +
      picker.endDate.format("DD/MM/YYYY")
    );
    setStartend({
      start: picker.startDate.format("YYYY-MM-DD"),
      end: picker.endDate.format("YYYY-MM-DD"),
    });
    setrange(
      picker.startDate.format("YYYY-MM-DD") +
      " - " +
      picker.endDate.format("YYYY-MM-DD")
    );
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const findpercetage = (array, element) => {
    let sum = array
      .map((item) => item.impressions)
      .reduce((prev, curr) => prev + curr, 0);
    let percentage =
      sum == 0
        ? 0
        : (
          (array
            .map((item) => item[element])
            .reduce((prev, curr) => prev + curr, 0) /
            sum) *
          100
        ).toFixed(2);
    return percentage;
  };
  const findpercetageVTR = (array, element) => {
    let sum = array
      .map((item) => item.impressions)
      .reduce((prev, curr) => prev + curr, 0);
    let sumviews = array
      .map((item) => item.views)
      .reduce((prev, curr) => prev + curr, 0);
    // let ratio= sumviews/sum;

    let percentage = sum == 0 ? 0 : ((sumviews / sum) * 100).toFixed(2);
    return percentage;
  };

  useEffect(() => {
    if (Object.keys(deal_detail).length > 0) {
      dispatch(updateBreadCrumb([]));
    }
  }, []);

  const handleselectchange = (event) => {
    let value = event.target.value;
    if (value === "custom") {
      setdiabledpicker(false)
      setCreation("custom")
    } else {
      setperiodfilter(value);
      setCreation(null)
      setdiabledpicker(true)
    }
  };
  // const searchfilter = () => {
  //   let period = "";
  //   if (periodfilter != "custom") {
  //     period = periodfilter;
  //   }
  //   let filterdata = {
  //     // creation_date: daterange,
  //     // startDate: startend.start,
  //     // endDate: startend.end,
  //     // campaign: campaignfilter,
  //     // format: formatfilter,
  //     // period: period,

  //     advertiser: [],
  //     market: [],
  //     inventory_type: "Apps",
  //     startDate: "",
  //     endDate: "",
  //     period: "month"
  //   };

  //   Object.keys(filterdata).forEach((key) => {
  //     if (
  //       filterdata[key] === "" ||
  //       (typeof filterdata[key] === "object" && filterdata[key].length == 0)
  //     ) {
  //       delete filterdata[key];
  //     }
  //   });
  //   // let serchquery = `creation_date=${daterange}&startDate=${startend.start}&endDate=${startend.end}&campaign=${campaignfilter}&format=${formatfilter}&period=${period}`
  //   dispatch(
  //     fetchdashboard({
  //       filter: filterdata,
  //       token: localStorage.getItem("token"),
  //     })
  //   ).then((result) => { setloadershown(false); console.log(result, "this is the actual result") });
  // };



  const resetfilter = () => {
    window.location.reload();
  };


  const graphdraw = () => {
    let chartdata = {
      chart: {
        type: 'column'
      },
      title: {
        text: '',
        align: 'left'
      },
      subtitle: {
        text:
          '',
        align: 'left'
      },
      xAxis: {
        categories: dashboardData?.graph?.map((el) => el.date),
        crosshair: true,
        accessibility: {
          description: 'Dates'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        }
      },
      tooltip: {
        valueSuffix: ' (1000 MT)'
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          name: 'views',
          data: dashboardData?.graph?.map((data) => data.views)
        },
        {
          name: 'impressions',
          data: dashboardData?.graph?.map((data) => data.impressions)
        }
      ]
    };

    setlinegraph(chartdata);
  };

  useEffect(() => {
    getAdvertiseData()
    graphdraw()
  }, [])

  //getting all the advertisers from here
  const getAdvertiseData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}report/get_advertiser`,
      {
        method: "GET",
        headers: {
          AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const result = await response.json();
    if (result) {
      setAdvertiseData(result?.data);
    }
  };

  //getting market from below function
  // useEffect(() => {
  //   dispatch(pipelinefilter({ token: localStorage.getItem("token") }))
  //     .then((result) => {
  //       const pipelineoptions = { ...filter };
  //       pipelineoptions.pipeline.values = result?.payload?.data;
  //       setFilter(pipelineoptions?.pipeline);
  //     })
  //     .catch((err) => { });
  // }, [])

  useEffect(() => {
    dispatch(pipelinefilter({ token: localStorage.getItem("token") }))
      .then((result) => {
        setPipelineOption(result?.payload?.data)
      })
      .catch((err) => { });
  }, [])

  useEffect(() => {
    if (inventories.length == 0) {
      dispatch(getInventories({ token: localStorage.getItem("token") }));
    }
  }, [])


  useEffect(() => {
      getDashboardData()
  }, [])

  const getDashboardData = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "advertiser": [],
      "market": [],
      "inventory_type": null,
      "startDate": null,
      "endDate": null,
      "period": "seven_days"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}report/dashboard/1/11`, requestOptions)
      .then(response => response.json())
      .then(result => { console.log(result); setloadershown(false); setDashboardData(result.data); setPage_count(result?.data?.pageresult?.page_count); setCurrent_Page(result?.data?.pageresult?.current_page) })
      .catch(error => toast.error('Internal server error occurred'));
  }

  // useEffect(() => {
  //   handleSearchData();
  // }, [pageNo !== 1])


  const handleSearchData = () => {
    console.log('me trae los valores de markets');
    console.log(markets);
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "advertiser": advertiser ? advertiser : null,
      "market": markets ? markets : [],
      "inventory_type": inventoryTypeId ? inventoryTypeId : null,
      "startDate": startend.start ? startend.start : null,
      "endDate": startend.end ? startend.end : null,
      "period": periodfilter ? periodfilter : null,
      "creation_date": creation
    });

    var requestOptions = {
      method: 'POST', 
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}report/dashboard/${pageNo}/11`, requestOptions)
      .then(response => response.json())
      .then(result => { console.log(result); setloadershown(false); setDashboardData(result.data); setCurrent_Page(result?.data?.pageresult?.current_page) })
      .catch(error => toast.error('Internal server error occurred'));

  }

  const handle_multiselect_advertiser = (e) => {
    const selectedFormats = e.map((val) => val?.name);
    setAdveriser(selectedFormats);
  }

  const handle_multiselect_inventory = (e) => {
    const selectedInvenory = e.map((val) => val?.label);
    setMarkets(selectedInvenory);

  }



  useEffect(() => {
    const storeValues = () => {
      let data = ["c154cd17-a947-4f80-a702-a369c6c8d2c4", "9028d3f5-7669-414d-9954-9b3de9656591", "20249197", "03ca6bf5-789f-4ced-ae3c-556ccf4cab80", "4837244"];
      let arr = [];

      const getAllData = (data) => {
        if (data?.length === 0) return;

        let findValue = pipelineOption?.find((el) => el.pipelineId === data[0]);
        if (findValue) {
          arr.push(findValue);
        }

        getAllData(data.slice(1));
      };

      getAllData(data);
      setMarketFilter(arr);
    };

    if (pipelineOption && pipelineOption.length > 0) {
      storeValues();
    }
  }, [pipelineOption]);

  // this below code is used to select the start date before 60 days from today nitin 20/09/2023
  var d = new Date();
  d.setDate(d.getDate() - 60);
  return (
    <div className="content_outer">
      <Subtitle subtitle="Dashboard" />
      <div className="content">
        <Loader showLoader={loadershown} />
        <Form className="home_filter">
          <h4 className="user__name">
            Welcome back,  {`${JSON.parse(localStorage.getItem("userdata")).first_name}`}!
          </h4>
          <Row className="border-bottom pb-3 mb-5">
            <Col lg={2}>
              <Form.Group className="mb-3 select_country_container input_wrap" controlId="formBasicEmail">
                <Form.Label className="center_label lbl_position">Period</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleselectchange}
                >
                  <option disabled={true} selected={true}>
                    Select
                  </option>
                  <option value="Yesterday">Yesterday</option>
                  <option value="today">Today</option>
                  <option value="thismonth">This Month</option>
                  <option value="seven_days">Last seven days</option>
                  <option value="last_month">Last month</option>
                  <option value="custom">Custom date</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={2}>
              <div className="select_country_container ">
                <label className="center_label lbl_position form-label" htmlFor="Campaign">
                  Advertiser
                </label>
                <Multiselect className="white_space"
                  options={advertiseData.map((i) => {
                    const name = i?.name ?? "Unknown";
                    return { ...i, name };
                  })}
                  // selectedValues={adv}
                  onSelect={(e) => { handle_multiselect_advertiser(e); }}
                  // onRemove={(e) => handle_multiremove_advertiser(e)}
                  displayValue="name"
                />
              </div>
            </Col>
            {!disabledatepicker && <Col md={3} lg={2}>
              <Form.Group className="mb-3 select_country_container" controlId="formBasicEmail">
                <Form.Label className="center_label lbl_position">Date Range</Form.Label>
               
                <DateRangePicker
                  initialSettings={{
                    startDate: d,
                  endDate: new Date(),
                  minDate:d,
                  maxDate: new Date(),
                  }}
                  onApply={handleApply}
                  onCancel={handleCancel}
                  disabled={disabledatepicker}
                  ref={pickerref}
                >
                  <Form.Control
                    type="text"
                    placeholder="Creation Date"
                    className="form-control"
                    disabled={disabledatepicker}
                  />
                </DateRangePicker>
              </Form.Group>
            </Col>}
            <Col lg={2}>
              <div className="select_country_container ">
                <label className="center_label lbl_position form-label" htmlFor="Campaign">
                  Market
                </label>
                <Multiselect
                  options={MarketFilter.map((i) => {
                    {
                      const label = i?.label ?? "Unknown";
                      return { ...i, label };
                    }

                  })}
                  // selectedValues={adv}
                  onSelect={(e) => { handle_multiselect_inventory(e); }}
                  // onRemove={(e) => handle_multiremove_advertiser(e)}
                  displayValue="label"
                />
              </div>
            </Col>
            {/* <Col lg={2}>
              <div className="input_wrap">
                <label className="mb-2" htmlFor="Campaign">
                  Inventory
                </label>
                <Multiselect
                  options={inventories.map((i) => {
                    const name = i?.description ?? "Unknown";
                    
                    return { ...i, name };
                  })}
                  // selectedValues={adv}
                  // onSelect={(e) => { handle_multiselect_inventory(e); }}
                  // onRemove={(e) => handle_multiremove_advertiser(e)}
                  displayValue="name"
                />
              </div>
            </Col> */}
            <Col lg={2}>
              <Form.Group className="mb-3 select_country_container input_wrap" controlId="formBasicEmail">
                <Form.Label className="center_label lbl_position">Inventory</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setInventoryTypeId(e.target.value)}
                >
                  <option disabled={true} selected={true}>
                    Select
                  </option>
                  {inventories?.map((data, index) => {
                    if(data?.id == 1 || data?.id == 3 || data?.id == 4 || data?.id == 5){
                      return (
                        <option value={data?.description}>{data?.description}</option>
                      )
                    }
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={12} md={12} lg={disabledatepicker ? 4 : 2}>
              <div className="d-flex align-items center submit_btn">
                <Form.Group className="mb-3" controlId="formBasicEmail">


                  <Button className={`transparent ${disabledatepicker ? "transparentMinWidth" : ""}`} onClick={resetfilter}>
                    Reset
                  </Button>
                </Form.Group>
                <Form.Group className="mb-3 ms-2 flex-grow-1" controlId="formBasicEmail">
                  <Button className="w-100 grey_btn" onClick={handleSearchData} >
                    Search
                  </Button>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6}>
              <div className="card_outer pb-0 ps-0 pe-0">
                <h4>
                  {dashboardData.Impression ? (dashboardData.Impression)?.toLocaleString() : 0}
                </h4>
                <p className="imp_text">Impressions</p>
                <img className="img-fluid" src="assets/images/impression_image.png" />
              </div>

            </Col>
            <Col lg={3} md={6}>
              <div className="card_outer">
                <h4>
                  {dashboardData.Views ? (dashboardData.Views).toLocaleString() : 0}
                </h4>
                <p className="view_text">Completed Views</p>
              </div>
              <div className="view_card">
                <h4><span className="me-3">VTR</span>{dashboardData?.Sumvtr ? (dashboardData?.Sumvtr)?.toFixed(2) : 0}%</h4>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="card_outer">
                <h4>
                  {dashboardData.Click ? (dashboardData.Click)?.toLocaleString() : 0}
                </h4>
                <p className="click_text">Clicks</p>
              </div>
              <div className="view_card ctr_bg">
                <h4><span className="me-3">CTR</span>{dashboardData?.Sumctr ? (dashboardData?.Sumctr)?.toFixed(2) : 0}%</h4>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="card_outer">
                <h4>
                  {dashboardData.Engagements ? (dashboardData.Engagements)?.toLocaleString() : 0}
                </h4>
                <p className="engage_text">Engagements</p>
              </div>
              <div className="view_card er_bg">
                <h4><span className="me-3">ER</span>{(dashboardData?.SumEr ? dashboardData?.SumEr : 0)?.toFixed(2)}%</h4>
              </div>
            </Col>
          </Row>
          <h2 className="home_subtitle">  Views and impressions
          </h2>
          <Row>

            <Col lg={12} md={12}>
              <div className="card_outer statistics">

                <div className="high_chart">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {
                        text: '',
                        align: 'left',
                      },
                      xAxis: {
                        categories: dashboardData?.graph?.sort((a,b) => new Date(a.date) - new Date(b.date))
                        .map((el) => {
                          // Assuming 'el.date' is a string representation of the date, e.g., '2023-07-28'
                          const dateObject = new Date(el.date);
                          const year = dateObject.getFullYear();
                          const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                          const day = String(dateObject.getDate()).padStart(2, '0');
                          return `${year}-${month}-${day}`;
                        }) // Use reverse() to display the data in descending order
                      },
                      
                      yAxis: {
                        title: {
                          text: '',
                        },
                      },
                      tooltip: {
                        valueSuffix: '',
                      },
                      plotOptions: {
                        series: {
                          borderRadius: '0%',
                        },
                      },
                      series: [
                        {
                          type: 'column',
                          name: 'impression',
                          data: dashboardData?.graph?.map((data) => data.impressions),
                          color: '#f978c8'
                        },
                        {
                          type: 'column',
                          name: 'views',
                          data: dashboardData?.graph?.map((data) => data.views),
                          color: '#00d7c8'
                        },
                      ],
                    }}
                  />

                </div>
              </div>
            </Col>
          </Row>
          <div className="advert_tabel campign_index_table">

            <Row>
              <Col lg={12} md={12}>
                <h2 className="home_subtitle">  Format
                </h2>
                <div className="card_outer  p-0 advert_tabel campign_index_table">
                  <div className="format">
                    <Table responsive className="mt-0 border-0">
                      <thead>
                        <tr>
                          <th className="text-center">FORMAT</th>
                          <th className="text-center">IMPRESSIONS</th>
                          <th className="text-center">VIEWS</th>
                          <th className="text-center">CLICKS</th>
                          <th className="text-center">ENGAGEMENTS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.format?.map((form, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{form?.format}</td>
                              <td className="text-center">{form?.impressions ? (form?.impressions)?.toLocaleString() : 0}</td>
                              <td className="text-center">{form?.views ? (form?.views)?.toLocaleString() : 0}</td>
                              <td className="text-center">{form?.clicks ? (form?.clicks)?.toLocaleString() : 0}</td>
                              <td className="text-center">{form?.engagements ? (form?.engagements)?.toLocaleString() : 0}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {/* <div className="cmn__pagination">
                            <ul>
                           
                              <li className="total_pages me-3">
                                <span className="active_pages">{current_Page}</span> of <span>{page_Count}</span>
                              </li>
                             {current_Page !== 1 && <li>
                                <BsChevronBarLeft onClick={() => setPageNo(1)} />
                              </li>}
                             {current_Page !== 1 && <li

                              >
                                <BsChevronLeft onClick={() => setPageNo(pageNo - 1)} />
                              </li>}
                             { current_Page !== page_Count &&  <li

                              >
                                <BsChevronRight onClick={() => setPageNo(pageNo + 1)} />
                              </li>}
                             {current_Page !== page_Count &&   <li>
                                <BsChevronBarRight onClick={() => setPageNo(page_Count)} />
                              </li>}
                            </ul>
                          </div> */}
                  </div>
                </div>
              </Col>
              {/* <Col lg={6} md={12}>
              <h2 className="home_subtitle"> Devices
              </h2>
              <div className="card_outer">
                <div className="pie_chart">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: "pie",
                        margin: [25, 20, 20, 20],
                        spacingTop: 0,
                        spacingBottom: 0,
                        spacingLeft: 0,
                        spacingRight: 0,
                      },

                      plotOptions: {
                        pie: {
                          size: "100%",
                          dataLabels: {
                            enabled: false,
                          },
                        },
                      },
                      title: {
                        text: "Device Usage World wide",
                      },
                      tooltip: {
                        pointFormat:
                          "{series.name}: <b>{point.percentage:.1f}%</b>",
                      },
                      accessibility: {
                        point: {
                          valueSuffix: "%",
                        },
                      },
                      credits: {
                        enabled: false,
                      },
                      plotOptions: {
                        pie: {
                          allowPointSelect: true,
                          cursor: "pointer",
                          dataLabels: {
                            enabled: true,
                            format:
                              "<b>{point.name}</b>: {point.percentage:.1f} %",
                          },
                        },
                      },
                      series: [
                        {
                          name: "Device",
                          colorByPoint: true,
                          data: device,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </Col> */}
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Home;
