import React, { useState } from "react";
import "./style.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { BiBell } from "react-icons/bi";
import { CommonSelector } from "../../app/features/Common/CommonSlice";
const Header = () => {
	const [isActive, setIsActive] = useState(false);
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [show_agency, setShow_agency] = useState(true)

	const { header_breadcrum } = useSelector(CommonSelector);
	const handleClick = () => {
		// setIsActive(current => !current);
		// dispatch(toggle({isActive}));
		localStorage.clear();
		navigation("/");
	};

	const permission = JSON.parse(localStorage.getItem('userdata'));

	return (
		<header className="header_outer">
			<div className="left_content d-flex">
				{/* <Link to="/home"><FaHome></FaHome> </Link> */}
				<div className="bredcrum">
					<ul>
					</ul>
				</div>
			</div>

			<div className="right_content">
				{/* {permission?.Permissions[24] && show_agency && (
					<p
						title="Navigate To Agency Users"
						className="mb-0 agency_users me-3"
						onClick={() => {
							navigation('/userlist', {
								state: {
									user: true,
									show_new_user: false
								}
							});
							setShow_agency(false)
						}}
					>
						Agency User
					</p>
				)} */}

				{/* <div className="notifications">
					<BiBell />
					<span></span>
				</div> */}
				<Dropdown className="user_dropdown ms-2">
					<Dropdown.Toggle variant="success" id="dropdown-basic">
						<img src="/assets/images/kite_user.webp" alt="" />
						<div className="ms-2 ms-3">
							<span>{`${JSON.parse(localStorage.getItem("userdata")).first_name +
								" " +
								JSON.parse(localStorage.getItem("userdata")).last_name
								} `}</span>
							<p className="designation">{localStorage.getItem("userGroup")}</p>

						</div>
						<div className="arrow__icon">	<FiChevronDown /></div>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item>
							<Link to="/profile"> My Profile</Link>{" "}
						</Dropdown.Item>
						{/* {permission?.Permissions[25] && <Dropdown.Item>
							<Link to="/adminSaas">Admin Saas</Link>{" "}

						</Dropdown.Item>} */}
						{permission?.Permissions[10] && (
							<Dropdown.Item onClick={() => {
								navigation('/userlist', {
									state: {
										refresh: true,
										show_new_user: true
									}
								}); setShow_agency(true)
							}}>
								Users
							</Dropdown.Item>
						)}
						{permission?.Permissions[24] && <Dropdown.Item onClick={() => navigation('/userlist', {
							state: {
								user: true,
								hideFilter : true
								// show_new_user: false
							}
						})}>
							Agency Users
						</Dropdown.Item>}
						{permission?.Permissions[25] && <Dropdown.Item onClick={() => {
							navigation('/userlist', {
								state: {
									user: true,
									validate: true,
									show_new_user: true
								}
							}); setShow_agency(true)
						}}>
							{/* <Link to="/userlist" params={{ testvalue: "hello" }}>Create Idea</Link> */}
							Admin Agency Users
						</Dropdown.Item>}
						{/* this is commented nitin 1/09/2023 */}
						{/* <Dropdown className="select_language">
							<Dropdown.Toggle variant="success" id="dropdown-basic12">
								<span>
									Language <FiChevronDown />
								</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									<Link to="">English</Link>{" "}
								</Dropdown.Item>
								<Dropdown.Item>
									<Link to="">Spanish</Link>{" "}
								</Dropdown.Item>
								<Dropdown.Item>
									<Link to="">Portuguese</Link>{" "}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown> */}
						<Dropdown.Item onClick={handleClick}>Logout</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</header>
	);
};

export default Header;
