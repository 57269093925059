import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useSelector, useDispatch } from "react-redux";
import {
	ExchangeRates,
	getHsDeal,
	deligateSelector,
} from "../../app/features/CampaignManager/DelegateSlice";

const DuplicateLineItem = (props) => {
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [deal_id, setDeal_id] = useState();
	const [campaign_id, setCampaign_id] = useState();
	const [inventory_type_id, setInventory_type_id] = useState();
	const [format_id, setFormat_id] = useState();
	const [date_from, setDate_from] = useState("");
	const [date_to, setDate_to] = useState("");
	const [inversion_budget, setInversion_budget] = useState();
	const [inversion_sell_rate, setInversion_sell_rate] = useState();
	const [inversion_offer_type_id, setInversion_offer_type_id] = useState();
	const [inversion_goal, setInversion_goal] = useState();
	const [target_country_id, setTarget_coountry_id] = useState([]);
	const [target_gender_id, setTarget_gender_id] = useState();
	const [target_frequency, setTarget_frequency] = useState();
	const [inversion_netRate, setInversion_netRate] = useState();
	const [inversion_netMargin, setInversion_netMargin] = useState();
	const [inversion_netRate_usd, setInversion_netRate_usd] = useState();
	const [inversion_sellRate_usd, setInversion_sellRate_usd] = useState();
	const [white_list, setWhite_list] = useState("");
	const [black_list, setBlack_list] = useState("");
	const [inversion_budget_usd, setInversion_budget_usd] = useState();
	const [lineitem_devices, setLineitem_devices] = useState([]);
	const [lineitem_passion_points, setLineitem_passion_points] = useState([]);
	const [audiences, setAudiences] = useState([]);
	const [nameErr, setNameErr] = useState(false);
	const [dateErr, setDateErr] = useState(false);
	const [budgetErr, setBudgetErr] = useState(false);
	const { dealCurrencies, hs_deal } = useSelector(deligateSelector);
	const [usdBudget, setUsdBudget] = useState("");

	useEffect(() => {
		getdata();
	}, []);

	const getdata = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getlineitemid/${props.id}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			}
		);
		const result = await response.json();
		setDeal_id(result?.data?.deal_id);
		setCampaign_id(result?.data?.campaign_id);
		setInventory_type_id(result?.data?.inventory_type_id);
		setFormat_id(result?.data?.format_id);
		setInversion_sell_rate(result?.data?.inversion_sell_rate);
		setInversion_offer_type_id(result?.data?.inversion_offer_type_id);
		setInversion_goal(result?.data?.inversion_goal);
		let country_arr = [];
		result?.data?.countryList?.map((data) => {
			country_arr.push(data.country.id);
		});
		setTarget_coountry_id(country_arr);
		setTarget_gender_id(result?.data?.target_gender_id);
		setTarget_frequency(result?.data?.target_frequency);
		setInversion_netRate(result?.data?.inversion_netRate);
		setInversion_netMargin(result?.data?.inversion_netMargin);
		setInversion_netRate_usd(result?.data?.inversion_netRate_usd);
		setInversion_sellRate_usd(result?.data?.inversion_sellRate_usd);
		setWhite_list(result?.data?.white_list);
		setBlack_list(result?.data?.black_list);
		let arr_new = [];
		result?.data?.deviceList?.map((data) => {
			arr_new.push(data.id);
		});
		setLineitem_devices(arr_new);
		let new_arr = [];
		result?.data?.passionPointList?.map((data) => {
			new_arr.push(data.id);
		});
		setLineitem_passion_points(new_arr);
		let newArray = [];
		result?.data?.audienceList?.map((data, index) => {
			newArray.push(data.audience.id);
		});
		setAudiences(newArray);
	};

	useEffect(() => {
		if (props.dealCurrency != "") {
			dispatch(ExchangeRates({ dealCurrency: props.dealCurrency }));
		}
	}, [props.dealCurrency]);

	const handleSave = () => {
		if (name === "") {
			setNameErr(true);
		} else {
			if (date_from == null) {
				setDateErr(true);
			} else {
				if (inversion_budget == null) {
					setBudgetErr(true);
				} else {
					var myHeaders = new Headers();
					myHeaders.append(
						"Authorization",
						"Bearer " + localStorage.getItem("token")
					);
					myHeaders.append("Content-Type", "application/json");

					var raw = JSON.stringify({
						name: name,
						deal_id: deal_id,
						campaign_id: campaign_id,
						inventory_type_id: inventory_type_id,
						format_id: format_id,
						date_from: date_from,
						date_to: date_to,
						inversion_budget: inversion_budget,
						inversion_sell_rate: inversion_sell_rate,
						inversion_offer_type_id: inversion_offer_type_id,
						inversion_goal: inversion_goal,
						target_country_id: target_country_id,
						target_gender_id: target_gender_id,
						target_frequency: target_frequency,
						audiences: audiences,
						inversion_netMargin: inversion_netMargin,
						inversion_netRate: inversion_netRate,
						inversion_netRate_usd: inversion_netRate_usd,
						inversion_sellRate_usd: inversion_sellRate_usd,
						white_list: white_list,
						black_list: black_list,
						inversion_budget_usd: usdBudget,
						lineitem_devices: lineitem_devices,
						lineitem_passion_points: lineitem_passion_points,
					});

					var requestOptions = {
						method: "POST",
						headers: myHeaders,
						body: raw,
						redirect: "follow",
					};

					fetch(
						`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-lineitem`,
						requestOptions
					)
						.then((response) => response.text())
						.then((result) => toast.success("LineItem created successfully"))
						.then(props.setDuplicate_modal(false))
						.then(
							setTimeout(() => {
								props.getcampain();
								props.searchFilter();
							}, 1000)
						)
						.catch((error) => toast.error(error));
				}
			}
		}
	};

	const handleCallback = (start, end) => {
		setDate_from(start.format("YYYY-MM-DD"));
		setDate_to(end.format("YYYY-MM-DD"));
	};

	const calculateBudget = (e) => {
		let budget = e.target.value;
		if (budget != "") {
			compute("USD", budget);
		}
	};

	const compute = (output_currency1, budget) => {
		if (Object.keys(dealCurrencies).length > 0) {
			const new_rate = dealCurrencies[output_currency1];
			let output_amount = (budget * new_rate).toFixed(2);
			return setUsdBudget(output_amount);
		}
	};

	return (
		<>
			<div className="content">
				<h2>Line item Setting</h2>
				<div className="cmn_border">
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group mb-3">
								<label className="mb-2" for="lignitemname">
									Line Item Name
								</label>
								<input
									type="text"
									className="form-control"
									name="name"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
								{nameErr && (
									<small style={{ color: "red" }}>Name field is required</small>
								)}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group mb-3">
								<label className="mb-2" for="lignitemname">
									Start Date - End Date
								</label>
								<DateRangePicker
									initialSettings={{
										startDate: props?.lineItem_detail?.date_from
											? new Date(props?.lineItem_detail?.date_from)
											: new Date(),
										endDate: props?.lineItem_detail?.date_to
											? new Date(props?.lineItem_detail?.date_to)
											: new Date(),
										maxSpan: { days: 45 },
									}}
									onCallback={handleCallback}
								>
									<input type="text" className="form-control" />
								</DateRangePicker>
								{dateErr && (
									<small style={{ color: "red" }}>Please fill date</small>
								)}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group mb-3">
								<label className="mb-2" for="lignitemname">
									Budget (BRL)
								</label>
								<input
									type="number"
									className="form-control"
									name="name"
									value={inversion_budget}
									onChange={(e) => {
										setInversion_budget(e.target.value);
										{
											calculateBudget(e);
										}
									}}
								/>
								{budgetErr && (
									<small style={{ color: "red" }}>PLease enter budget</small>
								)}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group mb-3">
								<label className="mb-2" for="lignitemname">
									Budget USD :{" "}
								</label>
								<span> {usdBudget}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-end mt-4">
					<button
						type="button"
						className="cmn_btn me-3 ms-auto cancel_cm_btn"
						disabled=""
						onClick={() => {
							props.setDuplicate_modal(false);
						}}
					>
						Cancel
					</button>
					<button type="submit" className="cmn_btn ms-2" onClick={handleSave}>
						Save
					</button>
				</div>
			</div>
		</>
	);
};

export default DuplicateLineItem;
