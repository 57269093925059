import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const CommonSlice = createSlice({
    name: 'Common',
    initialState: {
        successMessage: '',
        errorMessage: '',
        header_breadcrum: []
        },
    reducers: {
        clearState: (state) => {
            state.header_breadcrum=[];
            return state;
        },
        updateBreadCrumb: (state, action)=>{
            console.log(action)
            state.header_breadcrum= action.payload;
            return state;
          }
    },
    extraReducers: {}

})
export const {
    clearState, 
    updateBreadCrumb
  } = CommonSlice.actions;
  export const CommonSelector = (state) => state.Common;