export const upperCaseFirstLetter = string =>
 `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;

export const lowerCaseAllWordsExceptFirstLetters = string =>
 string.replaceAll(/\S*/g, word =>
  `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
 );

/**
 * @typedef DownloadCsvOptions
 * @property {string=} separator Delimiter used to separate values.
 */

/**
 * @param {string} filename
 * @param {unknown[][]} rows
 * @param {DownloadCsvOptions=} options
 */
export function downloadCsv(filename, rows, options = {}) {
  const { separator = ";" } = options;
  const csv = rows.map((row) =>
    row.map((cell) => {
      if (cell === undefined || cell === null) return "";
      const str = typeof cell === "object"
        ? JSON.stringify(cell)
        : String(cell);
      return str.includes(separator) || str.includes("\n") || str.includes('"')
        ? `"${str.replaceAll('"', '""')}"`
        : str;
    }).join(separator)
  ).join("\r\n");
  const data = new Blob([`\ufeff${csv}`]);
  const element = document.createElement("a");
  element.href = URL.createObjectURL(data);
  element.download = filename;
  element.hidden = true;
  document.body.append(element);
  element.click();
  element.remove();
}
