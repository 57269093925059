import styled from "styled-components";

export const TabSC = styled.button`
	display: flex;
	justify-content: center;
	flex-direction: column;
	background: transparent;
	width: 100%;
	border: none;
`;
