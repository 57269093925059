import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./features/Auth/authSlice";
import { DashboardSlice } from "./features/Dashboard/DashboardSlice";
import { indexSlice } from "./features/Report/reportSlice";
import { AbstractSlice } from "./features/Abstract/AbstractSlice";
import { CampaignManagerSlice } from "./features/CampaignManager/CampaignManagerSlice";
import { DeligateSlice } from "./features/CampaignManager/DelegateSlice";
import { MediaPlanManagerSlice } from "./features/CampaignManager/MediaPlanManager";
import { LineItemsSlice } from "./features/LineItemManager/LineItemManagerSlice";
import { CreativesSlice } from "./features/Creative/CreativeSlice";
import { CommonSlice } from "./features/Common/CommonSlice";
import { UserSlice } from "./features/Users/UserSlice";
import reset_password from "./features/Auth/resetPasswordSlice";
import change_user_password from "./features/Auth/changeUserPasswordSlice";
import changeUserPassword from "./features/Auth/changePasswordSlice";
import validate_token from "./features/Auth/validateTokenSlice";
import change_new_user_password from "./features/Auth/chnageNewUserPasswordSlice";
import lineitemLogHistory from "./features/lineitemHistory/lineitemHistorySlice";
import getUserAdvertiseClients from "./features/Users/get_advertise_clients_slice";
import saveAdvertiserClients from "./features/Users/save_advertiser_clients_slice";
import getSelectedAdvertiserClients from "./features/Users/get_selected_advertiser_clients_slice";
export default configureStore({
    reducer: {
        auth: authSlice.reducer,
        dashboard: DashboardSlice.reducer,
        index: indexSlice.reducer,
        abstract: AbstractSlice.reducer,
        campaignManager: CampaignManagerSlice.reducer,
        deligate: DeligateSlice.reducer,
        MediaPlanManager: MediaPlanManagerSlice.reducer,
        LineItems: LineItemsSlice.reducer,
        Creatives: CreativesSlice.reducer,
        Common: CommonSlice.reducer,
        user: UserSlice.reducer,
        reset_user_password: reset_password,
        change_user_password: changeUserPassword,
        change_new_user_password: change_user_password,
        validate_user_token : validate_token,
        new_user_password_change : change_new_user_password,
        log_history : lineitemLogHistory,
        all_user_clients : getUserAdvertiseClients,
        save_advertiser_client_list : saveAdvertiserClients,
        all_selected_advertiser_clients : getSelectedAdvertiserClients
    }
});