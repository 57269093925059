import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { MultiSelect } from "react-multi-select-component";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import style from "./style.css";
import { index, reportSelector } from "../../app/features/Report/reportSlice";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ArgentinaIcon, BoliviaIcon, BrasilIcon, ChileIcon, ColombiaIcon, CostaRicaIcon, EcuadorIcon, ElSalvadorIcon, GuatemalaIcon, HondurasIcon, MexicoIcon, NicaraguaIcon, PanamaIcon, ParaguayIcon, PeruIcon, PuertoRicoIcon, RepublicaDominicanaIcon, UruguayIcon, VenezuelaIcon, UnitedStatesIcon } from './svg';
import {
  fetchabstract,
  abstractSelector,
  reachPlanner,
  cleareachPlanner,
} from "../../app/features/Abstract/AbstractSlice";
import GaugeChart from "react-gauge-chart";
import { BiPlus } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
//New //
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loader from "../Loader";
import {
  LineItemsSelector,
  getInventories,
  getOfferTypes,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  CommonSelector,
  updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";
import {
  campaignManagerSelector,
  dealdetail,
  deal_currency,
  getowners,
  pipelinefilter,
  clearState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
// import  Button  from "../button";
import Button from "../button/button";
import { buttonColors, textColors } from "../../helpers/const/paletteColors";
import { TabSC } from "./abstract.styled";
import { ArIcon, BoIcon } from "../../icons/svg";
import Subtitle from "../Subtitle/subtitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { Pie } from "react-chartjs-2";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import { Margin, usePDF } from "react-to-pdf";

const Abstract = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("");
  const genders = [
    { label: "Girls ", value: "F" },
    { label: "Boys ", value: "M" },
  ];
  const { deal_detail, dealCurrency, isFetching, isSuccess, isError, error } =
    useSelector(campaignManagerSelector);
  const targetRef = useRef(null)
  // const { toPDF, targetRef } = usePDF({
  //   method: "save",
  //   filename: "Reports.pdf",
  //   page: { margin: Margin.MEDIUM },
  //   unit: 'in',
  //   format: [4, 2]
  // });

  const [selected, setSelected] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedAgeRange, setSelectedAgeRange] = useState([]);
  const [allAgeRange, setAllAgeRange] = useState([]);
  const [populatedData, setPopolatedData] = useState({});
  const [loadershown, setloadershown] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [filteredCountries, setcountries] = useState([]);
  const [filteredGender, setfilteredGender] = useState([]);
  const [connectedCountries, setConnectedCountries] = useState([]);
  const [connectedPopulation, setConnectedPopulation] = useState("");
  const [gender, setGender] = useState([]);
  const [campaigngender, setCampaignGender] = useState([]);
  const [campaignagerange, setCampaignAgeRange] = useState([]);
  const [campaignoffertype, setCampaignOfferType] = useState(0);
  const [campaignpostdata, setCampaignPostdata] = useState({});
  const [ageRange, setageRange] = useState([]);
  const [useasAgeRange, setuseasAgeRange] = useState([]);
  console.log(useasAgeRange, "useasAgeRangeuseasAgeRange");
  const [populationProjection, setpopulationProjection] = useState({});
  const { products, ageRanges } = useSelector(reportSelector);
  console.log(ageRanges, "this is all ages details");
  const [country, setCountry] = useState("");
  const [product, setProduct] = useState(0);
  const [budget, setBudget] = useState(0);
  const [noofdays, setNoOfDays] = useState(30);
  const [countries, setCountries] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [youtubestate, setYouTubeState] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [arrowYou, setArrowYou] = useState(false);
  const [arrowRo, setArrowRo] = useState(false);
  const [arrowKid, setArrowKid] = useState(false);
  const [youtubeData, setYoutubeData] = useState(false);
  const [robloxData, setRobloxData] = useState(false);
  const [kidscorpData, setKidsCorpData] = useState(false);
  const [indexNo, setIndexNo] = useState(null);
  const [date, setDate] = useState("");
  const [youtubeGraphState, setYoutubeGraphState] = useState(true);
  const [robloxGraphState, setRobloxGraphState] = useState(true);
  const [appGraphState, setAppGraphState] = useState(true);
  const [show_less, setShow_less] = useState(false);
  const [show_more, setShow_more] = useState(true);
  const [uploading, setUploading] = useState(false)
  const [allFields, setAllFields] = useState([
    {
      country: "",
      selected_country: "",
      gender: [],
      age: [],
      selectGender: [],
      selectAge: [],
      selectedAges: [],
      inventories: [{ product: "", offerType: "5", days: "30", budget: "" }],
    },
  ]);



  const [indexValue, setIndexValue] = useState(null);
  const [resultData, setResultData] = useState([]);
  console.log(resultData, "this is the roblo data");

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  const { offer_types } = useSelector(LineItemsSelector);

  const {
    incidencia,
    poblacion_proyectada,
    population_projection_by_age,
    use_as_per_age,
    dataloaded,
    rachpalnnerdata,
    reachplannerdataLoaded,
    connected_Population,
  } = useSelector(abstractSelector);

  useEffect(() => {
    if (offer_types.length == 0) {
      dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
    }
  }, [offer_types]);
  useEffect(() => {
    dispatch(index({}));
    dispatch(getInventories({ token: localStorage.getItem("token") }));
    // dispatch(fetchabstract({country: filteredCountries, gender: filteredGender, age: ageRange, token: localStorage.getItem('token')}))
  }, []);

  const filterdata = () => {
    let filtered_countries = [];
    selected.forEach((value, index) => {
      filtered_countries.push(value?.label);
    });
    setConnectedCountries(filtered_countries);
    setloadershown(true);
    dispatch(
      fetchabstract({
        country: filteredCountries,
        gender: gender,
        age: allAgeRange,
        token: localStorage.getItem("token"),
      })
    ).then((response) => {
      setloadershown(false);
    });
  };

  const handlecountrychange = (data) => {
    let selectedcountry = data.map((i) => {
      return i.value.toUpperCase();
    });
    setSelected(data);
    setcountries(selectedcountry);
  };
  const handleGanderchange = (data) => {
    console.log(data)
    if (!Array.isArray(data)) {
      return;
    }

    const selectedGender = data.map((i) => i.value);
    console.log(selectedGender, "this is the selected gender")
    const selected_gender_value = data.map((i) => i.label)
    console.log(selected_gender_value, "this is the selected gender value")
    setSelectedGender(data);
    setGender(selectedGender);



    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      const currentField = updatedFields[indexValue];

      if (currentField && Array.isArray(currentField.gender)) {
        const uniqueGender = [
          ...new Set([...currentField.gender, ...selectedGender]),
        ];
        updatedFields[indexValue] = {
          ...currentField,
          gender: uniqueGender,
        };
      }

      return updatedFields;
    });

    allFields[indexValue].gender = allFields[indexValue].gender.filter(item => selectedGender.includes(item))

    setAllFields((prevFields) => {
      const updatedFieldsGrnder = [...prevFields];
      const currentField = updatedFieldsGrnder[indexValue];

      if (currentField && Array.isArray(currentField.selectGender)) {
        const currentSelectedGenders = currentField.selectGender;
        const uniqueSelectedGenders = [
          ...new Set([...currentSelectedGenders, ...data]),
        ];
        updatedFieldsGrnder[indexValue] = {
          ...currentField,
          selectGender: uniqueSelectedGenders,
        };
      }

      return updatedFieldsGrnder;
    });

    allFields[indexValue].selectGender = allFields[indexValue].selectGender.filter(obj => {
      return selectedGender.includes(obj.value);
    })

  };


  const handleCampaignAgeRangeChange = (data) => {
    let selectAgeRange = data.map((i) => {
      return i.value.toUpperCase();
    });

    let selectedAge = data.map((i) => {
      return i.label.toUpperCase();
    });
    console.log(selectedAge, "this is the selected age")
    console.log(allFields[indexValue].selectAge, "selected age of all fields")
    setSelectedAgeRange(data);
    setAllAgeRange(selectAgeRange);





    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      const uniqueAge = [
        ...new Set([...updatedFields[indexValue].age, ...selectAgeRange]),
      ];
      updatedFields[indexValue] = {
        ...updatedFields[indexValue],
        age: uniqueAge,
      };
      return updatedFields;
    });

    allFields[indexValue].age = allFields[indexValue].age.filter(item => selectAgeRange.includes(item))



    setAllFields((prevFields) => {
      const updatedFieldsAge = [...prevFields];
      const uniqueAgeRange = [
        ...new Set([...updatedFieldsAge[indexValue].selectAge, ...data]),
      ];
      updatedFieldsAge[indexValue] = {
        ...updatedFieldsAge[indexValue],
        selectAge: uniqueAgeRange,
      };
      return updatedFieldsAge;
    });

    allFields[indexValue].selectAge = allFields[indexValue].selectAge.filter(obj => {
      return selectAgeRange.includes(obj.value);
    })

    setAllFields((prevFields) => {
      const updatedFieldsSelected = [...prevFields];
      const uniqueAgeRangeSelected = [
        ...new Set([
          ...updatedFieldsSelected[indexValue].selectedAges,
          ...selectedAge,
        ]),
      ];
      updatedFieldsSelected[indexValue] = {
        ...updatedFieldsSelected[indexValue],
        selectedAges: uniqueAgeRangeSelected,
      };
      return updatedFieldsSelected;
    });

    allFields[indexValue].selectedAges = allFields[indexValue].selectedAges.filter(item => selectAgeRange.includes(item))

    // allFields[indexValue].selectedAge =  allFields[indexValue].selectedAge.filter(item1 => {
    //   return !array2.some(item2 => item2.key === item1.key && item2.value === item1.value);
    // });

    setDate("test" + Date.now());
  };

  const submitCampaignReach = () => {
    let isError = false; // Flag variable to track errors

    allFields?.map((data, i) => {
      if (data?.country === "") {
        toast.error("Please select Country");
        isError = true;
      } else if (data?.gender?.length === 0) {
        toast.error("Please select Gender");
        isError = true;
      } else if (data?.ageRange?.length === 0) {
        toast.error("Please select Age Range");
        isError = true;
      } else if (data?.inventories?.length !== 0) {
        data?.inventories?.map((value, index) => {
          if (value?.inventoryType === "") {
            toast.error("Please select Inventory type");
            isError = true;
          } else if (value?.offerType === "") {
            toast.error("Please select Offer type");
            isError = true;
          } else if (value?.budget === "") {
            toast.error("Please add Budget");
            isError = true;
          }
        });
      }
    });

    if (!isError) {
      fieldData();
    }
  };

  const fieldData = () => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      data: allFields,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}calculator/reach`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setResultData(result?.data);
        setShow_more(true);
        setloadershown(false);
        console.log(result);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setloadershown(false);
      });
  };

  const resetCampaignReachPlanner = () => {
    setAllFields([
      {
        country: "",
        gender: [],
        age: [],
        selectGender: [],
        selectAge: [],
        inventories: [{ product: "", offerType: "", days: "", budget: "" }],
      },
    ]);
    setResultData([]);
  };

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}countries/getCountries`,
      {
        method: "GET",
      }
    );
    const result = await response.json();
    setCountries(result.data);
  };

  const getCountryData = () => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      age: allAgeRange,
      country: filteredCountries,
      gender: gender,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}calculator/abstract`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setPopolatedData(result);
        setloadershown(false);
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    processdata();
  }, [populatedData]);

  const processdata = async () => {
    let ageusages = [];
    populatedData?.data?.map((curelem) => {
      let curelearray = {};
      Object.keys(curelem.value.use_as_per_age).forEach((age_value) => {
        let keydata = JSON.parse(curelem.value.use_as_per_age[age_value]);
        keydata.forEach((pop_val, index) => {
          let ageindex = ageRanges.findIndex(
            (x) => x.ageRangeCode == pop_val.ageRangeCode
          );
          if (ageindex != -1) {
            keydata[index] = {
              ...keydata[index],
              description: ageRanges[ageindex]?.description,
            };
          }
        });
        curelearray = { ...curelearray, [age_value]: keydata };
      });
      let newelement = {
        ...curelem.value,
        parsedvalues: curelearray,
        key: curelem.key,
      };
      // ageusages={}

      ageusages.push(newelement);
    });
    await setuseasAgeRange(ageusages);
  };

  const duplicateInventroy = (i) => {
    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      if (updatedFields[i].inventories?.length === 3) {
        toast.error("you cannot add more fields");
      } else {
        updatedFields[i].inventories.push({
          product: "",
          offerType: "5",
          days: "30",
          budget: "",
        });
      }

      return updatedFields;
    });
  };

  const duplicateCountry = () => {
    if (allFields?.length === 5) {
      toast.error("You cannot add more fields");
    } else {
      setAllFields((prevFields) => [
        ...prevFields,
        {
          country: "",
          gender: [],
          age: [],
          selectGender: [],
          selectAge: [],
          selectedAges: [],
          inventories: [
            { product: "", offerType: "5", days: "30", budget: "" },
          ],
        },
      ]);
    }
  };

  const handleSetCountries = (e, index) => {
    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
        ...updatedFields[index],
        country: e.target.value,
      };
      return updatedFields;
    });
  };

  const handle_select_countries = (e, index) => {
    let country_value = "";
    const val = countries.find((el) => el.ab === e.target.value);
    country_value = val.labelKey;
    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
        ...updatedFields[index],
        selected_country: country_value,
      };
      return updatedFields;
    });


  }

  const handleSetInventory = (e, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState];
      newState[indexValue].inventories[i].product = e.target.value;
      return newState;
    });
  };

  const handleSetOffer = (e, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState];
      newState[indexValue].inventories[i].offerType = e.target.value;
      return newState;
    });
  };

  const handleSetDays = (e, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState];
      newState[indexValue].inventories[i].days = e.target.value;
      return newState;
    });
  };

  const handleSetBudget = (e, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState];
      newState[indexValue].inventories[i].budget = e.target.value;
      return newState;
    });
  };

  const handleDeleteIndex = (i) => {
    const updatedArray = [...allFields];
    updatedArray.splice(i, 1);
    setAllFields(updatedArray);
  };

  const handleDeleteChildIndex = (index, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState]; // Create a copy of the state array
      newState[index].inventories.splice(i, 1); // Remove the inventory at the specified index
      return newState;
    });
  };

  const handleIndexYoutube = (index) => {
    setYoutubeGraphState(!youtubeGraphState);
    if (youtubeGraphState) {
      for (let i = 0; i < useasAgeRange.length; i++) {
        if (useasAgeRange[i].youtubegraphdata) {
          useasAgeRange[i].youtubegraphdata = false;
          useasAgeRange[i].robloxgraphdata = false;
          useasAgeRange[i].kidscorpgraphdata = false;
        }
        useasAgeRange[index].youtubegraphdata = true;
        useasAgeRange[index].kidscorpgraphdata = false;
        useasAgeRange[index].robloxgraphdata = false;
      }
    } else {
      for (let i = 0; i < useasAgeRange.length; i++) {
        if (useasAgeRange[i].youtubegraphdata) {
          useasAgeRange[i].youtubegraphdata = false;
          useasAgeRange[i].robloxgraphdata = false;
          useasAgeRange[i].kidscorpgraphdata = false;
        }
        useasAgeRange[index].youtubegraphdata = false;
        useasAgeRange[index].kidscorpgraphdata = false;
        useasAgeRange[index].robloxgraphdata = false;
      }
    }
  };

  const handleKidsCorpIndex = (index) => {
    setAppGraphState(!appGraphState);
    if (appGraphState) {
      for (let i = 0; i < useasAgeRange.length; i++) {
        if (useasAgeRange[i].youtubegraphdata) {
          useasAgeRange[i].youtubegraphdata = false;
          useasAgeRange[i].robloxgraphdata = false;
          useasAgeRange[i].kidscorpgraphdata = false;
        }
        useasAgeRange[index].kidscorpgraphdata = true;
        useasAgeRange[index].robloxgraphdata = false;
        useasAgeRange[index].youtubegraphdata = false;
      }
    } else {
      for (let i = 0; i < useasAgeRange.length; i++) {
        if (useasAgeRange[i].youtubegraphdata) {
          useasAgeRange[i].youtubegraphdata = false;
          useasAgeRange[i].robloxgraphdata = false;
          useasAgeRange[i].kidscorpgraphdata = false;
        }
        useasAgeRange[index].kidscorpgraphdata = false;
        useasAgeRange[index].robloxgraphdata = false;
        useasAgeRange[index].youtubegraphdata = false;
      }
    }
  };

  const handleIndexRoblox = (index) => {
    setRobloxGraphState(!robloxGraphState);
    if (robloxGraphState) {
      for (let i = 0; i < useasAgeRange.length; i++) {
        if (useasAgeRange[i].youtubegraphdata) {
          useasAgeRange[i].youtubegraphdata = false;
          useasAgeRange[i].robloxgraphdata = false;
          useasAgeRange[i].kidscorpgraphdata = false;
        }
        useasAgeRange[index].robloxgraphdata = true;
        useasAgeRange[index].youtubegraphdata = false;
        useasAgeRange[index].kidscorpgraphdata = false;
      }
    } else {
      for (let i = 0; i < useasAgeRange.length; i++) {
        if (useasAgeRange[i].youtubegraphdata) {
          useasAgeRange[i].youtubegraphdata = false;
          useasAgeRange[i].robloxgraphdata = false;
          useasAgeRange[i].kidscorpgraphdata = false;
        }
        useasAgeRange[index].robloxgraphdata = false;
        useasAgeRange[index].youtubegraphdata = false;
        useasAgeRange[index].kidscorpgraphdata = false;
      }
    }
  };

  const handleSelectedAges = () => {
    alert("hey you selected some fields from the age range");
    let data = [];
  };
  // const exportData = async () => {
  //   setUploading(true);
  //   setloadershown(true);
  //   const targetElement = targetRef.current;

  //   if (!targetElement) {
  //     console.error("Target element is null or undefined.");
  //     return;
  //   }

  //   try {
  //     // Ensure all images inside targetElement are loaded and have non-zero dimensions
  //     const images = Array.from(targetElement.querySelectorAll('img')).filter(img => img.offsetWidth > 0 && img.offsetHeight > 0);
  //     await Promise.all(images.map(img => img.decode()));

  //     // Calculate the total height of the content inside targetElement
  //     const totalContentHeight = targetElement.scrollHeight;
  //     const pageHeight = 900; // Set the height of each page

  //     // Calculate the number of pages needed
  //     const totalPages = Math.ceil(totalContentHeight / pageHeight);

  //     // Create a PDF document
  //     const pdf = new jsPDF({
  //       orientation: 'p',
  //       format: 'letter',
  //       precision: 2,
  //     });

  //     // Loop through each page and add content to the PDF
  //     for (let currentPage = 0; currentPage < totalPages; currentPage++) {
  //       // Calculate the height of the current chunk/page
  //       const chunkHeight = Math.min(pageHeight, totalContentHeight - currentPage * pageHeight);

  //       // Create a canvas element for each page
  //       const canvas = document.createElement('canvas');
  //       canvas.width = 1450;
  //       canvas.height = chunkHeight;

  //       // Set the scroll position to capture the correct portion of the content
  //       targetElement.scrollTop = currentPage * pageHeight;

  //       // Draw the HTML content onto the canvas with scaling
  //       await html2canvas(targetElement, {
  //         canvas: canvas,
  //         logging: true,
  //         scale: 1,
  //         windowHeight: chunkHeight,
  //         imageTimeout: 15000,
  //         proxy: null,
  //         height: chunkHeight,
  //       });

  //       // Convert canvas to an image and add it to the PDF
  //       const imgData = canvas.toDataURL('image/png');
  //       const imgWidth = 210; // A4 width in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

  //       // Add a new page if there are more pages to generate
  //       if (currentPage < totalPages - 1) {
  //         pdf.addPage();
  //       }
  //     }

  //     // Save the PDF file
  //     pdf.save('Reports.pdf');
  //     setUploading(false);
  //     setloadershown(false);
  //   } catch (error) {
  //     console.error("Error exporting HTML to PDF:", error);
  //     setUploading(false);
  //     setloadershown(false);
  //   }
  // };
//   const exportData = async () => {
//     setUploading(true);
//     setloadershown(true);
//     const targetElement = targetRef.current;

//     if (!targetElement) {
//         console.error("Target element is null or undefined.");
//         return;
//     }

//     try {
//         // Wait for all images to load before rendering to canvas
//         const images = Array.from(targetElement.querySelectorAll('img'));
//         await Promise.all(images.map(img => img.decode()));

//         // Calculate total content height
//         const totalContentHeight = targetElement.scrollHeight;
//         const chunkHeight = 1900; // Set a maximum height for each chunk (adjust as needed)

//         // Calculate number of chunks required
//         const numChunks = Math.ceil(totalContentHeight / chunkHeight);

//         // Create a PDF document
//         const pdf = new jsPDF({
//             orientation: 'p',
//             format: 'a4',
//             precision: 2,
//         });

//         // Render content in chunks
//         for (let i = 0; i < numChunks; i++) {
//             // Calculate start and end offsets for the current chunk
//             const startOffset = i * chunkHeight;
//             const endOffset = Math.min((i + 1) * chunkHeight, totalContentHeight);

//             // Set targetElement's scrollTop to the current chunk
//             targetElement.scrollTop = startOffset;

//             // Render the chunk to canvas
//             const canvas = await html2canvas(targetElement, {
//                 logging: true,
//                 scale: 2,
//                 windowWidth: window.innerWidth,
//                 windowHeight: window.innerHeight,
//                 useCORS: true,
//                 scrollY: -window.scrollY,
//                 imageTimeout: 15000,
//                 proxy: null,
//                 y: startOffset, // Set the y-coordinate to capture the current chunk
//             });

//             // Convert canvas to an image and add it to the PDF
//             const imgData = canvas.toDataURL('image/png');
//             const imgWidth = 205; // A4 width in mm
//             const imgHeight = (canvas.height * imgWidth) / canvas.width;
//             pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

//             // Add a new page for subsequent chunks
//             if (i < numChunks - 1) {
//                 pdf.addPage();
//             }
//         }

//         // Save the PDF file
//         const pdfBlob = pdf.output('blob');
//         const pdfUrl = URL.createObjectURL(pdfBlob);

//         // Open the PDF in a new window
//         window.open(pdfUrl, '_blank');

//         setUploading(false);
//         setloadershown(false);
//     } catch (error) {
//         console.error("Error exporting HTML to PDF:", error);
//         setUploading(false);
//         setloadershown(false);
//     }
// };



// this below is main
  // const exportData = async () => {
  //   setUploading(true);
  //   setloadershown(true);
  //   const targetElement = targetRef.current;
  //   const height = targetElement.clientHeight / 5 ;

  //   if (!targetElement) {
  //     console.error("Target element is null or undefined.");
  //     return;
  //   }

  //   // Set default dimensions for elements with 0 width or height
  //   const setDefaultDimensions = (element) => {
  //     if (element.offsetWidth === 0) {
  //       element.style.width = "100px"; // Set a default width (adjust as needed)
  //     }
  //     if (element.offsetHeight === 0) {
  //       element.style.height = "100px"; // Set a default height (adjust as needed)
  //     }
  //   };

  //   // Recursively set default dimensions for all child elements
  //   const setDefaultDimensionsRecursively = (element) => {
  //     setDefaultDimensions(element);
  //     const children = element.children;
  //     for (let i = 0; i < children.length; i++) {
  //       setDefaultDimensionsRecursively(children[i]);
  //     }
  //   };

  //   // Set default dimensions for targetElement and its children
  //   setDefaultDimensionsRecursively(targetElement);

  //   try {
  //     // Ensure all images inside targetElement are loaded and have non-zero dimensions
  //     const images = Array.from(targetElement.querySelectorAll('img')).filter(img => img.offsetWidth > 0 && img.offsetHeight > 0);
  //     await Promise.all(images.map(img => img.decode()));

  //     // Calculate the total height of the content inside targetElement
  //     const totalContentHeight = targetElement.scrollHeight;

  //     // Create a canvas element with dynamic height based on the content
  //     const canvas = document.createElement('canvas');
  //     canvas.width = 1450;
  //     canvas.height = totalContentHeight + 1000; // Adding some padding

  //     // Draw the HTML content onto the canvas with scaling
  //     await html2canvas(targetElement, {
  //       canvas: canvas,
  //       logging: true, // Enable logging for detailed information (optional)
  //       scale: 1, // Scale factor to fit content within the canvas
  //       windowWidth: document.documentElement.scrollWidth,
  //       windowHeight: window.scrollHeight,
  //       imageTimeout : 15000,
  //       proxy : null,
  //       height : 1800,
  //       autoPaging: true,
  //       scrollY: -window.scrollY
  //     });

  //     // Create a PDF document
  //     const pdf = new jsPDF({
  //       orientation: 'p',
  //       format: [210,height],
  //       precision: 2,
  //     });

  //     // Convert canvas to an image and add it to the PDF
  //     const imgData = canvas.toDataURL('image/png');
  //     const imgWidth = 210; // A4 width in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

  //     // Save the PDF file
  //     pdf.save('Reports.pdf');
  //     setUploading(false);
  //     setloadershown(false);
  //   } catch (error) {
  //     console.error("Error exporting HTML to PDF:", error);
  //     setUploading(false);
  //     setloadershown(false);
  //   }
  // };

  const exportData = async () => {
    setUploading(true);
    setloadershown(true);
    const targetElement = targetRef.current;
    let total_height;
    const height = targetElement.clientHeight / 5;
  
    if (!targetElement) {
      console.error("Target element is null or undefined.");
      return;
    }
  
    // Set default dimensions for elements with 0 width or height
    const setDefaultDimensions = (element) => {
      if (element.offsetWidth === 0) {
        element.style.width = "100px"; // Set a default width (adjust as needed)
      }
      if (element.offsetHeight === 0) {
        element.style.height = "100px"; // Set a default height (adjust as needed)
      }
    };
  
    // Recursively set default dimensions for all child elements
    const setDefaultDimensionsRecursively = (element) => {
      setDefaultDimensions(element);
      const children = element.children;
      for (let i = 0; i < children.length; i++) {
        setDefaultDimensionsRecursively(children[i]);
      }
    };
  
    // Set default dimensions for targetElement and its children
    setDefaultDimensionsRecursively(targetElement);
  
    try {
      // Ensure all images inside targetElement are loaded and have non-zero dimensions
      const images = Array.from(targetElement.querySelectorAll('img')).filter(img => img.offsetWidth > 0 && img.offsetHeight > 0);
      await Promise.all(images.map(img => img.decode()));
  
      // Calculate the total height of the content inside targetElement
      const totalContentHeight = targetElement.scrollHeight;
  
      // Create a canvas element with dynamic height based on the content
      const canvas = document.createElement('canvas');
      canvas.width = 1450;
      canvas.height = totalContentHeight + 1000; // Adding some padding
      
      // Draw the HTML content onto the canvas with scaling
      await html2canvas(targetElement, {
        canvas: canvas,
        logging: true, // Enable logging for detailed information (optional)
        scale: 1, // Scale factor to fit content within the canvas
        allowTaint: true,
        windowWidth: canvas.width,
        windowHeight: canvas.height,
        imageTimeout: 15000,
        proxy: null,
        height: 1800,
        autoPaging: true,
        scrollY: -window.scrollY
      });
      
      // Create a PDF document
      const pdf = new jsPDF({
        orientation: 'p',
        format: [208, canvas.height / 8],
        precision: 2,
      });
      // Convert canvas to an image and add it to the PDF
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  
      // Save the PDF file
      pdf.save('Reports.pdf');
      setUploading(false);
      setloadershown(false);
    } catch (error) {
      console.error("Error exporting HTML to PDF:", error);
      setUploading(false);
      setloadershown(false);
    }
  };
  


  let current = new Date();
  let cDate = current.getDate() + '/' + (current.getMonth() + 1) + '/' + current.getFullYear();

  let hours = current.getHours();
  let minutes = current.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12' in PM

  let cTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;

  let dateTime = cDate + ' ' + cTime;


  return (
    <div className="content_outer abstract_filter">
      {/* <div className="container-subtitle"> */}
      <Subtitle subtitle="Reach calculator" />r
      {/* </div> */}
      <Loader showLoader={loadershown} />
      <div className="content pt-3 mt-5">
        <div className="">
          <div className="reach_calculater_tabs">
            <Tabs
              defaultActiveKey="kite_universe"
              id="controlled-tab-example"
              // activeKey={key}
              // onSelect={(k) => setKey(k)}
              className="tabs_outer_wrapper bg-white tab-lg campion_bg"
            >
              <Tab
                eventKey="kite_universe"
                className="mt-5"
                title="Kite Universe"
              >
                <div className="kite_uni ">
                  <Row className="">
                    <Col lg={5}>
                     <div className="select_country_container input_wrap mt-0">
                      <label htmlFor="" className="center_label lbl_position form-label">Country</label>
                     <MultiSelect
                        options={countries.map((i) => ({
                          ...i,
                          label: i.labelKey,
                          value: i.ab,
                        }))}
                        value={selected}
                        onChange={handlecountrychange}
                        disableSearch={true}
                        hasSelectAll={false}
                        labelledBy="Select"
                        overrideStrings={{
                          selectSomeItems: "Countries",
                        }}
                      />
                     </div>
                    </Col>
                    <Col lg={5}>
                      <div role="group" className="select_country_container input_wrap mt-0" aria-labelledby="checkbox-group ">
                        {/* {genders?.map((gender, gender_index) => {

                                                return (
                                                    <label className="form-label mx-2 mb-0" key={gender_index}>
                                                        <input type="checkbox" name="audiences" value={gender.value} onChange={handleGanderchange} />
                                                        <span className='ms-1'> {gender?.label}</span>
                                                    </label>
                                                )

                                            })} */}
                                               <label htmlFor="" className="center_label lbl_position form-label">Gender</label>
                        <MultiSelect
                          options={genders.map((gender) => ({
                            ...gender,
                            label: gender.label,
                            value: gender.value,
                          }))}
                          value={selectedGender}
                          onChange={handleGanderchange}
                          disableSearch={true}
                          hasSelectAll={false}
                          labelledBy="Select"
                          overrideStrings={{
                            selectSomeItems: "Gender",
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <Button
                        disabled={selected != "" ? false : disabled}
                        bg={
                          selected != ""
                            ? buttonColors.search
                            : buttonColors.disabled
                        }
                        color={textColors.search}
                        text="Search"
                        onClick={getCountryData}
                      />
                    </Col>
                  </Row>
                </div>
                {useasAgeRange?.map((data, index) => {
                  return data.incidencia.AppsKidscorp === 0 ? null : (
                    <div className="p-4">
                      <div className="filter_search_universe mb-3">
                        <Row className="align-items-center">
                          <Col lg={12} className="result-search">
                            <div className="pe-3">
                              <div className="bg_white select_country_globe">
                                <div className="social_image mr-2">
                                  {data.key === "AR" && <ArIcon size="35px" />}
                                  {data.key === "BO" && (
                                    <img
                                      src="assets/images/bo.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "BR" && (
                                    <img
                                      src="assets/images/br.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "CL" && (
                                    <img
                                      src="assets/images/cl.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "CO" && (
                                    <img
                                      src="assets/images/co.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "CR" && (
                                    <img
                                      src="assets/images/cr.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "EC" && (
                                    <img
                                      src="assets/images/ec.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "SV" && (
                                    <img
                                      src="assets/images/sv.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "GT" && (
                                    <img
                                      src="assets/images/gt.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "HN" && (
                                    <img
                                      src="assets/images/hn.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "MX" && (
                                    <img
                                      src="assets/images/mx.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "NI" && (
                                    <img
                                      src="assets/images/ni.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PA" && (
                                    <img
                                      src="assets/images/pa.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PY" && (
                                    <img
                                      src="assets/images/py.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PE" && (
                                    <img
                                      src="assets/images/pe.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PR" && (
                                    <img
                                      src="assets/images/pr.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "DO" && (
                                    <img
                                      src="assets/images/do.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "UY" && (
                                    <img
                                      src="assets/images/uy.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "VE" && (
                                    <img
                                      src="assets/images/ve.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "US" && (
                                    <img
                                      src="assets/images/us.png"
                                      alt="country"
                                    />
                                  )}
                                  {/* <img src="assets/images/country.png" alt="country" /> */}
                                  <span className="name-country">
                                    {
                                      countries.find(
                                        ({ ab }) =>
                                          ab === data?.key.toLowerCase()
                                      )?.labelKey
                                    }
                                  </span>
                                </div>

                                <div className="text-world">
                                  <div className="world-space">
                                    {/* <WorldIcon /> */}
                                  </div>
                                  <h4 className="text-total text-center">
                                    Total connected population
                                    {/* {
																			countries.find(
																				({ ab }) =>
																					ab === data?.key.toLowerCase()
																			)?.labelKey
																		}
																		's */}
                                    {/* <h4 className="population_heading">
																		Connected Population
																	</h4> */}
                                    <span className="space-population">
                                      {data?.connected_population?.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 0 }
                                      )}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Row>
                              <Row
                                onClick={() => {
                                  setSelectedType("youtube");
                                }}
                              >
                                <div className="">
                                  <div
                                    className="bg_white youtube accordian_bg"
                                    onClick={() => {
                                      handleIndexYoutube(index);
                                      //  handleYoutubeGraph(data.key, index) ;
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      {/* <div className="social_image"> */}
                                      {/* <img
                                          src="assets/images/youtube.png"
                                          alt="youtube"
                                        /> */}
                                      {/* <YoutubeIcon size="50px" /> */}
                                      {/* </div> */}
                                      <p class="abs_badge mb-0">YouTube</p>
                                      <div className="text-container">
                                        <h5>Total Selected Audience</h5>
                                        <p className="result-social">
                                          {parseInt(
                                            data?.poblacion_proyectada?.YouTube
                                          )?.toLocaleString()}
                                        </p>
                                      </div>
                                      <div className="text-container">
                                        <h5>Incidence on Selected Audience</h5>
                                        <p className="result-social">
                                          {data?.incidencia?.YouTube?.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                          )}
                                          %
                                        </p>
                                      </div>
                                      {arrowYou ? (
                                        <FontAwesomeIcon
                                          icon={faAngleUp}
                                          className="vector"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faAngleDown}
                                          className="vector"
                                        />
                                      )}
                                    </div>
                                    <div id="youtube" className="">
                                      <Row className="result-graf">
                                        {data?.youtubegraphdata && (
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  % Incidence by Age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      type: "category",
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    // legend: {
                                                    //     enabled: false
                                                    // },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      series: {
                                                        borderWidth: 0,
                                                        dataLabels: {
                                                          enabled: true,
                                                          format:
                                                            "{point.y:.1f}%",
                                                        },
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormat:
                                                        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
                                                    },

                                                    series: [
                                                      {
                                                        colorByPoint: true,
                                                        showInLegend: false,
                                                        //   data: ( youtubestate && youtubestate?.map((data,index) => ({  name: data?.description, y: data?.percentage })))
                                                        data:
                                                          data?.parsedvalues
                                                            ?.YouTube !== [] &&
                                                          data?.parsedvalues?.YouTube?.map(
                                                            (el, index) => ({
                                                              name: el?.description,
                                                              y: el?.percentage,
                                                              color: "#f978c8",
                                                            })
                                                          ),
                                                      },
                                                    ],
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        )}

                                        {/* youtube percentage chart */}
                                        {data?.youtubegraphdata && (
                                          <Col lg={6}>
                                            <div>
                                              <div className="graph card-header">
                                                <h2 className="graph_title text-center">
                                                  YouTube projected population
                                                  by age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      categories: ageRanges.map(
                                                        (i) => {
                                                          return i?.description;
                                                        }
                                                      ),
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    //  legend: {
                                                    //     layout: 'vertical',
                                                    //     align: 'right',
                                                    //     verticalAlign: 'middle'
                                                    // },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      line: {
                                                        dataLabels: {
                                                          enabled: true,
                                                        },
                                                        enableMouseTracking: false,
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormatter:
                                                        function () {
                                                          return (
                                                            '<span style="color:' +
                                                            this.color +
                                                            '">' +
                                                            this.category +
                                                            "</span>: <b>" +
                                                            this.y.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 0, // Minimum number of fraction digits
                                                                maximumFractionDigits: 2, // Maximum number of fraction digits
                                                              }
                                                            ) +
                                                            "</b> of total<br/>"
                                                          );
                                                        },
                                                    },

                                                    series: [
                                                      {
                                                        name: "",
                                                        data: data?.parsedvalues?.YouTube?.map(
                                                          (i, index) => {
                                                            return parseInt(
                                                              i?.proprojectedPopulation
                                                            );
                                                          }
                                                        ),
                                                        color: "#00eacb",
                                                        showInLegend: false,
                                                      },
                                                    ],
                                                    responsive: {
                                                      rules: [
                                                        {
                                                          condition: {
                                                            maxWidth: 500,
                                                          },
                                                          chartOptions: {
                                                            // legend: {
                                                            //     layout: 'horizontal',
                                                            //     align: 'center',
                                                            //     verticalAlign: 'bottom'
                                                            // }
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Row>

                              <Row
                                onClick={() => {
                                  setSelectedType("robolox");
                                }}
                              >
                                <div className="">
                                  <div
                                    className="bg_white youtube accordian_bg"
                                    onClick={() => {
                                      handleIndexRoblox(index);
                                      // handleRobloxGraph(data.key, index)
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p class="abs_badge mb-0">Roblox</p>

                                      <div className="text-container">
                                        <h5 className="">
                                          Total Selected Audience
                                        </h5>
                                        <p className="result-social">
                                          {parseInt(
                                            data?.poblacion_proyectada?.Roblox
                                          )?.toLocaleString()}
                                        </p>
                                      </div>
                                      <div className="text-container">
                                        <h5>Incidence on Selected Audience</h5>
                                        <p className="result-social">
                                          {data?.incidencia?.Roblox?.toFixed(2)}
                                          %
                                        </p>
                                      </div>
                                      {arrowRo ? (
                                        <FontAwesomeIcon
                                          icon={faAngleUp}
                                          className="vector"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faAngleDown}
                                          className="vector"
                                        />
                                      )}
                                    </div>

                                    <div id="roblox" className="">
                                      <Row className="result-grafRo">
                                        {data?.robloxgraphdata && (
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  % Incidence by Age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      type: "category",
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    // legend: {
                                                    //     enabled: false
                                                    // },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      series: {
                                                        borderWidth: 0,
                                                        dataLabels: {
                                                          enabled: true,
                                                          format:
                                                            "{point.y:.1f}%",
                                                        },
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormat:
                                                        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
                                                    },
                                                    series: [
                                                      {
                                                        colorByPoint: true,
                                                        showInLegend: false,
                                                        //   data: ( youtubestate && youtubestate?.map((data,index) => ({  name: data?.description, y: data?.percentage })))
                                                        data: data?.parsedvalues?.Roblox?.map(
                                                          (el, index) => ({
                                                            name: el?.description,
                                                            y: el?.percentage,
                                                            color: "#f978c8",
                                                          })
                                                        ),
                                                      },
                                                    ],
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        )}

                                        {/* roblox percentage chart */}
                                        {data?.robloxgraphdata && (
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  Roblox projected population by
                                                  age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      categories: ageRanges.map(
                                                        (i) => {
                                                          return i?.description;
                                                        }
                                                      ),
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    //  legend: {
                                                    //     layout: 'vertical',
                                                    //     align: 'right',
                                                    //     verticalAlign: 'middle'
                                                    // },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      line: {
                                                        dataLabels: {
                                                          enabled: true,
                                                        },
                                                        enableMouseTracking: false,
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormatter:
                                                        function () {
                                                          return (
                                                            '<span style="color:' +
                                                            this.color +
                                                            '">' +
                                                            this.category +
                                                            "</span>: <b>" +
                                                            this.y.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 0, // Minimum number of fraction digits
                                                                maximumFractionDigits: 2, // Maximum number of fraction digits
                                                              }
                                                            ) +
                                                            "</b> of total<br/>"
                                                          );
                                                        },
                                                    },

                                                    series: [
                                                      {
                                                        name: "",
                                                        showInLegend: false,
                                                        data: data?.parsedvalues?.Roblox?.map(
                                                          (i, index) => {
                                                            return parseInt(
                                                              i?.proprojectedPopulation
                                                            );
                                                          }
                                                        ),
                                                        color: "#00eacb",
                                                      },
                                                    ],
                                                    responsive: {
                                                      rules: [
                                                        {
                                                          condition: {
                                                            maxWidth: 500,
                                                          },
                                                          chartOptions: {
                                                            // legend: {
                                                            //     layout: 'horizontal',
                                                            //     align: 'center',
                                                            //     verticalAlign: 'bottom'
                                                            // }
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Row>

                              <Row
                                onClick={() => {
                                  setSelectedType("kidscorp");
                                }}
                              >
                                <div className="">
                                  <div
                                    className="bg_white youtube accordian_bg"
                                    onClick={() => {
                                      handleKidsCorpIndex(index);
                                      // handleKidscorpGraph(data.key, index)
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p class="abs_badge mb-0">Apps</p>
                                      <div className="text-container">
                                        <h5>Total Selected Audience</h5>
                                        <p className="result-social">
                                          {parseInt(
                                            data?.poblacion_proyectada
                                              ?.AppsKidscorp
                                          )?.toLocaleString()}
                                        </p>
                                      </div>
                                      <div className="text-container">
                                        <h5>Incidence on Selected Audience</h5>
                                        <p className="result-social">
                                          {data?.incidencia?.AppsKidscorp?.toFixed(
                                            2
                                          )}
                                          %
                                        </p>
                                      </div>
                                      {arrowKid ? (
                                        <FontAwesomeIcon
                                          icon={faAngleUp}
                                          className="vector"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faAngleDown}
                                          className="vector"
                                        />
                                      )}
                                    </div>
                                    <div id="kid" className="">
                                      <Row className="result-grafKid">
                                        {data?.kidscorpgraphdata && (
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  % Incidence by Age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      type: "category",
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    // legend: {
                                                    //     enabled: false
                                                    // },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      series: {
                                                        borderWidth: 0,
                                                        dataLabels: {
                                                          enabled: true,
                                                          format:
                                                            "{point.y:.1f}%",
                                                        },
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormat:
                                                        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
                                                    },
                                                    series: [
                                                      {
                                                        colorByPoint: true,
                                                        showInLegend: false,
                                                        //   data: ( youtubestate && youtubestate?.map((data,index) => ({  name: data?.description, y: data?.percentage })))
                                                        data: data?.parsedvalues?.AppsKidscorp?.map(
                                                          (el, index) => ({
                                                            name: el?.description,
                                                            y: el?.percentage,
                                                            color: "#f978c8",
                                                          })
                                                        ),
                                                      },
                                                    ],
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        )}

                                        {/* kidscorp percentage chart */}
                                        {data?.kidscorpgraphdata && (
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  Kidscorp projected population
                                                  by age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      categories: ageRanges.map(
                                                        (i) => {
                                                          return i?.description;
                                                        }
                                                      ),
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    //  legend: {
                                                    //     layout: 'vertical',
                                                    //     align: 'right',
                                                    //     verticalAlign: 'middle'
                                                    // },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      line: {
                                                        dataLabels: {
                                                          enabled: true,
                                                        },
                                                        enableMouseTracking: false,
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormatter:
                                                        function () {
                                                          return (
                                                            '<span style="color:' +
                                                            this.color +
                                                            '">' +
                                                            this.category +
                                                            "</span>: <b>" +
                                                            this.y.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 2,
                                                                useGrouping: true, // Add thousand separators
                                                              }
                                                            ) +
                                                            "</b> of total<br/>"
                                                          );
                                                        },
                                                    },

                                                    series: [
                                                      {
                                                        name: "",
                                                        showInLegend: false,
                                                        data: data?.parsedvalues?.AppsKidscorp?.map(
                                                          (i, index) => {
                                                            return parseInt(
                                                              i?.proprojectedPopulation
                                                            );
                                                          }
                                                        ),
                                                        color: "#00eacb",
                                                      },
                                                    ],
                                                    responsive: {
                                                      rules: [
                                                        {
                                                          condition: {
                                                            maxWidth: 500,
                                                          },
                                                          chartOptions: {
                                                            // legend: {
                                                            //     layout: 'horizontal',
                                                            //     align: 'center',
                                                            //     verticalAlign: 'bottom'
                                                            // }
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      {/* graphs starts here */}
                    </div>
                  );
                  // }
                })}
              </Tab>

              <Tab
                eventKey="campaignreach"
                title="Campaign Reach Planner"
                className="mt-5"
              >
                <div className="reach_filter_wrapper">
                  <div className="d-flex align-items-center">
                    {" "}
                    <h4
                      className={
                        !show_more
                          ? "filter_headings mb-0 flex-grow-1"
                          : "filter_headings flex-grow-1"
                      }
                    >
                      Filter
                    </h4>{" "}
                    {show_more && resultData?.length !== 0 && (
                      <div
                        className="show_btns"
                        onClick={() => {
                          setShow_more(!show_more);
                          setShow_less(!show_less);
                        }}
                      >
                        Show Less
                        <svg
                          className="ms-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="m11.441 14.748-4.464-4.463a.75.75 0 1 1 1.061-1.061l3.96 3.961 3.962-3.96a.749.749 0 1 1 1.06 1.06l-4.492 4.491a.744.744 0 0 1-.53.22.744.744 0 0 1-.557-.248z"
                            fill="#04190D"
                          />
                        </svg>
                      </div>
                    )}
                    {!show_more && resultData?.length !== 0 && show_less && (
                      <div
                        className="show_btns"
                        onClick={() => {
                          setShow_more(!show_more);
                          setShow_less(!show_less);
                        }}
                      >
                        Show More
                        <svg
                          className="ms-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="m11.441 14.748-4.464-4.463a.75.75 0 1 1 1.061-1.061l3.96 3.961 3.962-3.96a.749.749 0 1 1 1.06 1.06l-4.492 4.491a.744.744 0 0 1-.53.22.744.744 0 0 1-.557-.248z"
                            fill="#04190D"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  {show_more && !show_less && (
                    <div className="reach_inner_wrapper">
                      {allFields?.map((fields, index) => {
                        return (
                          <>
                            <div className="kite_uni">
                              <b className="select_country_heading bold_item">
                                Select country and...
                              </b>
                              <Row className="w-100 m-0">
                                <Col lg={4}>
                                  <div className="select_country_container">
                                    <Form.Group className="mb-3 form-group">
                                      <label
                                        htmlFor=""
                                        className="center_label lbl_position"
                                      >
                                        Select Country
                                      </label>
                                      <Form.Select
                                        onChange={(e) => {
                                          handleSetCountries(e, index);
                                          setCountry(e.target.value);
                                          handle_select_countries(e, index)
                                        }}
                                        value={allFields?.selected_country}
                                      >
                                        <option>{allFields[index]?.selected_country ? allFields[index]?.selected_country : "Select"}</option>
                                        {countries?.map((data, i) => (
                                          <option value={data.ab} key={i}>
                                            {" "}
                                            {data.labelKey}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="select_country_container ">
                                    <div
                                      className="form-group justify-content-around"
                                      onClick={() => setIndexValue(index)}
                                    >
                                      <label
                                        htmlFor=""
                                        className="lbl_position lblndex"
                                      >
                                        Select Gender
                                      </label>
                                      {/* {genders?.map((gender_val, gender_index) => {

                                                return (
                                                    <label className="form-label mx-2 mb-0" key={gender_index}>
                                                        <input type="checkbox" name="audiences" value={gender_val.value} onChange={handleCampaignGenderChange} checked={campaigngender.includes(gender_val.value)} />
                                                        <span className='ms-1'> {gender_val?.label}</span>
                                                    </label>
                                                )
                                            })} */}
                                      <MultiSelect
                                        options={genders.map((gender) => ({
                                          ...gender,
                                          label: gender.label,
                                          value: gender.value,
                                        }))}
                                        value={allFields[index]?.selectGender}
                                        onChange={handleGanderchange}
                                        disableSearch={false}
                                        hasSelectAll={false}                                        
                                        labelledBy="Select Gender"
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="budget_container">
                                    <div className="select_country_container">
                                      <div
                                        className="form-group "
                                        onClick={() => setIndexValue(index)}
                                      >
                                        <label
                                          htmlFor=""
                                          className="lbl_position lblndex"
                                        >
                                          Select Age Range
                                        </label>
                                        {/* {ageRanges?.map((age, age_index) => {
                                                return (
                                                    <div className="form-check" key={age_index}>
                                                        <input className="form-check-input" type="checkbox" value={age?.ageRangeCode.toString()} onChange={handleCampaignAgeRangeChange} checked={campaignagerange.includes(age?.ageRangeCode.toString())} />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            {age?.description}
                                                        </label>
                                                    </div>
                                                )

                                            })} */}
                                        <MultiSelect
                                          options={ageRanges.map((i) => ({
                                            ...i,
                                            label: i.description,
                                            value: i.ageRangeCode.toString(),
                                          }))}
                                          value={allFields[index]?.selectAge}
                                          onChange={
                                            handleCampaignAgeRangeChange
                                          }
                                          disableSearch={true}
                                          hasSelectAll={false}
                                          labelledBy="Select Country"
                                        />
                                      </div>
                                    </div>
                                    <div className="delete_icon">
                                      {allFields?.length > 1 && (
                                        <AiFillDelete
                                          onClick={() =>
                                            handleDeleteIndex(index)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <hr className="dot_hr_line"></hr>
                              <Row className="mt-3 m-0">
                                <Col lg={10}>
                                  <Row className="mr-0">
                                    {fields?.inventories?.map((inFields, i) => {
                                      return (
                                        <>
                                          <Col
                                            lg={6}
                                            onClick={() => setIndexValue(index)}
                                          >
                                            <div className="select_country_container">
                                              <Form.Group className="mb-3 form-group">
                                                <label
                                                  htmlFor=""
                                                  className="center_label lbl_position"
                                                >
                                                  Select Inventory Type
                                                </label>
                                                <Form.Select
                                                  onChange={(e) => {
                                                    handleSetInventory(e, i);
                                                    setProduct(e.target.value);
                                                  }}
                                                  value={inFields?.product}
                                                >
                                                  <option>select</option>
                                                  {products?.map(
                                                    (data, index) => (
                                                      <option
                                                        value={data.productCode}
                                                        key={index}
                                                      >
                                                        {" "}
                                                        {data.description}
                                                      </option>
                                                    )
                                                  )}
                                                </Form.Select>
                                              </Form.Group>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="budget_container">
                                              <div className="select_country_container">
                                                <div
                                                  className="form-group"
                                                  onClick={() =>
                                                    setIndexValue(index)
                                                  }
                                                >
                                                  <label
                                                    htmlFor=""
                                                    className="center_label lbl_position"
                                                  >
                                                    Select Budget USD
                                                  </label>
                                                  <input
                                                    type="number"
                                                    name="inversion_budget"
                                                    onChange={(e) => {
                                                      setBudget(e.target.value);
                                                      handleSetBudget(e, i);
                                                    }}
                                                    value={inFields?.budget}
                                                    className="form-control"
                                                  />
                                                </div>
                                              </div>
                                              <div className="delete_icon">
                                                {fields.inventories?.length >
                                                  1 && (
                                                    <AiFillDelete
                                                      onClick={() =>
                                                        handleDeleteChildIndex(
                                                          index,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  )}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col lg={3} className=" d-none">
                                            <div className="select_country_container">
                                              <div
                                                className="form-group"
                                                onClick={() =>
                                                  setIndexValue(index)
                                                }
                                              >
                                                <label
                                                  htmlFor=""
                                                  className="center_label lbl_position"
                                                >
                                                  Select Offer Type
                                                </label>
                                                <select
                                                  name="inversion_offer_type_id"
                                                  className="form-control"
                                                  value={inFields?.offerType}
                                                  onChange={(e) => {
                                                    setCampaignOfferType(
                                                      e.target.value
                                                    );
                                                    handleSetOffer(e, i);
                                                  }}
                                                >
                                                  <option>select</option>
                                                  {inFields?.product == 1 &&
                                                    offer_types?.map(
                                                      (offr, offr_index) => {
                                                        if (offr.id == 5) {
                                                          return (
                                                            <option
                                                              value={offr?.id}
                                                              key={offr_index}
                                                            >
                                                              {
                                                                offr?.descripcion
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  {inFields?.product == 2 &&
                                                    offer_types?.map(
                                                      (offr, offr_index) => {
                                                        if (offr.id == 5) {
                                                          return (
                                                            <option
                                                              value={offr?.id}
                                                              key={offr_index}
                                                            >
                                                              {
                                                                offr?.descripcion
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  {inFields?.product == 3 &&
                                                    offer_types?.map(
                                                      (offr, offr_index) => {
                                                        if (offr.id == 5) {
                                                          return (
                                                            <option
                                                              value={offr?.id}
                                                              key={offr_index}
                                                            >
                                                              {
                                                                offr?.descripcion
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </select>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col lg={3} className=" d-none">
                                            <div className="select_country_container d-none">
                                              <div
                                                className="form-group"
                                                onClick={() =>
                                                  setIndexValue(index)
                                                }
                                              >
                                                <label
                                                  htmlFor=""
                                                  className="center_label lbl_position"
                                                >
                                                  Select # of Days
                                                </label>
                                                <input
                                                  type="number"
                                                  name="numberdays"
                                                  // onChange={(e) => {
                                                  // 	setNoOfDays(e.target.value);
                                                  // 	handleSetDays(e, i)
                                                  // }}
                                                  value={noofdays}
                                                  className="form-control"
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </>
                                      );
                                    })}
                                  </Row>
                                </Col>
                                <Col lg={2}>
                                  <Row>
                                    <Col
                                      lg={12}
                                      className="add_inventory_btn_outer"
                                    >
                                      <div className="form-group  mb-3">
                                        <button
                                          className="add_inventorty_btn"
                                          onClick={() =>
                                            duplicateInventroy(index)
                                          }
                                        >
                                          {" "}
                                          <BiPlus />
                                          Add Inventory Type
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <hr className="horizontal_line mt-4 "></hr>
                            </div>
                          </>
                        );
                      })}
                      <div className="add_country_wrapper">
                        <div className="add_inventory_btn_outer">
                          {allFields?.length !== 5 ? (
                            <div className="form-group mb-3">
                              <button
                                className="add_country_btn add_inventorty_btn"
                                onClick={duplicateCountry}
                              >
                                {" "}
                                <BiPlus />
                                Add Country
                              </button>
                            </div>
                          ) : null}
                        </div>
                        <Col lg={4}>
                          <div className="d-flex setbuttons">
                            <button
                              className="cmn_btn reset_button_color me-2"
                              onClick={resetCampaignReachPlanner}
                            >
                              Reset
                            </button>

                            <button
                              className="cmn_btn search_btn "
                              onClick={() => {
                                submitCampaignReach();
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  )}
                </div>
                <div className="position-relative">

                  <div className="pdf_wrapper" ref={targetRef}>
                    {uploading && <div className=" p-3 pdf_head">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src="/assets/images/kids_header.svg" width="180" />
                        <img src="/assets/images/mariano.png" width="200" />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <h2 style={{ fontSize: "32px", fontWeight: "bold" }} className="mb-0 header_title">Reach Calculator Export</h2>
                        <h2 style={{ fontSize: "32px" }} className="mb-0 header_title">{dateTime}</h2>
                      </div>
                    </div>}
                    {resultData?.length !== 0 && (
                      <div className="summary">
                        <ul>
                          <li>
                            <h5>Total Summary</h5>
                          </li>
                          <li>
                            <p>
                              Country:{" "}
                              <strong>
                                {resultData[
                                  resultData.length - 1
                                ].Report?.countryName?.map((data) => {
                                  switch (data) {
                                    case "ar":
                                      return "Argentina,";
                                    case "bo":
                                      return "Bolivia,";
                                    case "br":
                                      return "Brasil,";
                                    case "cl":
                                      return "Chile,";
                                    case "co":
                                      return "Colombia,";
                                    case "cr":
                                      return "Costa Rica,";
                                    case "ec":
                                      return "Ecuador,";
                                    case "sv":
                                      return "El Salvador,";
                                    case "gt":
                                      return "Guatemala,";
                                    case "hn":
                                      return "Honduras,";
                                    case "mx":
                                      return "Mexico,";
                                    case "ni":
                                      return "Nicaragua,";
                                    case "pa":
                                      return "Panama,";
                                    case "py":
                                      return "Paraguay,";
                                    case "pe":
                                      return "Peru,";
                                    case "pr":
                                      return "Puerto Rico,";
                                    case "do":
                                      return "Republica Dominicana,";
                                    case "uy":
                                      return "Uruguay,";
                                    case "ve":
                                      return "Venezuela,";
                                    case "us":
                                      return "United States,";
                                    default:
                                      return data;
                                  }
                                })}
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Total budget:{" USD "}
                              <strong>
                                {
                                  resultData[resultData?.length - 1].Report
                                    ?.allBudgetSum?.toLocaleString(undefined, { minimumFractionDigits: 0 })
                                }
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Apps reach:{" "}
                              <strong>
                                {resultData[resultData?.length - 1].Report
                                  ?.appReachSum
                                  ? resultData[resultData?.length - 1].Report
                                    ?.appReachSum?.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  : "-"}
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Youtube reach:{" "}
                              <strong>
                                {resultData[resultData?.length - 1].Report
                                  ?.youtubeReachSum
                                  ? resultData[resultData?.length - 1].Report
                                    ?.youtubeReachSum?.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  : "-"}
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Roblox reach:{" "}
                              <strong>
                                {resultData[resultData?.length - 1].Report
                                  ?.robloxReachSum
                                  ? resultData[resultData?.length - 1].Report
                                    ?.robloxReachSum?.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  : "-"}
                              </strong>
                            </p>
                          </li>
                        </ul>
                      </div>
                    )}


                    {resultData?.map((values, indexVal) => {
                      console.log(values, "thi is the actua; refmffnkfnf");
                      if (
                        Array.isArray(values?.inventory) &&
                        values?.inventory.length > 0 &&
                        values?.inventory.every((item) => item !== null) &&
                        Object.keys(values?.insight).length > 0
                      ) {
                        // Your code here
                        return (

                          <div key={indexVal} className="audience_outer_wrapper mt-3">
                            <div className="flag_outer">
                              <h2 className="heading_argentina">
                                {values?.country === "ar" && (
                                  <ArgentinaIcon size={35} />
                                )}
                                {values?.country === "bo" && (
                                  <BoliviaIcon size={35}/>
                                )}
                                {values?.country === "br" && (
                                  <BrasilIcon size={35} />
                                )}
                                {values?.country === "cl" && (
                                  <ChileIcon size={35} />
                                )}
                                {values?.country === "co" && (
                                  <ColombiaIcon size={35} />
                                )}
                                {values?.country === "cr" && (
                                  <CostaRicaIcon size={35} />
                                )}
                                {values?.country === "ec" && (
                                  <EcuadorIcon size={35} />
                                )}
                                {values?.country === "sv" && (
                                  <ElSalvadorIcon size={35} />
                                )}
                                {values?.country === "gt" && (
                                  <GuatemalaIcon size={35} />

                                )}
                                {values?.country === "hn" && (
                                  <HondurasIcon size={35} />
                                )}
                                {values?.country === "mx" && (
                                  <MexicoIcon size={35} />
                                )}
                                {values?.country === "ni" && (
                                  <NicaraguaIcon size={35} />
                                )}
                                {values?.country === "pa" && (
                                  <PanamaIcon size={35} />
                                )}
                                {values?.country === "py" && (
                                  <ParaguayIcon size={35} />
                                )}
                                {values?.country === "pe" && (
                                  <PeruIcon size={35} />
                                )}
                                {values?.country === "pr" && (
                                  <PuertoRicoIcon size={35} />
                                )}
                                {values?.country === "do" && (
                                  <RepublicaDominicanaIcon size={35} />
                                )}
                                {values?.country === "uy" && (
                                  <UruguayIcon size={35} />
                                )}
                                {values?.country === "ve" && (
                                  <VenezuelaIcon size={35} />
                                )}
                                {values?.country === "us" && (
                                  <UnitedStatesIcon size={35} />
                                )}
                              </h2>
                              <h3 className="ms-2 flex-grow-1">
                                {values?.country === "ar" && "Argentina"}
                                {values?.country === "bo" && "Bolivia"}
                                {values?.country === "br" && "Brasil"}
                                {values?.country === "cl" && "Chile"}
                                {values?.country === "co" && "Colombia"}
                                {values?.country === "cr" && "Costa Rica"}
                                {values?.country === "ec" && "Ecuador"}
                                {values?.country === "sv" && "El Salvador"}
                                {values?.country === "gt" && "Guatemala"}
                                {values?.country === "hn" && "Honduras"}
                                {values?.country === "mx" && "Mexico"}
                                {values?.country === "ni" && "Nicaragua"}
                                {values?.country === "pa" && "Panama"}
                                {values?.country === "py" && "Paraguay"}
                                {values?.country === "pe" && "Peru"}
                                {values?.country === "pr" && "Puerto Rico"}
                                {values?.country === "do" &&
                                  "Republica Dominicana"}
                                {values?.country === "uy" && "Uruguay"}
                                {values?.country === "ve" && "Venezuela"}
                                {values?.country === "us" && "United States"}
                              </h3>
                              <h3 className="ranges_list age_range">
                                Age:&nbsp;
                                {allFields && allFields.length > 0 && (
                                  <div>
                                    {allFields[indexVal]?.selectedAges?.map(
                                      (childVal, i) => (
                                        <h3 key={i}>
                                          {childVal}
                                          {i != allFields.length - 1 ? "," : ""}
                                          &nbsp;
                                        </h3>
                                      )
                                    )}
                                  </div>
                                )}
                              </h3>
                              <h3 className="ranges_list age_range">
                                {"| "}Gender:&nbsp;
                                <strong>
                                  {values?.countryreturn?.Gender?.map(
                                    (gender, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {gender?.length > 1 && (
                                            <span> Both</span>
                                          )}
                                          {gender?.length == 1 &&
                                            gender[0] === "F" && (
                                              <span>Girls</span>
                                            )}
                                          {gender?.length == 1 &&
                                            gender[0] === "M" && (
                                              <span>Boys</span>
                                            )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </strong>
                              </h3>
                            </div>
                            {values?.inventory?.map((data, index) => {
                              return (
                                <>
                                  <div className="selcted_audience_container m-0 row mb-3 mt-3">
                                    <div className="col-lg-12 p-0">
                                      <div className="projection_wrapper">
                                        <div className="projection_content">
                                          <p className="abs_badge mb-0">
                                            {data?.inventor === "Apps Kidscorp" ? "Apps" : null}
                                            {data?.inventor === "Roblox" ? "Roblox" : null}
                                            {data?.inventor === "YouTube" ? "YouTube" : null}
                                          </p>
                                          <h2 className="projectionTitle mb-0 ms-2">
                                            Reach Projection
                                          </h2>
                                          <svg
                                            stroke="currentColor"
                                            fill="none"
                                            stroke-width="2"
                                            viewBox="0 0 24 24"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            height="20"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="12" y1="8" x2="12" y2="12"></line>
                                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                            <title>The reach projection is the result for a 30 days campaign according to your budget.</title>
                                          </svg>
                                          {/* <p className="text-start mb-0">*you're going to reach this % of the projected population according to your budget in 30 days</p> */}
                                        </div>


                                        <div className="apps_total_calc">
                                          <ProgressBar
                                            className="bar_color"
                                            now={data?.percentage}
                                          />
                                          <h4 className="m-0">
                                            {data?.percentage?.toFixed(1) + "%"}
                                          </h4>
                                        </div>
                                        <div className="total_selected_calc">
                                          <p>Total u18 selected audience</p>
                                          <p>
                                            <strong>
                                              {data?.boldProjectpopulation?.toLocaleString(undefined, {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                              })}
                                            </strong>
                                            /
                                            {data?.projectedPopulation?.toLocaleString()}
                                          </p>
                                        </div>
                                        <div className="line_divide"></div>

                                        <div className="budget_calc">
                                          <p className="mb-0">
                                            Budget <strong>USD {data?.budget?.toLocaleString()}</strong>
                                          </p>
                                        </div>
                                        <div className="line_divide"></div>

                                        {/* <div className="top_placement">
                                              <p className="mb-0">
                                                Top placement{" "}
                                                <svg
                                                  stroke="currentColor"
                                                  fill="currentColor"
                                                  stroke-width="0"
                                                  viewBox="0 0 24 24"
                                                  height={20}
                                                  width={20}
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                                                  <circle
                                                    cx="16.806"
                                                    cy="7.207"
                                                    r="1.078"
                                                  ></circle>
                                                  <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                                                </svg>
                                              </p>
                                            </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                            {values?.insight && (
                              <div className="selcted_audience_container audience_data m-0 row">
                                <div className="col-lg-8">
                                  <div className="audience_stat">
                                    <div className="activity_wrapper">
                                      <div className="activity_timing">
                                        <div className="activity_card">
                                          <div className="actvity_card_image">
                                            <img
                                              width={68}
                                              height={68}
                                              src="/assets/images/iphone_image.png"
                                              alt=""
                                            />
                                          </div>
                                          <div className="atcivityTiming">
                                            <h3>
                                              {values?.insight
                                                ?.averagePlayInAppsMin
                                                ? values?.insight?.averagePlayInAppsMin?.toFixed(
                                                  0
                                                )
                                                : "NA"}{" "}
                                              {values?.insight
                                                ?.averagePlayInAppsMin
                                                ? "min"
                                                : null}
                                            </h3>
                                            <p>play in apps daily</p>
                                          </div>
                                        </div>
                                        <div className="activity_card">
                                          <div className="actvity_card_image">
                                            <img
                                              width={68}
                                              height={68}
                                              src="/assets/images/youtub_image.png"
                                              alt=""
                                            />
                                          </div>
                                          <div className="atcivityTiming">
                                            <h3>
                                              {values?.insight
                                                ?.averageWatchYtDailyMin
                                                ? values?.insight?.averageWatchYtDailyMin?.toFixed(
                                                  0
                                                )
                                                : "NA"}{" "}
                                              {values?.insight
                                                ?.averageWatchYtDailyMin
                                                ? "min"
                                                : null}
                                            </h3>
                                            <p>play in youtube daily</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="long_divider"></div>
                                      <div className="top_activitesWrappers">
                                        <h5>Top 3 activities</h5>
                                        <div className="top_activites">
                                          <div className="online offline">
                                            <h3>Online</h3>
                                            <ul>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOnline1Text
                                                    ? values?.insight
                                                      ?.MaxActivityOnline1Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOnline1Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOnline1Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOnline1Pct
                                                    ? values?.insight?.MaxActivityOnline1Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOnline2Text
                                                    ? values?.insight
                                                      ?.MaxActivityOnline2Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOnline2Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOnline2Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOnline2Pct
                                                    ? values?.insight?.MaxActivityOnline2Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOnline3Text
                                                    ? values?.insight
                                                      ?.MaxActivityOnline3Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOnline3Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOnline3Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOnline3Pct
                                                    ? values?.insight?.MaxActivityOnline3Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="offline">
                                            <h3>Offline</h3>
                                            <ul>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOffline1Text
                                                    ? values?.insight
                                                      ?.MaxActivityOffline1Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOffline1Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOffline1Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOffline1Pct
                                                    ? values?.insight?.MaxActivityOffline1Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOffline2Text
                                                    ? values?.insight
                                                      ?.MaxActivityOffline2Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOffline2Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOffline2Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOffline2Pct
                                                    ? values?.insight?.MaxActivityOffline2Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOffline3Text
                                                    ? values?.insight
                                                      ?.MaxActivityOffline3Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOffline3Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOffline3Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOffline3Pct
                                                    ? values?.insight?.MaxActivityOffline3Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="passion_point">
                                      <ul>
                                        <li>
                                          <h2>Top Passion Points</h2>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint1[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint1
                                            )
                                              ? values.insight.PassionPoint1[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint2[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TRAVEl" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint2
                                            )
                                              ? values.insight.PassionPoint2[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint3[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint3
                                            )
                                              ? values.insight.PassionPoint3[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint4[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "Music" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint4
                                            )
                                              ? values.insight.PassionPoint4[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint5[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint5
                                            )
                                              ? values.insight.PassionPoint5[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                {/* if there is any data inside the graph only when below code execute */}
                                {Object.keys(values?.insight).length !== 0 && (
                                  <div className="col-lg-4">
                                    <div className="com_used">
                                      <h3>Commonly used devices</h3>
                                      <HighchartsReact
                                        containerProps={{
                                          style: { height: "200px" },
                                        }}
                                        highcharts={Highcharts}
                                        options={{
                                          chart: {
                                            // zoomType: 'xy'
                                            type: "column",
                                          },
                                          // bar:{
                                          // 	borderRadius:15

                                          // },
                                          // title: {
                                          // 	text: 'Karasjok weather, 2021',
                                          // 	align: 'left'
                                          // },
                                          // subtitle: {
                                          // 	text: 'Source: <a href="https://www.yr.no/nb/historikk/graf/5-97251/Norge/Troms%20og%20Finnmark/Karasjok/Karasjok?q=2021" target="_blank">YR</a>',
                                          // 	align: 'left'
                                          // },
                                          xAxis: [
                                            {
                                              categories: [
                                                "Smart Tv",
                                                "Smartphone",
                                                "Tablet,ipad",
                                                "Desktop",
                                                "Console",
                                              ],
                                              crosshair: true,
                                            },
                                          ],
                                          yAxis: [
                                            {
                                              // Primary yAxis
                                              // labels: {
                                              // 	format: '{value}°C',
                                              // 	style: {
                                              // 		color: "green"
                                              // 	}
                                              // },
                                              // title: {
                                              // 	text: 'Temperature',
                                              // 	style: {
                                              // 		color: Highcharts.getOptions().colors[1]
                                              // 	}
                                              // }
                                            },
                                            {
                                              // Secondary yAxis
                                              // title: {
                                              // 	text: 'Precipitation',
                                              // 	style: {
                                              // 		color: Highcharts.getOptions().colors[0]
                                              // 	}
                                              // },
                                              // labels: {
                                              // 	format: '{value} mm',
                                              // 	style: {
                                              // 		color: Highcharts.getOptions().colors[0]
                                              // 	}
                                              // },
                                              opposite: true,
                                            },
                                          ],
                                          tooltip: {
                                            shared: true,
                                          },
                                          legend: {
                                            align: "left",
                                            x: 80,
                                            verticalAlign: "top",
                                            y: 60,
                                            floating: true,
                                            // backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
                                          },
                                          // plotOptions: {
                                          // 	column: {
                                          // 	  borderRadiusTopLeft: 5,
                                          // 	  borderRadiusTopRight: 5,
                                          // 	},
                                          //   },
                                          // here we are managing the data to show in graph table
                                          series: [
                                            {
                                              name: "",
                                              type: "column",
                                              showInLegend: false,
                                              yAxis: 1,
                                              data: [
                                                values?.insight
                                                  ?.averageDeviceSmarttv,
                                                values?.insight
                                                  ?.averageDeviceSmartphone,
                                                values?.insight
                                                  ?.averageDeviceTablet,
                                                values?.insight
                                                  ?.averageDeviceDesktop,
                                                values?.insight
                                                  ?.averageDeviceConsole,
                                              ],
                                              color: "#00eacb",
                                              // borderRadius: 10, // Custom property
                                            },
                                          ],
                                          plotOptions: {
                                            column: {
                                              // pointPadding: 0.2,
                                              // borderWidth: 0
                                            },
                                            series: {
                                              borderRadiusTopLeft: 5,
                                              borderRadiusTopRight: 5,
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* <div className="audience_inner_contetnt col-lg-8">
																		<div>
																			{
																				data?.inventory === "Roblox" && <img className="img_style"
																					src="assets/images/robloxPlanner.png"
																					alt="kidscorp" width="200px" height="50px"
																				/>
																			}
																			{
																				data?.inventory === "Apps Kidscorp" && <img className="img_style"
																					src="assets/images/kidscorpPlanner.png"
																					alt="kidscorp" width="200px" height="50px"
																				/>
																			}
																			{
																				data?.inventory === "YouTube" && <img className="img_style"
																					src="assets/images/youtubePlanner.png"
																					alt="kidscorp" width="200px" height="50px"
																				/>
																			}
																		</div>
																		<div className="total_selected_heading">
																			<h5>Total u18 selected Audience</h5>
																			<h2 className="cmn_style">{!data?.projectedPopulation
																				? "0" : Math.floor(data?.projectedPopulation)
																					?.toLocaleString()}</h2>
																		</div>
																	</div>
																	<div className="reach_projection_outer col-lg-6">
																		<div className="reach_inner_content">
																			<h2 className="cmn_style">Reach Projection</h2>
																		
																		</div>
																		<div className="halfDonut col-lg-6">
																			<HighchartsReact
																				highcharts={Highcharts}
																				options={{
																					chart: {
																						plotBackgroundColor: null,
																						plotBorderWidth: 0,
																						plotShadow: false,
																						height: 150 // Adjust the height as per your requirement
																					},
																					title: {
																						text: (data?.percentage * 100)?.toFixed(1) + '%',
																						verticalAlign: 'middle', // Align the title to the top
																						y: 50,// Adjust the vertical position of the title
																						style: {
																							color: '#603b87',
																							fontWeight: "900"
																						}
																					},
																					plotOptions: {
																						pie: {
																							dataLabels: {
																								enabled: false,
																								distance: -50,
																								style: {
																									fontWeight: 'bold',
																									color: 'white'
																								}
																							},
																							startAngle: -90,
																							endAngle: 90,
																							center: ['50%', '85%'],
																							size: '200%',
																							colors: ['#603b87', '#f0f6fd']
																						}
																					},
																					series: [{
																						type: 'pie',
																						name: '',
																						innerSize: '85%',
																						data: [
																							[data, data?.percentage * 100],
																							{
																								name: '',
																								y: (100 - data?.percentage),
																								dataLabels: {
																									enabled: false
																								}
																							}
																						]
																					}],
																					credits: {
																						enabled: false
																					},
																					legend: {
																						enabled: false
																					},
																					tooltip: {
																						enabled: false // Add this line to hide the tooltip
																					},
																				}}
																			/>
	
	
	
																		</div>
																	</div> */}
                              </div>
                            )}

                            {values?.inventioryCount > 1 && (
                              <div className="reachFooter">
                                <ul>
                                  <li>
                                    <h4>Country Summary</h4>
                                  </li>
                                  <li>
                                    <p className="d-flex">
                                      Age:{" "}
                                      <strong>
                                        {allFields && allFields.length > 0 && (
                                          <div className="d-flex">
                                            {allFields[indexVal]?.selectedAges?.map(
                                              (childVal, i) => (
                                                <strong key={i}>
                                                  {childVal}
                                                  {i != allFields.length - 1 ? "" : ","}
                                                  &nbsp;
                                                </strong>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </strong>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Gender:{" "}
                                      <strong>
                                        {values?.countryreturn?.Gender?.map(
                                          (gender, index) => {
                                            return (
                                              <React.Fragment key={index}>
                                                {gender?.length > 1 && (
                                                  <span> Both</span>
                                                )}
                                                {gender?.length == 1 &&
                                                  gender[0] === "F" && (
                                                    <span>Girls</span>
                                                  )}
                                                {gender?.length == 1 &&
                                                  gender[0] === "M" && (
                                                    <span>Boys</span>
                                                  )}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </strong>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Total Budget:{" USD "}
                                      <strong>
                                        {values?.countryreturn?.Budget?.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                                      </strong>
                                    </p>
                                  </li>
                                  {values?.countryreturn?.AppReachsum != 0 && <li>
                                    <p>
                                      Apps reach: <strong>{values?.countryreturn?.AppReachsum ? values?.countryreturn?.AppReachsum?.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                      }) : "-"}</strong>
                                    </p>
                                  </li>}
                                  {values?.countryreturn?.RobloxReachsum != 0 && <li>
                                    <p>
                                      Roblox reach: <strong>{values?.countryreturn?.RobloxReachsum ? values?.countryreturn?.RobloxReachsum?.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                      }) : "-"}</strong>
                                    </p>
                                  </li>}
                                  {values?.countryreturn?.YoutubeReachsum != 0 && <li>
                                    <p>
                                      Youtube reach: <strong>{values?.countryreturn?.YoutubeReachsum ? values?.countryreturn?.YoutubeReachsum?.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                      }) : "-"}</strong>
                                    </p>
                                  </li>}
                                </ul>
                              </div>
                            )}
                          </div>

                        );
                      }
                    })}

                  </div>
                  {resultData?.length !== 0 && <div className="mt-3">
                    <button onClick={exportData} className="pdf_export">
                      {!uploading ? "PDF" : "Exporing"}
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M19 9h-4V3H9v6H5l7 8zM4 19h16v2H4z"></path></svg>
                    </button>
                  </div>}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abstract;
