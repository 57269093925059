import React, { useState, useRef, useEffect } from "react";
import "./campaign.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "react-bootstrap";
import { toast } from "react-hot-toast";

import { FaChevronRight, FaSearch, FaPlus, FaChevronDown } from "react-icons/fa";
import { HiBars3 } from "react-icons/hi2";
import {
	AiOutlineClose,
	AiOutlineSearch

} from "react-icons/ai";
import { ImFileText2 } from "react-icons/im";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import {
	campaignManagerSelector,
	campaignfilter,
	pipelinefilter,
	statusfilter,
	advertizerfilter,
	getowners,
	applyfilter,
	clearState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import {
	BsChevronDown,
	BsChevronLeft,
	BsChevronRight,
	BsChevronBarLeft,
	BsChevronBarRight,
} from "react-icons/bs";
import {
	CommonSelector,
	updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";
import Moment from "react-moment";
import Spinner from 'react-bootstrap/Spinner';

const CampaignPage = () => {
	const navigation = useNavigate();
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [searchfilter, SetSearchfilter] = useState([]);
	const [page, setPage] = useState(1);
	const [loadershown, setloadershown] = useState(false);
	const [filteredDealData, setFilteredDealData] = useState([])
	const [valueAcsending, setValueAcsending] = useState(false)
	const [arrowId, setArrowId] = useState(false)
	const [arrowMedia, setArrowMedia] = useState(false)
	const [arrowMarket, setArrowMarket] = useState(false)
	const [up, setUp] = useState(false)
	const [down, setDown] = useState(false)
	const [defaultId, setDefaultId] = useState(true)
	const [defaultMedia, setDefaultMedia] = useState(true)
	const [defaultMarket, setDefaultMarket] = useState(true)
	const [selectSort, setSelectSort] = useState("")
	const [asc, setAsc] = useState(false)
	const [pageNo, setPageNo] = useState(1)
	const [pageLimit, setPageLimit] = useState(10)
	const [page_Count, setPage_count] = useState(0)
	const [current_Page, setCurrent_Page] = useState(1)
	const [advertiserSearch, setAdvertiserSearch] = useState("")
	const [searchDealId, setSearchDealId] = useState("")
	const [statusId, setStatusId] = useState("")
	const [pipelineOption, setPipelineOption] = useState([])
	const [pipelineData, setPipelineData] = useState()
	const [ownerData, setOwnerData] = useState([])
	const [ownerData1, setOwnerData1] = useState([])
	const [ownerData2, setOwnerData2] = useState([])
	const [searchSalesOwner, setSearchSalesOwner] = useState()
	const [searchCsOwner, setSearchCsOwner] = useState()
	const [searchAdopsOwner, setSearchAdopsOwner] = useState()
	const [searchvalue, setSearchValue] = useState("")
	const [searchSet, setSearch] = useState(false)
	const [tempStatus, setTempStatus] = useState("Status")
	const [tempMarket, setTempMarket] = useState("Market")
	const [advertiser_list, setAdvertiser_list] = useState([])
	const [spinner, setSpinner] = useState(false)
	const [show_div, setShow_div] = useState(false)
	const [selectedfilter, SetSelectedfilter] = useState({
		advertiser: {
			name: "Advertiser",
			value: "",
			key: "advertiser",
		},
		deal_id: {
			name: "Id Media Plan",
			value: "",
			key: "deal_id",
		},
		pipeline: {
			name: "Pipeline",
			value: [],
			key: "pipeline",
		},
		ownerType: {
			name: "Owner-type",
			value: "",
			key: "ownerType",
		},
		ownerValue: {
			name: "Owner-value",
			value: "",
			key: "ownerValue",
		},
		amOwner: {
			name: "Account Manager",
			value: [],
			key: "amOwner",
		},
		adops_owner: {
			name: "Adops Owner",
			value: [],
			key: "adops_owner",
		},
		sales_owner: {
			name: "Sales Owner",
			value: [],
			key: "sales_owner",
		},
		status: {
			name: "Status",
			value: "",
			key: "status",
		},
	});

	const {
		campaign_filters,
		pipeline_filter,
		status_filter,
		advertizer_filters,
		owner_groups,
		// filtered_result,
		isFetching,
		isSuccess,
		isError,
		error,
	} = useSelector(campaignManagerSelector);

	const [filtervalue, setFilterValue] = useState({
		advertiser: "",
		deal_id: "",
		pipeline: [],
		ownerType: "",
		ownerValue: "",
		amOwner: [],
		adops_owner: [],
		sales_owner: [],
		status: "",
	});

	const [filter, setFilter] = useState({
		advertiser: {
			name: "Advertiser",
			type: "text",
			values: "",
			shown: true,
			multiselect: false,
		},
		deal_id: {
			name: "ID",
			type: "number",
			values: "",
			shown: true,
			multiselect: false,
		},
		pipeline: {
			name: "Market",
			type: "dropdown",
			values: [],
			shown: true,
			multiselect: false,
		},
		ownerType: {
			name: "Owner",
			type: "multidropdown",
			values: {
				sales_owner: {
					name: "Sales",
					values: [],
					data: 1,
				},
				amOwner: {
					name: "Account Manager",
					values: [],
					data: 2,
				},
				adops_owner: {
					name: "Adops",
					values: [],
					data: 3,
				},
			},
			shown: true,
			multiselect: false,
		},
		status: {
			name: "Status",
			type: "dropdown",
			values: [
				{
					name: "New",
					value: 1,
				},
				{
					name: "Active",
					value: 2,
				},
				{
					name: "InActive",
					value: 3,
				},
			],
			shown: true,
			multiselect: false,
		},
	});

	const searchFilter = () => {
		let posted_array = Object.assign({}, filtervalue);

		Object.keys(posted_array).forEach((key) => {
			if (
				posted_array[key] === "" ||
				(typeof posted_array[key] === "object" && posted_array[key].length == 0)
			) {
				delete posted_array[key];
			}
		});
		setloadershown(true);
		dispatch(
			applyfilter({
				token: localStorage.getItem("token"),
				filter: posted_array,
				page: page,
				pageSize: 10,
			})
		).then((response) => {
			setloadershown(false);
		});
	};

	useEffect(() => {
		getDealData()
	}, [pageLimit, pageNo])

	const getDealData = () => {
		setloadershown(true)
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		var raw = JSON.stringify({
			"campaign": null,
			"pipeline": pipelineData ? pipelineData : null,
			"status": statusId ? statusId : null,
			"advertiser": advertiserSearch ? advertiserSearch : null,
			"cs_owner": searchCsOwner ? searchCsOwner : null,
			"adops_owner": searchAdopsOwner ? searchAdopsOwner : null,
			"sales_owner": searchSalesOwner ? searchSalesOwner : null,
			"deal_id": searchDealId ? searchDealId : null,
			"Field_Name": "id",
			"Order": "ascending"
		});

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/deals/${pageNo}/${pageLimit}`, requestOptions)
			.then(response => response.json())
			.then(result => { setFilteredDealData(result?.data?.results); setloadershown(false); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page) })
			.catch(error => { toast.error("internal error occured"); setloadershown(false) });
	}

	useEffect(() => {
		getFilterData()
	}, [advertiserSearch, searchDealId, statusId, pipelineData, searchSalesOwner, searchAdopsOwner, searchCsOwner])

	const getFilterData = () => {
		setloadershown(true)
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		var raw = JSON.stringify({
			"campaign": null,
			"pipeline": pipelineData ? pipelineData : null,
			"status": statusId ? statusId : null,
			"advertiser": advertiserSearch ? advertiserSearch : null,
			"cs_owner": searchCsOwner ? searchCsOwner : null,
			"adops_owner": searchAdopsOwner ? searchAdopsOwner : null,
			"sales_owner": searchSalesOwner ? searchSalesOwner : null,
			"deal_id": searchDealId ? searchDealId : null,
			"Field_Name": "id",
			"Order": "ascending"
		});

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/deals/${pageNo}/${pageLimit}`, requestOptions)
			.then(response => response.json())
			.then(result => { setFilteredDealData(result?.data?.results); setloadershown(false); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page) })
			.catch(error => { toast.error("internal error occured"); setloadershown(false) });
	}


	const handleSorting = (field) => {
		setloadershown(true)
		// var myHeaders = new Headers();
		// myHeaders.append("Content-Type", "application/json");
		// myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

		// var raw = JSON.stringify({
		// 	"campaign": null,
		// 	"pipeline": pipelineData ? pipelineData : null,
		// 	"status": statusId ? statusId : null,
		// 	"advertiser": advertiserSearch ? advertiserSearch : null,
		// 	"cs_owner": searchCsOwner ? searchCsOwner : null,
		// 	"adops_owner": searchAdopsOwner ? searchAdopsOwner : null,
		// 	"sales_owner": searchSalesOwner ? searchSalesOwner : null,
		// 	"deal_id": searchDealId ? searchDealId : null,
		// 	"FieldName": field,
		// 	"Order": asc ? "asc" : "desc"
		// });

		// var requestOptions = {
		// 	method: 'POST',
		// 	headers: myHeaders,
		// 	body: raw,
		// 	redirect: 'follow'
		// };
		// fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/deals?page=1&pageSize=10`, requestOptions)
		// 	.then(response => response.json())
		// 	.then(result => { setFilteredDealData(result?.data?.results); setloadershown(false) })
		// 	.catch(error => { toast.error("internal error occured"); setloadershown(false) });
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		var raw = JSON.stringify({
			"campaign": null,
			"pipeline": pipelineData ? pipelineData : null,
			"status": statusId ? statusId : null,
			"advertiser": advertiserSearch ? advertiserSearch : null,
			"cs_owner": searchCsOwner ? searchCsOwner : null,
			"adops_owner": searchAdopsOwner ? searchAdopsOwner : null,
			"sales_owner": searchSalesOwner ? searchSalesOwner : null,
			"deal_id": searchDealId ? searchDealId : null,
			"FieldName": field,
			"Order": asc ? "asc" : "desc"
		});

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/deals/${pageNo}/${pageLimit}`, requestOptions)
			.then(response => response.json())
			.then(result => { setFilteredDealData(result?.data?.results); setloadershown(false) })
			.catch(error => { toast.error("internal Error Occured"); setloadershown(false) });
	}

	useEffect(() => {
		dispatch(pipelinefilter({ token: localStorage.getItem("token") }))
			.then((result) => {
				setPipelineOption(result?.payload?.data)
			})
			.catch((err) => { });

		dispatch(getowners({ token: localStorage.getItem("token"), ownergroup: 1 }))
			.then((result) => {
				setOwnerData(result?.payload?.data)
			})
			.catch((err) => { });

		dispatch(getowners({ token: localStorage.getItem("token"), ownergroup: 2 }))
			.then((result) => {
				const csOwner = { ...filter };
				setOwnerData1(result?.payload?.data)
			})
			.catch((err) => { });

		dispatch(getowners({ token: localStorage.getItem("token"), ownergroup: 3 }))
			.then((result) => {
				setOwnerData2(result?.payload?.data)
			})
			.catch((err) => { });
	}, [])

	// useEffect(() => {
	// 	setFilteredDealData(filtered_result?.results)
	// }, [filtered_result?.results])

	// useEffect(() => {
	// 	dispatch(pipelinefilter({ token: localStorage.getItem("token") }))
	// 		.then((result) => {
	// 			const pipelineoptions = { ...filter };
	// 			pipelineoptions.pipeline.values = result?.payload?.data;
	// 			setFilter(pipelineoptions);
	// 		})
	// 		.catch((err) => { });
	// 	dispatch(getowners({ token: localStorage.getItem("token"), ownergroup: 1 }))
	// 		.then((result) => {
	// 			const salesOwner = { ...filter };
	// 			salesOwner.ownerType.values.sales_owner.values = result?.payload?.data;
	// 			setFilter(salesOwner);
	// 		})
	// 		.catch((err) => { });
	// 	dispatch(getowners({ token: localStorage.getItem("token"), ownergroup: 2 }))
	// 		.then((result) => {
	// 			const csOwner = { ...filter };
	// 			csOwner.ownerType.values.amOwner.values = result?.payload?.data;
	// 			setFilter(csOwner);
	// 		})
	// 		.catch((err) => { });
	// 	dispatch(getowners({ token: localStorage.getItem("token"), ownergroup: 3 }))
	// 		.then((result) => {
	// 			const adopsOwner = { ...filter };
	// 			adopsOwner.ownerType.values.adops_owner.values = result?.payload?.data;
	// 			setFilter(adopsOwner);
	// 		})
	// 		.catch((err) => { });
	// 	dispatch(updateBreadCrumb([]));
	// }, []);

	// useEffect(() => {
	// 	searchFilter();
	// }, [page]);

	const filter_submit = (e) => {
		if (page == 1) {
			searchFilter();
		} else {
			setPage(1);
		}
	};

	const gotonext = (id, dealId, currencyCode, status) => {
		navigation("/campaign/mediaplan", {
			state: { deal_id: id, dealid: dealId, currencyCode, status: status },
		});
	};

	const filter_search = (e) => {
		let filtered_array = [];
		if (e.target.value.length > 2) {
			Object.keys(filter).filter((element, index) => {
				if (element == "advertiser") {
				}

				if (element == "deal_id") {
				}

				if (element == "pipeline") {
					filter[element].values.filter((pipline, index) => {
						if (pipline.label) {
							if (
								pipline.label
									.toLowerCase()
									.includes(e.target.value.toLowerCase())
							) {
								let filtername = filter[element].name;
								filtered_array.push({
									[filtername]: {
										id: pipline.pipelineId,
										value: pipline.label,
										name: "pipeline",
									},
								});
							}
						}
					});
				}

				if (element == "ownerType") {
					Object.keys(filter[element].values).filter((otvalue, otindex) => {
						filter[element].values[otvalue].values.filter((owval, owindex) => {
							if (owval.email) {
								if (
									owval.first_name
										.toLowerCase()
										.includes(e.target.value.toLowerCase()) ||
									owval.last_name
										.toLowerCase()
										.includes(e.target.value.toLowerCase())
								) {
									let owner_type = "";
									switch (filter[element].values[otvalue].name) {
										case "Sales":
											owner_type = "sales_owner";
											break;
										case "Account Manager":
											owner_type = "amOwner";
											break;
										case "Adops":
											owner_type = "adops_owner";
											break;
									}

									let filtername =
										filter[element].name +
										"-" +
										filter[element].values[otvalue].name;
									filtered_array.push({
										[filtername]: {
											id: owval.id,
											value: owval.email,
											name: owner_type,
										},
									});
								}
							}
						});
					});
				}

				if (element == "status") {
					filter[element].values.filter((statusval, statusindex) => {
						if (statusval.name) {
							if (
								statusval.name
									.toLowerCase()
									.includes(e.target.value.toLowerCase())
							) {
								let filtername = filter[element].name;
								filtered_array.push({
									[filtername]: {
										id: statusval.value,
										value: statusval.name,
										name: "status",
									},
								});
							}
						}
					});
				}
			});
		} else {
			searchFilter();
		}
		SetSearchfilter(filtered_array);
	};

	const managefilter = (values) => {
		switch (values.name) {
			case "deal_id":
				setFilterValue((prev_val) => ({ ...prev_val, deal_id: values.value }));
				SetSelectedfilter((prev_val) => ({
					...prev_val,
					deal_id: {
						name: "Id Media Plan",
						value: values.value,
						key: "deal_id",
					},
				}));
				break;
			case "pipeline":
				if (
					filtervalue.pipeline.findIndex((element) => element == values.id) ==
					-1
				) {
					setFilterValue((prev_val) => ({
						...prev_val,
						pipeline: [...filtervalue.pipeline, values.id],
					}));
					SetSelectedfilter((prev_val) => ({
						...prev_val,
						pipeline: {
							name: "Market",
							value: [...selectedfilter.pipeline.value, values],
							key: "pipeline",
						},
					}));
				}
				break;
			case "adops_owner":
				setFilterValue((prev_val) => ({
					...prev_val,
					adops_owner: [...filtervalue.adops_owner, values.value],
				}));
				SetSelectedfilter((prev_val) => ({
					...prev_val,
					adops_owner: {
						name: "Adops Owner",
						value: [...selectedfilter.adops_owner.value, values],
						key: "adops_owner",
					},
				}));
				break;
			case "sales_owner":
				setFilterValue((prev_val) => ({
					...prev_val,
					sales_owner: [...filtervalue.sales_owner, values.value],
				}));
				SetSelectedfilter((prev_val) => ({
					...prev_val,
					sales_owner: {
						name: "Sales Owner",
						value: [...selectedfilter.sales_owner.value, values],
						key: "sales_owner",
					},
				}));
				break;
			case "amOwner":
				setFilterValue((prev_val) => ({
					...prev_val,
					amOwner: [...filtervalue.amOwner, values.value],
				}));
				SetSelectedfilter((prev_val) => ({
					...prev_val,
					amOwner: {
						name: "Account Manager",
						value: [...selectedfilter.amOwner.value, values],
						key: "amOwner",
					},
				}));
				break;
			case "status":
				setFilterValue((prev_val) => ({ ...prev_val, status: values.id }));
				SetSelectedfilter((prev_val) => ({
					...prev_val,
					status: {
						name: "Status",
						value: values.value,
						key: "status",
					},
				}));
				break;
		}
	};

	const removefilter = (key, index = null) => {
		if (index == null) {
			setFilterValue((prev_val) => ({ ...prev_val, [key]: "" }));
			SetSelectedfilter((prev_val) => ({
				...prev_val,
				[key]: {
					name: "",
					value: "",
					key: key,
				},
			}));
		}
		if (index != null) {
			selectedfilter[key].value.splice(index, 1);
			filtervalue[key].splice(index, 1);
			setFilterValue((prev_val) => ({ ...prev_val, [key]: filtervalue[key] }));
			SetSelectedfilter((prev_val) => ({
				...prev_val,
				[key]: { ...selectedfilter[key], value: selectedfilter[key].value },
			}));
		}
	};

	const setsdvertiser = (e, key) => {
		setFilterValue((prev_val) => ({ ...prev_val, [key]: e.target.value }));
	};

	const applyadvertiser = (e, key) => {
		if (filtervalue[key] != "") {
			SetSelectedfilter((prev_val) => ({
				...prev_val,
				[key]: {
					name:
						key == "deal_id"
							? "Id Media Plan"
							: key[0].toUpperCase() + key.slice(1),
					value: filtervalue[key],
					key: key,
				},
			}));
		}
	};
	const handlePageClick = (event) => {
		setArrowId(false)
		setArrowMarket(false)
		setArrowMedia(false)
		setDefaultId(true)
		setDefaultMarket(true)
		setDefaultMedia(true)

		let newpage = event.selected + 1;
		setPage(newpage);
	};

	const handleSort = (field) => {
		setAsc(!asc)
		setValueAcsending(!valueAcsending)
		setArrowId(true)
		setArrowMarket(false)
		setArrowMedia(false)
		setDefaultId(false)
		setDefaultMarket(true)
		setDefaultMedia(true)

	}

	const handleSortByMedia = (field) => {
		setAsc(!asc)
		setValueAcsending(!valueAcsending)
		setArrowId(false)
		setArrowMarket(false)
		setArrowMedia(true)
		setDefaultId(true)
		setDefaultMarket(true)
		setDefaultMedia(false)
	}

	const handleSortByMarket = (field) => {
		setAsc(!asc)
		setValueAcsending(!valueAcsending)
		setArrowId(false)
		setArrowMarket(true)
		setArrowMedia(false)
		setDefaultId(true)
		setDefaultMarket(false)
		setDefaultMedia(true)
	}

	const handleAdvertiser = (e) => {

		if (/[a-zA-Z]/.test(e.target.value)) {
			// setAdvertiserSearch(e.target.value)
			// setSearch(true)
			// setPipelineData()
			// setSearchSalesOwner()
			// setSearchAdopsOwner()
			// setSearchCsOwner()
			// setSearchDealId("")

		} else {
			setSearchDealId(e.target.value)
			setSearch(true)
			setPipelineData()
			setAdvertiserSearch()
			setSearchSalesOwner()
			setSearchAdopsOwner()
			setSearchCsOwner()
		}
	}

	const handlePipe = (e) => {
		let data = []
		data.push(e.target.value)
		setSearch(true)
		setPipelineData(data)
		setSearchDealId("")
		setAdvertiserSearch()
		setSearchSalesOwner()
		setSearchAdopsOwner()
		setSearchCsOwner()
	}

	const handleSalesOwner = (email) => {
		let data = []
		if (email !== "") {
			data.push(email)
			setSearchSalesOwner(data)
			setSearchAdopsOwner()
			setSearchCsOwner()
			setPipelineData()
			setSearchDealId("")
			setAdvertiserSearch()
			setSearch(true)

		}
	}

	const handleAdopsOwner = (email) => {
		let data = []
		if (email !== "") {
			data.push(email)
			setSearchAdopsOwner(data)
			setSearch(true)
			setSearchCsOwner()
			setPipelineData()
			setSearchDealId("")
			setAdvertiserSearch()
			setSearchSalesOwner()
		}
	}

	const handleCsOwner = (email) => {
		let data = []
		if (email !== "") {
			data.push(email)
			setSearchCsOwner(data)
			setSearch(true)
			setPipelineData()
			setSearchDealId("")
			setAdvertiserSearch()
			setSearchSalesOwner()
			setSearchAdopsOwner()
		}
	}

	const handleSortDate = () => {
		setAsc(!asc)
	}


	const handle_search_input = (e) => {
		if (!/[a-zA-Z]/.test(e.target.value)) {
			setShow_div(false);
			setAdvertiser_list([]);
		}
		if (/[a-zA-Z]/.test(e.target.value)) {
			setSpinner(true)
			setShow_div(true)
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

			var raw = JSON.stringify({
				"advertiser": e.target.value
			});

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			};

			fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManager/AdvertiserFilter`, requestOptions)
				.then(response => response.json())
				.then(result => { console.log(result); setAdvertiser_list(result?.data); setSpinner(false) })
				.catch(error => console.log('error', error));
		}
	}

	return (
		<div>
			<div className="content_outer">
				<Loader showLoader={loadershown} />
				<div className="cmn_header_top campaign__header">
					<h3>Campaign Manager</h3>
					{<div className="flex-grow-1 text-center">
						<div className="cmn_header__search">
							<AiOutlineSearch size={24} />	<input type="text" value={searchvalue} placeholder="Search by Id or Advertiser" onBlur={(e) => handleAdvertiser(e)} onChange={(e) => { handle_search_input(e); setSearchValue(e.target.value); setSearch(true) }} onClick={() => { setShow_div(false); setAdvertiser_list([]) }} />
							{show_div && <div className="search_dropown">
								{spinner &&
									<div className="p-3">
										<Spinner animation="border" role="status">
											<span className="visually-hidden">Loading...</span>
										</Spinner>

									</div>
								}
								<ul>
									{
										advertiser_list?.map((data, index) => {
											return (
												<li key={index} onClick={() => { setAdvertiserSearch(data?.advertiser); setShow_div(false) }}>{data?.advertiser}</li>
											)
										})
									}
									{/* <li>deepak</li>
									<li>hankuuu</li> */}
								</ul>
							</div>}
						</div>
					</div>}

				</div>
				<div className="content pt-0">
					<div className="App">
						{/* <div className="header_nav">
							<button onClick={() => navigation(-1)}>
								<AiOutlineLeft />{" "}
							</button>
							<div className="bredcrum">
								<p>Media Plans</p>
							</div>
						</div> */}
						<div className="cmn__filters campiagn_filter filter_menu">
							<ul>
								<li><img src="/assets/images/filter_list.svg" /></li>
								<li>
									{/* <select name="" className="form-select" id="selectBox" value={pipelineData} onChange={(e) => handlePipe(e)}>
										<option>Market</option>
										{pipelineOption?.map((option, i) => {
											if (
												option?.pipelineId === "c154cd17-a947-4f80-a702-a369c6c8d2c4" ||
												option?.pipelineId === "9028d3f5-7669-414d-9954-9b3de9656591" ||
												option?.pipelineId === "20249197" ||
												option?.pipelineId === "03ca6bf5-789f-4ced-ae3c-556ccf4cab80" ||
												option?.pipelineId === "4837244"
											) {
												return (
													<option id="optionsValue" value={option?.pipelineId}>{option?.label}</option>
												);
											}
										})}
									</select> */}

									<Dropdown className="owner_filter">
										{/* first */}
										<Dropdown.Toggle id="dropdown-basic">
											{tempMarket} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{pipelineOption?.map((option, index) => {
												if (
													option?.pipelineId === "c154cd17-a947-4f80-a702-a369c6c8d2c4" ||
													option?.pipelineId === "9028d3f5-7669-414d-9954-9b3de9656591" ||
													option?.pipelineId === "20249197" ||
													option?.pipelineId === "03ca6bf5-789f-4ced-ae3c-556ccf4cab80" ||
													option?.pipelineId === "4837244"
												) {
													return (
														// <option id="optionsValue" value={option?.pipelineId}>{option?.label}</option>
														<Dropdown.Item key={index} onClick={() => { setPipelineData(option?.pipelineId); setSearch(true); setTempMarket(option?.label) }}>{option?.label}</Dropdown.Item>

													);
												}
											})}


										</Dropdown.Menu>
									</Dropdown>

								</li>
								<li>
									<Dropdown className="owner_filter">
										<Dropdown.Toggle id="dropdown-basic">
											Owner<svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
										</Dropdown.Toggle>

										<Dropdown.Menu className="owner_options">
											<Dropdown>
												{/* first */}
												<Dropdown.Toggle id="dropdown-basic">
													Account <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{ownerData1?.map((csOwner, index) => {
														return (
															<>
																<Dropdown.Item key={index} onClick={() => { handleCsOwner(csOwner?.email) }}>{csOwner?.first_name}{" "}{csOwner?.last_name}</Dropdown.Item>
															</>
														)
													})}


												</Dropdown.Menu>
											</Dropdown>
											{/* first */}
											{/* second */}
											<Dropdown className="">
												<Dropdown.Toggle id="dropdown-basic">
													Adops<svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{ownerData2?.map((adopsOwner, index) => {
														return (
															<>
																<Dropdown.Item key={index} onClick={() => { handleAdopsOwner(adopsOwner?.email) }}>{adopsOwner?.first_name}{" "}{adopsOwner?.last_name}</Dropdown.Item>
															</>
														)
													})}

												</Dropdown.Menu>
											</Dropdown>
											{/* second */}

											{/* third */}
											<Dropdown>
												<Dropdown.Toggle id="dropdown-basic">
													Sales <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{ownerData?.map((salesowner, index) => {
														return (
															<>
																<Dropdown.Item key={index} onClick={() => { handleSalesOwner(salesowner?.email) }}>{salesowner?.first_name}{" "}{salesowner?.last_name}</Dropdown.Item>
															</>
														)
													})}


												</Dropdown.Menu>
											</Dropdown>
											{/* third */}
										</Dropdown.Menu>
									</Dropdown>
								</li>
								<li>
									{/* <select
										name=""
										id=""
										className="form-select"
										onChange={(e) => { setStatusId(e.target.value); setSearch(true) }}

									>
										<option >Status</option>
										<option value="1">New</option>
										<option value="2">Active</option>
										<option value="3">InActive</option>
									</select> */}
									<Dropdown className="owner_filter">
										<Dropdown.Toggle id="dropdown-basic">
											{tempStatus} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item onClick={(e) => { setStatusId("3"); setTempStatus("NEW"); setSearch(true) }}>New</Dropdown.Item>
											<Dropdown.Item onClick={(e) => { setStatusId("1"); setTempStatus("ACTIVE"); setSearch(true) }}>Active </Dropdown.Item>
											<Dropdown.Item onClick={(e) => { setStatusId("2"); setTempStatus("INACTIVE"); setSearch(true) }}>Inactive</Dropdown.Item>

										</Dropdown.Menu>
									</Dropdown>
								</li>
								<button
									className={!searchSet ? "clear__btn" : "clear__btn1"}
									onClick={() => {
										setStatusId(0);
										setSearchSalesOwner();
										setSearchAdopsOwner();
										setSearchCsOwner();
										setPipelineData(null);
										setAdvertiserSearch();
										setSearchDealId("");
										setSearchValue("");
										setSearch(false)
										setTempStatus("Status")
										setTempMarket("Market")
									}}
									disabled={!searchSet}
								>
									Clear
								</button>

								{/* <li>
									<select name="" className="form-select" id=""><option value="market">Date</option></select>
								</li> */}

							</ul>
						</div>
						<div className="campiagn_outer">
							<h3 className="filter_title">Media plan</h3>
							<div className="campiagn_filter d-none">
								<div className="filter_bg">
									{Object.keys(selectedfilter).map((value, index) => {
										return (
											<>
												{selectedfilter[value]?.value != "" &&
													typeof selectedfilter[value]?.value == "string" && (
														<span className="show_filter" key={index}>
															{selectedfilter[value]?.name}:{" "}
															{selectedfilter[value]?.value}{" "}
															<AiOutlineClose
																onClick={() =>
																	removefilter(selectedfilter[value]?.key)
																}
															/>{" "}
														</span>
													)}
												{
													typeof selectedfilter[value]?.value == "object" &&
													selectedfilter[value]?.value.length > 0 &&
													selectedfilter[value]?.value.map(
														(element, index) => {
															return (
																<span className="show_filter" key={index}>
																	{selectedfilter[value]?.name}:{" "}
																	{element?.value}{" "}
																	<AiOutlineClose
																		onClick={() =>
																			removefilter(
																				selectedfilter[value]?.key,
																				index
																			)
																		}
																	/>{" "}
																</span>
															);
														}
													)

													//
												}
											</>
										);
									})}
									<div className="add_filter">
										<div className="filter_menu">
											<Dropdown className="advert">
												<Dropdown.Toggle
													id="dropdown-autoclose-true"
													className="filter_btn"
												>
													Add Filter <FaPlus className="ms-2" />
												</Dropdown.Toggle>

												<Dropdown.Menu className="filter_menu">
													<ul>
														<>
															<li className="search">
																<input
																	type="text"
																	className="form-control"
																	onChange={filter_search}
																/>
																<FaSearch />
																{searchfilter.length > 0 && (
																	<div className="search_items">
																		{searchfilter.map(
																			(searchvalue, searchindex) => {
																				return (
																					<>
																						{Object.keys(searchvalue).map(
																							(element, eleindex) => {
																								return (
																									<Dropdown.Item
																										className="mb-2 mt-1"
																										onClick={() => {
																											managefilter(
																												searchvalue[element]
																											);
																										}}
																									>
																										<b> {element}</b> :{" "}
																										{searchvalue[element].value}
																									</Dropdown.Item>
																								);
																							}
																						)}
																					</>
																				);
																			}
																		)}
																	</div>
																)}
															</li>
															{Object.keys(filter).map((ddown, index) => {
																return (
																	<li key={index}>
																		<Dropdown className={`child${index}`}>
																			<Dropdown.Toggle id="dropdown-autoclose-true">
																				<span> {filter[ddown].name}</span>{" "}
																				<FaChevronRight />
																			</Dropdown.Toggle>
																			<Dropdown.Menu className="child_filters">
																				{(filter[ddown].type == "text" ||
																					filter[ddown].type == "number") && (
																						<div>
																							<p>
																								<b>Contains</b>
																							</p>
																							<div>
																								<label htmlFor="">
																									Any of the following values
																								</label>
																								<input
																									type={filter[ddown].type}
																									className="form-control mt-2"
																									value={filtervalue[ddown]}
																									onChange={(e) =>
																										setsdvertiser(e, ddown)
																									}
																								/>
																							</div>
																							<Dropdown.Item
																								className="cmn_btn ms-0 mt-3"
																								onClick={(e) => {
																									applyadvertiser(e, ddown);
																								}}
																							>
																								Apply
																							</Dropdown.Item>
																						</div>
																					)}

																				{/* {filter[ddown].type == 'dropdown' && ddown == "campaign" && filter[ddown].values?.map((opvalue, valindex) => {
                                                                        return (
                                                                            <Dropdown.Item key={valindex} onClick={() => { managefilter({ id: opvalue?.id, value: opvalue?.campaign, name: 'campaign' }) }}>{opvalue?.campaign}</Dropdown.Item>
                                                                        )
                                                                    })
                                                                    } */}

																				{filter[ddown].type == "dropdown" &&
																					ddown == "pipeline" &&
																					filter[ddown].values?.map(
																						(pipvalue, pipindex) => {
																							return (
																								<Dropdown.Item
																									key={pipindex}
																									onClick={() => {
																										managefilter({
																											id: pipvalue?.pipelineId,
																											value: pipvalue?.label,
																											name: "pipeline",
																										});
																									}}
																								>
																									{pipvalue?.label}
																								</Dropdown.Item>
																							);
																						}
																					)}

																				{filter[ddown].type ==
																					"multidropdown" &&
																					ddown == "ownerType" &&
																					Object.keys(
																						filter[ddown].values
																					)?.map((ownrvalue, ownrindex) => {
																						return (
																							<Dropdown
																								key={ownrindex}
																								className="multi_filter"
																							>
																								<Dropdown.Toggle>
																									<span>
																										{
																											filter[ddown].values[
																												ownrvalue
																											].name
																										}
																									</span>{" "}
																									<FaChevronRight />
																								</Dropdown.Toggle>
																								<Dropdown.Menu className="child_filter">
																									{filter[ddown].values[
																										ownrvalue
																									].values?.map(
																										(owvalue, owindex) => {
																											return (
																												<Dropdown.Item
																													onClick={() => {
																														managefilter({
																															id: owvalue?.id,
																															value:
																																owvalue?.email,
																															name: ownrvalue,
																														});
																													}}
																												>
																													{owvalue.first_name}{" "}
																													{owvalue.last_name}
																												</Dropdown.Item>
																											);
																										}
																									)}
																								</Dropdown.Menu>
																							</Dropdown>
																						);
																					})}
																				{filter[ddown].type == "dropdown" &&
																					ddown == "status" &&
																					filter[ddown].values?.map(
																						(statusvalue, statusindex) => {
																							return (
																								<Dropdown.Item
																									key={statusindex}
																									onClick={() => {
																										managefilter({
																											id: statusvalue?.value,
																											value: statusvalue?.name,
																											name: "status",
																										});
																									}}
																								>
																									{statusvalue?.name}
																								</Dropdown.Item>
																							);
																						}
																					)}
																			</Dropdown.Menu>
																		</Dropdown>
																	</li>
																);
															})}
														</>
													</ul>
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</div>
								</div>
								<button className="cmn_btn" onClick={filter_submit}>
									Go{" "}
								</button>
							</div>
						</div>
						{/* filter ends */}
						<div className="advert_tabel campign_index_table">
							<Table
								responsive

							// className={
							// 	// filtered_result?.results?.length === 0 ? "no_data" : null
							// }
							>
								<thead>
									<tr>
										<th onClick={() => { handleSort('id'); handleSorting('deal_id') }} className="text-start">ID {defaultId && <IoMdArrowDropdown className="d-none" style={{ fontSize: "15px" }} />} {arrowId && up && <IoMdArrowDropdown className="d-none" style={{ fontSize: "15px" }} />}{arrowId && down && <IoMdArrowDropup style={{ fontSize: "15px" }} />}</th>
										<th onClick={() => { handleSortByMedia('media'); handleSorting('campaign') }} className="text-start">Media Plan {defaultMedia && <IoMdArrowDropdown className="d-none" style={{ fontSize: "15px" }} />} {arrowMedia && up && <IoMdArrowDropdown className="d-none" style={{ fontSize: "15px" }} />}{arrowMedia && down && <IoMdArrowDropup style={{ fontSize: "15px" }} />}</th>
										<th className="text-start">Advertiser</th>
										<th onClick={() => { handleSortByMarket('market'); handleSorting('pipeline') }} className="text-start">Market {defaultMarket && <IoMdArrowDropdown className="d-none" style={{ fontSize: "15px" }} />} {arrowMarket && up && <IoMdArrowDropdown className="d-none" style={{ fontSize: "15px" }} />}{arrowMarket && down && <IoMdArrowDropup style={{ fontSize: "15px" }} />}</th>
										<th onClick={() => { handleSorting('date_from'); handleSortDate() }} className="text-start">Start Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" /></svg></th>
										<th onClick={() => { handleSorting('date_to'); handleSortDate() }} className="text-start">End Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833" />
										</svg></th>
										<th className="text-start">Status</th>
										<th style={{ opacity: "0" }}>pdf</th>
										{/* <th className="text-start">Status Platform</th> */}
									</tr>
								</thead>
								<tbody>
									{filteredDealData?.length === 0 && (
										<div className="No_Data">
											{" "}
											<img
												className="nodata"
												src="/assets/images/notfound.svg"
											/>
										</div>
									)}

									{filteredDealData?.map((curelem, index) => {
										console.log(curelem, "this is the current element")
										return (
											<tr
												key={index}
												onClick={() => {
													gotonext(
														curelem?.id,
														curelem?.dealId,
														curelem.currencyCode,
														curelem?.isNew
													);
												}}
												style={{ cursor: "pointer" }}
											>
												<td className="text-start">{curelem?.dealId}</td>
												<td className="text-start">{curelem?.campaign}</td>
												<td className="text-start">{curelem?.advertiser}</td>
												<td className="text-start">
													{curelem?.pipelineNavigation?.label}
												</td>
												<td className="text-start"><Moment format="DD/MM/YYYY">{curelem?.dateFrom}</Moment></td>
												<td className="text-start"><Moment format="DD/MM/YYYY">{curelem?.dateTo}</Moment></td>
												<td className="text-start">
													{/* {curelem?.statusNavigation?.label} */}
													{/* {(curelem?.status == 1 || curelem?.status == 3) && (
														<>
															{curelem?.isNew == "1" && <strong className="">New</strong>}
															{curelem?.isNew == "0" && <strong className="">Active</strong>}
														</>
													)} */}
													{/* {curelem?.status == 2 && <strong className="">Inactive</strong>} */}
													{/* {curelem?.status == 3 &&
                                                    <>
                                                        New
                                                    </>

													{curelem?.statusNavigation
                                            ?.description == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                          {curelem?.statusNavigation
                                            ?.description == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                          {curelem?.statusNavigation
                                            ?.description == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                          {curelem?.statusNavigation
                                            ?.description == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}


                                                // } */}
												
													{curelem?.statusNavigation?.idStatus == 1 && <div className="status_description_active"><img src="/assets/images/check_circle.svg"/><strong>Active</strong></div>}
													{curelem?.statusNavigation?.idStatus == 2 && <div className="status_description_inactive"><img src="/assets/images/cancel_FILL.svg"/><strong>Inactive</strong></div>}
													{curelem?.statusNavigation?.idStatus == 3 && <>{curelem?.campaignsCount !== 0 ?
													<div className="status_description_active"><img src="/assets/images/check_circle.svg"/><strong>Active</strong></div>
													:
													<div className="status_description_new"><img src="/assets/images/draft_FILL.svg"/><strong>New</strong></div>
													}
													</>}
												</td>
												<td className="text-right"><div className="pdf_icon" title="View Campaigns">
													<img src="/assets/images/description_file.svg" onClick={() => {
														gotonext(
															curelem?.id,
															curelem?.dealId,
															curelem.currencyCode,
															curelem?.isNew
														);
													}} />
												</div></td>
												{/* <td className="text-start">
													{curelem?.plateformStatus
														? curelem?.plateformStatus
														: "Pending"}
												</td> */}
											</tr>
										);
									})}
								</tbody>
							</Table>
							{filteredDealData?.length !== 0 && <div className="cmn__pagination">
								<ul>
									<span>Rows per page</span>
									<li className="no__rows">
										<select
											name=""
											id=""
											onChange={(e) => setPageLimit(e.target.value)}

										>
											{/* <option value="5">5</option> */}
											<option value="10">10</option>
											<option value="15">15</option>
											<option value="20">20</option>
										</select>
									</li>
									<li className="total_pages me-3">
										<span className="active_pages">{current_Page}</span> of <span>{page_Count}</span>
									</li>
									{ pageNo != 1 && <li>
										<BsChevronBarLeft onClick={() => setPageNo(1)} />
									</li>}
									{  pageNo != 1 && <li

									>
										<BsChevronLeft onClick={() => setPageNo(pageNo - 1)} />
									</li>}
									{  pageNo != page_Count && <li

									>
										<BsChevronRight onClick={() => setPageNo(pageNo + 1)} />
									</li>}
									{  pageNo != page_Count && <li>
										<BsChevronBarRight onClick={() => setPageNo(page_Count)} />
									</li>}
								</ul>
							</div>}
						</div>
						{/* <div className="cmn_pagination">
							<ReactPaginate
								nextLabel={<BiChevronsRight />}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={filtered_result?.page_count}
								previousLabel={<BiChevronsLeft />}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
							/>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};
export default CampaignPage;
