import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const developedBy = createAsyncThunk(
    'creative/developedBy',
    async ({ token }, thunkAPI) => {
        try {
            return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/developed-by`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                return response.data
            }).catch((e) => {
                let error = "";
                if (e.response) {
                    error = e.response.data;
                } else if (e.request) {
                    error = e.request;
                } else {
                    error = e.message;
                }
                return thunkAPI.rejectWithValue(error)
            });
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getCreativeTypes = createAsyncThunk(
    'creative/getCreativeTypes',
    async ({ token, type_id }, thunkAPI) => {
        try {
            return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/creative-types/${type_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                return response.data
            }).catch((e) => {
                let error = "";
                if (e.response) {
                    error = e.response.data;
                } else if (e.request) {
                    error = e.request;
                } else {
                    error = e.message;
                }
                return thunkAPI.rejectWithValue(error)
            });
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const getLineItem = createAsyncThunk(
    'creative/getLineItem',
    async ({ token, lineitem_id }, thunkAPI) => {
        try {
            return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getlineitemid/${lineitem_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                return response.data
            }).catch((e) => {
                let error = "";
                if (e.response) {
                    error = e.response.data;
                } else if (e.request) {
                    error = e.request;
                } else {
                    error = e.message;
                }
                return thunkAPI.rejectWithValue(error)
            });
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const StoreCreative =  createAsyncThunk(
    'creative/StoreCreative',
    async (values, thunkAPI) => {
        try {
            return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-creative`, values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+values.get("token"),
                }
            }).then((response) => {
                console.log(response)
                return response.data
            }).catch((e) => {
                console.log(e)
                let error = "";
                if (e.response) {
                    if(e.response.data.file){
                        error = e.response.data.file[0];
                    }else{
                        error = e.response.data;
                    }
                    
                } else if (e.request) {
                    error = e.request;
                } else {
                    error = e.message;
                }
                return thunkAPI.rejectWithValue(error)
            });
        } catch (error) {
            console.log(error, "err")
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const UpdateCreative =  createAsyncThunk(
    'creative/UpdateCreative',
    async ({values, id}, thunkAPI) => {
        try {
            return await axios.put(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-creative/${id}`, values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+values.get("token"),
                }
            }).then((response) => {
                console.log(response)
                return response.data
            }).catch((e) => {
                console.log(e)
                let error = "";
                if (e.response) {
                    if(e.response.data.file){
                        error = e.response.data.file[0];
                    }else{
                        error = e.response.data;
                    }
                    
                } else if (e.request) {
                    error = e.request;
                } else {
                    error = e.message;
                }
                return thunkAPI.rejectWithValue(error)
            });
        } catch (error) {
            console.log(error, "err")
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const listCreative =  createAsyncThunk(
    'creative/listCreative',
    async ({lineitem_id, token, filter, page, pageSize}, thunkAPI) => {
        try {
            return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/creatives/${lineitem_id}?page=${page}&pageSize=${pageSize}&${filter}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+token,
                }
            }).then((response) => {
                return response.data
            }).catch((e) => {
                console.log(e)
                let error = "";
                if (e.response) {
                        error = e.response.data;
                    
                } else if (e.request) {
                    error = e.request;
                } else {
                    error = e.message;
                }
                return thunkAPI.rejectWithValue(error)
            });
        } catch (error) {
            console.log(error, "err")
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const CreativeOnOff =  createAsyncThunk(
    'creative/CreativeOnOff',
    async ({token,id}, thunkAPI) => {
        try {
            return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/creative-onOff`, {id: id}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+token,
                }
            }).then((response) => {
                return response.data
            }).catch((e) => {
                console.log(e)
                let error = "";
                if (e.response) {
                    if(e.response.data.file){
                        error = e.response.data.file[0];
                    }else{
                        error = e.response.data;
                    }
                    
                } else if (e.request) {
                    error = e.request;
                } else {
                    error = e.message;
                }
                return thunkAPI.rejectWithValue(error)
            });
        } catch (error) {
            console.log(error, "err")
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const CreativesSlice = createSlice({
    name: 'Creatives',
    initialState: {
        successMessage: '',
        errorMessage: '',
        developed_by: [],
        creativeTypes:[],
        list_creative:[],
        line_Item: {},
        stored_creative: {},
        isFetchingDevelopedBy: false,
        isSuccessDevelopedBy: false,
        isErrorDevelopedBy: false,
        isFetchingLineitem: false,
        isSuccessLineitem: false,
        isErrorLineitem: false,
        isFetchingCreativeTypes: false,
        isSuccessCreativeTypes: false,
        isErrorCreativeTypes: false,
        isFetchingStoreCreative: false,
        isSuccessStoreCreative: false,
        isErrorStoreCreative: false,
        isFetchinglistCreative: false,
        isSuccesslistCreative: false,
        isErrorlistCreative: false,
        isFetchingCreativeOnOff: false,
        isSuccessCreativeOnOff: false,
        isErrorCreativeOnOff: false,
        isFetchingUpdateCreative: false,
        isSuccessUpdateCreative: false,
        isErrorUpdateCreative: false,

    },
    reducers: {
        clearState: (state) => {
            state.isFetchingDevelopedBy= false;
            state.isSuccessDevelopedBy= false;
            state.isErrorDevelopedBy= false;
            state.isFetchingLineitem= false;
            state.isSuccessLineitem= false;
            state.isErrorLineitem= false;
            state.isFetchingCreativeTypes= false;
            state.isSuccessCreativeTypes= false;
            state.isErrorCreativeTypes= false;
            state.isFetchingCreativeOnOff= false;
            state.isSuccessCreativeOnOff= false;
            state.isErrorCreativeOnOff= false;
            return state;
        },
        clearDevelop:(state)=>{
            state.isFetchingDevelopedBy= false;
            state.isSuccessDevelopedBy= false;
            state.isErrorDevelopedBy= false;
            state.developed_by= [];
            return state;
        },
        clearLineitem:(state)=>{
            state.isFetchingLineitem= false;
            state.isSuccessLineitem= false;
            state.isErrorLineitem= false;
            state.line_Item= {};
            return state;
        },
        clearCreativeTypes:(state)=>{
            state.isFetchingCreativeTypes= false;
            state.isSuccessCreativeTypes= false;
            state.isErrorCreativeTypes= false;
            state.creativeTypes= [];
            return state;
        },
        clearstorecreative:(state)=>{
            state.isFetchingStoreCreative= false;
            state.isSuccessStoreCreative= false;
            state.isErrorStoreCreative= false;
            return state;
        },
        clearlistCreative:(state)=>{
            state.isFetchinglistCreative= false;
            state.isSuccesslistCreative= false;
            state.isErrorlistCreative= false;
            return state;
        },
        clearupdateCreative:(state)=>{
            state.isFetchingUpdateCreative= false;
            state.isSuccessUpdateCreative= false;
            state.isErrorUpdateCreative= false;
            return state;
        }
    },
    extraReducers: {
        [developedBy.fulfilled]: (state, { payload }) => {
            state.developed_by = payload.data;
            state.isFetchingDevelopedBy = false;
            state.isSuccessDevelopedBy = true;
            state.isErrorDevelopedBy = false;
          },
          [developedBy.rejected]: (state, { payload }) => {
            state.isFetchingDevelopedBy = false;
            state.isErrorDevelopedBy = true;
            state.errorMessage = payload
          },
          [developedBy.pending]: (state) => {
            state.isFetchingDevelopedBy = true;
          },
        [getLineItem.fulfilled]: (state, { payload }) => {
            state.line_Item = payload.data;
            state.isFetchingLineitem = false;
            state.isSuccessLineitem = true;
            state.isErrorLineitem = false;
          },
          [getLineItem.rejected]: (state, { payload }) => {
            state.isFetchingLineitem = false;
            state.isErrorLineitem = true;
            state.errorMessage = payload
          },
          [getLineItem.pending]: (state) => {
            state.isFetchingLineitem = true;
          },
        [getCreativeTypes.fulfilled]: (state, { payload }) => {
            state.creativeTypes = payload.data;
            state.isFetchingCreativeTypes = false;
            state.isSuccessCreativeTypes = true;
            state.isErrorCreativeTypes = false;
          },
          [getCreativeTypes.rejected]: (state, { payload }) => {
            state.isFetchingCreativeTypes = false;
            state.isErrorCreativeTypes = true;
            state.errorMessage = payload
          },
          [getCreativeTypes.pending]: (state) => {
            state.isFetchingCreativeTypes = true;
          },
        [StoreCreative.fulfilled]: (state, { payload }) => {
            console.log(payload)
            state.stored_creative = payload.data;
            state.isFetchingStoreCreative = false;
            state.isSuccessStoreCreative = true;
            state.isErrorStoreCreative = false;
          },
          [StoreCreative.rejected]: (state, { payload }) => {
            console.log(payload)
            state.isFetchingStoreCreative = false;
            state.isErrorStoreCreative = true;
            state.errorMessage = payload
          },
          [StoreCreative.pending]: (state) => {
            state.isFetchingStoreCreative = true;
          },
        [listCreative.fulfilled]: (state, { payload }) => {
            state.list_creative = payload.data;
            state.isFetchinglistCreative = false;
            state.isSuccesslistCreative = true;
            state.isErrorlistCreative = false;
          },
          [listCreative.rejected]: (state, { payload }) => {
            state.isFetchinglistCreative = false;
            state.isErrorlistCreative = true;
            state.errorMessage = payload
          },
          [listCreative.pending]: (state) => {
            state.isFetchinglistCreative = true;
          },
        [CreativeOnOff.fulfilled]: (state, { payload }) => {
            state.isFetchingCreativeOnOff = false;
            state.isSuccessCreativeOnOff = true;
            state.isErrorCreativeOnOff = false;
          },
          [CreativeOnOff.rejected]: (state, { payload }) => {
            state.isFetchingCreativeOnOff = false;
            state.isErrorCreativeOnOff = true;
            state.errorMessage = payload
          },
          [CreativeOnOff.pending]: (state) => {
            state.isFetchingCreativeOnOff = true;
          },
        [UpdateCreative.fulfilled]: (state, { payload }) => {
            state.isFetchingUpdateCreative = false;
            state.isSuccessUpdateCreative = true;
            state.isErrorUpdateCreative = false;
          },
          [UpdateCreative.rejected]: (state, { payload }) => {
            state.isFetchingUpdateCreative = false;
            state.isErrorUpdateCreative = true;
            state.errorMessage = payload
          },
          [UpdateCreative.pending]: (state) => {
            state.isFetchingUpdateCreative = true;
          }
    }

})
export const {
    clearState, clearDevelop,
    clearLineitem, clearCreativeTypes,
    clearstorecreative,
    clearupdateCreative
  } = CreativesSlice.actions;
  export const CreativeSelector = (state) => state.Creatives;