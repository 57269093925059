import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate } from "react-router-dom";
import {
  kite_products,
  storeCampaign,
  updateCampaign,
  campaignManagerSelector,
  storeProduct,
  clearState,
  clearcampaignState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import { toast } from "react-hot-toast";
import {
  ExchangeRates,
  deligateSelector,
} from "../../app/features/CampaignManager/DelegateSlice";
import moment from "moment";
import Loader from "../Loader";
import Moment from "react-moment";

const NewCompaignSaas = (props) => {
  const formikRef = React.useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [budget_usd1, SetUSDBudget] = useState(0);
  const {
    kiteProducts,
    isCampaignSaved,
    isCampaignupdated,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useSelector(campaignManagerSelector);
  const { dealCurrencies } = useSelector(deligateSelector);
  const [show_create_product, setShowCreateProduct] = useState(false);
  const [product_name, setProductName] = useState("");
  const [show_message, setShow_message] = useState(false);
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [budget, setBudget] = useState();
  const [total_sum_budget, setTotal_sum_budget] = useState("");
  const [err, SetError] = useState(false);
  const [reload, setReload] = useState(false);
  const [reload_check, setReload_check] = useState(false);
  const [loadershown, setloadershown] = useState(false);
  const [startDate,setStartDate] = useState()

  const initialValues = {
    name: "",
    kite_deal_id: props.deal_id,
    date_from: "",
    date_to: "",
    budge: "",
    product_id: "",
    plateform_campaign_id: "",
    status_id: 2,
    dateRange: "",
    budget_usd: "",
    token: localStorage.getItem("token"),
  };

  useEffect(() => {
    dispatch(
      kite_products({
        token: localStorage.getItem("token"),
        clientId: props.clientId,
      })
    );
  }, [props.campaign_detail?.id]);
  useEffect(() => {
    if (reload) {
      setTimeout(() => {
        props.setModalShow(false);
        props.getData();
        props.searchFilter();
        toast.success("Campaign Saved Successfully");
      }, 2000);
    }
    dispatch(clearState());
  }, [reload]);

  useEffect(() => {
    if (reload_check) {
      setTimeout(() => {
        toast.success("Campaign Updated Successfully");
        props.searchFilter();
        props.setModalShow(false);
      }, 1000);
    }
    dispatch(clearState());
  }, [reload_check]);

  useEffect(() => {
    if (props.getcampain) {
      props.getcampain();
    }
    dispatch(clearcampaignState());
  }, [isSuccess]);

  // useEffect(() => {
  //     dispatch(deal_currency({ token: localStorage.getItem('token'), dealId: props.dealid }));
  // }, [deal_id])
  useEffect(() => {
    if (props.dealCurrency != "") {
      dispatch(ExchangeRates({ dealCurrency: props.dealCurrency }));
    }
  }, [props.dealCurrency]);

  useEffect(() => {
    if (props.campaign_detail) {
      formikRef.current?.setFieldValue(
        "budget_usd",
        props?.campaign_detail?.budget_usd,
        true
      );
      formikRef.current?.setFieldValue(
        "name",
        props?.campaign_detail?.name,
        true
      );
      formikRef.current?.setFieldValue(
        "status_id",
        props?.campaign_detail?.statusId,
        true
      );
      formikRef.current?.setFieldValue(
        "plateform_campaign_id",
        props?.campaign_detail?.platformCampaignId,
        true
      );
      formikRef.current?.setFieldValue(
        "product_id",
        props?.campaign_detail?.productNavigation?.productId,
        true
      );
      formikRef.current?.setFieldValue(
        "budge",
        props?.campaign_detail?.budge,
        true
      );
      formikRef.current?.setFieldValue(
        "date_to",
        moment(props?.campaign_detail?.date_to).format("YYYY-MM-DD"),
        true
      );
      setStartDate(moment(props?.campaign_detail?.date_to).format("YYYY-MM-DD"))
      formikRef.current?.setFieldValue(
        "date_from",
        moment(props?.campaign_detail?.date_from).format("YYYY-MM-DD"),
        true
      );
      SetError(false);
      setBudget(parseFloat(props?.campaign_detail?.budge));
      // alert(props?.campaign_detail?.budge)
    }
  }, [props.campaign_detail]);

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setloadershown(true);
    if (!props?.campaign_detail) {
      const response = await fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-campaign`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values?.token,
          },
          body: JSON.stringify(values),
        }
      );

      const result = await response.json();
      if (result) {
        setloadershown(false);
        props.setModalShow(false);
        props.searchFilter();
        props.getCampaignInfo();
        props?.getReloadData()
      }
    } else {
      dispatch(
        updateCampaign({ values: values, id: props.campaign_detail.id })
      ).then((result) => {
        props.setModalShow(false);
        setReload_check(true);
        props.searchFilter();
        props.onRefresh();
      });
    }
  };
  useEffect(() => {
    check_budget();
  });

  const check_budget = () => {
    if (props.campaign_detail) {
      setBudget(props.total_Budget - props.sum_Budget);
    } else {
      let pending_budget = props?.totalBudget - props?.sumBudget;
      setBudget(pending_budget);
    }
  };

  const calculate_budget = (e) => {
    if (budget + props?.campaign_detail?.budge < e.target.value) {
      SetError(true);
    }
  };

  const handleCallback = (start, end) => {
    formikRef.current?.setFieldValue(
      "date_from",
      start.format("YYYY-MM-DD"),
      false
    );
    formikRef.current?.setFieldValue(
      "date_to",
      end.format("YYYY-MM-DD"),
      false
    );
  };

  const handleCallback1 = (start, end) => {
    formikRef.current?.setFieldValue(
      "date_from",
      startDate,
      false
    );
    formikRef.current?.setFieldValue(
      "date_to",
      end.format("YYYY-MM-DD"),
      false
    );
  };
  const calculatebudget = (e) => {
    const computeBudget = e.target.value;

    let budg = e.target.value;

    if (e.target.value < props?.budget_used) {
      SetError(true);
    } else {
      SetError(false);
    }

    if (budg != "") {
      compute("USD", computeBudget);
      setTotal_sum_budget(budget);
    }
  };

  const compute = (output_currency1, computeBudget) => {
    if (Object.keys(dealCurrencies).length > 0) {
      const new_rate = dealCurrencies[output_currency1];
      // rate.innerText = `1 ${input_currency1} = ${new_rate} ${output_currency1}`

      let output_amount = (computeBudget * new_rate).toFixed(2);
      SetUSDBudget(output_amount);
      formikRef.current?.setFieldValue("budget_usd", output_amount, false);
      return output_amount;
    }
  }
  const togglecreateproduct = (event) => {
    setShowCreateProduct(event.target.checked);
  };
  const saveProduct = () => {
    let productName = product_name.trim();
    if (productName != "") {
      dispatch(
        storeProduct({
          token: localStorage.getItem("token"),
          clientId: props.clientId,
          productName: productName,
        })
      ).then((result) => {
        dispatch(
          kite_products({
            token: localStorage.getItem("token"),
            clientId: props.clientId,
          })
        );
        setShowCreateProduct(false);
      });
    }
  };
  const handleProductName = (e) => {
    let Product_Name = e.target.value;
    if (Product_Name.length === 0) {
      setShow_message(false);
    } else {
      let Check_Product_Name = kiteProducts.find(
        (item) => item.name.toLowerCase() === Product_Name.toLowerCase()
      );
      if (Check_Product_Name) {
        setShow_message(true);
      } else {
        setProductName(Product_Name);
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure to delete the item?")) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/delete-campaign/${id}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => props.searchFilter())
        .then(toast.success("Deleted Successfully"))
        .then(props.setModalShow(false))

        .catch((error) => toast.error(error));
    }
  };

  const handleSetBudget = (e) => {
  };

  const permission = JSON.parse(localStorage.getItem('userdata'));

  console.log(props?.campaignStat,"this is the campaign status")

  return (
    // <div className="content_outer">
    <div className="content pt-0">
      <Loader showLoader={loadershown} />

      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          date_from: Yup.string().required('Select date range'),
          budge: Yup.string().required("Select Budget"),
          product_id: Yup.string().required("Please Select Product"),
        })}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="campaignName">
                      Campaign Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      placeholder="Lorem Ipsum"
                    />
                    {errors.name && touched.name ? (
                      <span className="text-danger">{errors.name}</span>
                    ) : null}
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="product">
                      Product
                    </label>
                    <select
                      name="product_id"
                      className="form-select"
                      value={values.product_id}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="">--Select--</option>
                      {kiteProducts?.map((product, index) => {
                        return (
                          <option value={product?.productId} key={index}>
                            {product?.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.product_id && touched.product_id ? (
                      <span className="text-danger">{errors.product_id}</span>
                    ) : null}
                  </div>
                </Col>
                { props?.campaignStat === "ACTIVE" ? 
                 <Col lg={6}>
                {/* <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position" htmlFor="date">End date</label>
                  <input min={new Date()} max={new Date(props?.end_date)} type="date" id="date" className="date"/>
                </div> */}
                 <div className="form-group select_country_container cmn_modal_field mb-3">
                 <label className="lbl_position" htmlFor="startdate">
                         End Date
                      </label>
                      <DateRangePicker
                        initialSettings={{
                          endDate: new Date(props?.end_date),
                          minDate:
                            new Date(props?.start_date) < new Date()
                              ? new Date()
                              : new Date(props?.start_date),
                          maxDate: new Date(props?.end_date),
                          singleDatePicker: true,
                          locale: {
                            format: "DD/MM/YYYY", // Specify the desired date format here
                          },
                        }}
                        name="date_from"
                        onCallback={handleCallback1}
                      >
                        <input
                          type="text"
                          name="dateRange"
                          className="form-control"
                        />
                      </DateRangePicker>
                      </div>
                </Col>
                :
                props?.campaign_detail && (
                  <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                      <label className="lbl_position" htmlFor="startdate">
                        Start Date - End Date
                      </label>
                      <DateRangePicker
                        initialSettings={{
                          startDate:
                            props?.campaign_detail?.statusNavigation
                              ?.description === "ACTIVE" || "PAUSE"
                              ? new Date()
                              : new Date(props?.start_date) < new Date()
                                ? new Date()
                                : new Date(props?.start_date),
                          endDate: new Date(props?.end_date),
                          minDate:
                            new Date(props?.start_date) < new Date()
                              ? new Date()
                              : new Date(props?.start_date),
                          maxDate: new Date(props?.end_date),
                          locale: {
                            format: "DD/MM/YYYY", // Specify the desired date format here
                          },
                        }}
                        name="date_from"
                        onCallback={handleCallback}
                      >
                        <input
                          type="text"
                          name="dateRange"
                          className="form-control"
                        />
                      </DateRangePicker>

                      {errors.date_from && touched.date_from ? (
                        <span className="text-danger">{errors.date_from}</span>
                      ) : null}
                      
                    </div>
                    <div className="d-flex">
                        <p className="me-4">
                          Actual start Date:
                          <Moment format="DD/MM/YYYY">
                            {props?.campaign_detail?.date_from}
                          </Moment>
                        </p>
                        <p>
                          Actual End Date:
                          <Moment format="DD/MM/YYYY">
                            {props?.campaign_detail?.date_to}
                          </Moment>
                        </p>
                      </div>
                  </Col>
                )}
                {props?.deal_detail && (
                  <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                      <label className="lbl_position" htmlFor="startdate">
                        Start Date - End Date
                      </label>
                      {/* initialSettings={{ maxSpan: {days: 7}}} */}

                      <DateRangePicker
                        initialSettings={{
                          // new Date(props?.deal_detail?.dateFrom) > new Date() ? new Date(props?.deal_detail?.dateFrom) :
                          startDate:
                            new Date(props?.deal_detail?.dateFrom) < new Date()
                              ? new Date()
                              : new Date(props?.deal_detail?.dateFrom),
                          endDate: new Date(props?.deal_detail?.dateTo),
                          minDate:
                            new Date(props?.deal_detail?.dateFrom) > new Date()
                              ? new Date(props?.deal_detail?.dateFrom)
                              : new Date(),
                          maxDate: new Date(props?.deal_detail?.dateTo),
                          locale: {
                            format: "DD/MM/YYYY", // Specify the desired date format here
                          },
                        }}
                        name="date_from"
                        onCallback={handleCallback}
                      >
                        <input
                          type="text"
                          name="dateRange"
                          className="form-control"
                        />
                      </DateRangePicker>
                      {errors.date_from && touched.date_from ? (
                        <span className="text-danger">{errors.date_from}</span>
                      ) : null}
                    </div>
                  </Col>
                )}

                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position" htmlFor="budget">
                      {/* Budget [
                      {props?.currencyCode
                        ? props?.currencyCode
                        : props?.currency_code}
                      ] */}
                      Budget ({(budget && !err) ? <p className='mt-2'>Budget must be between {!props?.campaign_detail?.budge ? parseInt(budget) : parseInt(props?.budget_used)} {props?.campaign_detail?.budge ? "and" : null} {props?.campaign_detail?.budge + budget ? parseInt(props?.campaign_detail?.budge + budget) : null}</p> : <p style={{ color: "red" }}>You cannot add more then {(props?.campaign_detail ? budget + props?.campaign_detail?.budge : budget)}</p>}
                      {errors.budge && touched.budge ? (
                        <span className='text-danger'>{errors.budge}</span>
                      ) : null})
                    </label>
                    <input
                      type="number"
                      step="any"
                      style={err ? { border: "1px solid red" } : null}
                      min={
                        props?.campaign_detail
                          ? props?.campaign_detail?.budge
                          : 1
                      }
                      max={
                        props?.campaign_detail
                          ? props?.campaign_detail?.budge +
                          props?.remainingBudget * 1
                          : props?.totalBudget - props?.sumBudget
                      }
                      className="form-control"
                      name="budge"
                      onChange={(e) => {
                        handleChange(e);
                        calculatebudget(e);
                        handleSetBudget(e);
                        calculate_budget(e);
                      }}
                      value={!values.budge ? null : values.budge}
                    />


                    {/* {(budget && !err ) ? <p className='mt-2'>Budget must be between {!props?.campaign_detail?.budge ? parseInt(budget) : parseInt(props?.budget_used)} {props?.campaign_detail?.budge ? "and" : null} {props?.campaign_detail?.budge + budget ? parseInt(props?.campaign_detail?.budge + budget) : null}</p> : <p style={{color:"red"}}>You cannot add more then {( props?.campaign_detail ? budget + props?.campaign_detail?.budge : budget)}</p> }
                                                    {errors.budge && touched.budge ? (
                                                        <span className='text-danger'>{errors.budge}</span>
                                                    ) : null} */}
                  </div>
                  {/* {props?.totalBudget && (
                      <p className="mt-2">
                        Budget must be between 0 and{" "}
                        {props?.totalBudget - props?.sumBudget}
                      </p>
                    )}
                    {props?.total_Budget ? (
                      <p className="mt-2">
                        Budget must be between {props?.budget_used} and{" "}
                        {props?.campaign_detail?.budge +
                          props?.remainingBudget * 1}
                      </p>
                    ) : null} */}

                  <p>Budget USD : ${budget_usd1}</p>

                </Col>
                {/* <Col lg={6}>
                  <div className="form-group mb-3">
                    <label className="mb-2" htmlFor="budgetUsd">
                      Budget USD
                    </label>
                    <span className="d-block">$ {values.budget_usd}</span>
                  </div>
                </Col> */}
                <Col lg={12}>
                  <div className="form-group mb-3 d-none">
                    <input
                      type="checkbox"
                      onChange={togglecreateproduct}
                      checked={show_create_product}
                    />
                    <span className="create_product">Create Product</span>

                    <div style={show_create_product ? {} : { display: "none" }}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="save_product">
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              name="product_name"
                              onChange={(e) => {
                                handleProductName(e);
                              }}
                            />
                            {show_message ? (
                              <small style={{ color: "red" }}>
                                *This product is already exist*
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="save_product">
                            <button type="button" onClick={saveProduct}>
                              Save Product
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={12}>
                                    <h2 className='mt-3 mb-3 p-0'>
                                        Campaign Setting
                                    </h2>
                                </Col> */}

                <Col lg={12} className="text-end mt-3">
                  <div className="d-flex justify-content-end">
                    {props?.campaign_detail?.statusId !== 2 &&
                      props.campaign_detail && permission?.Permissions[5] ? (
                      <button
                        type="button"
                        className="cmn_btn  delete_cmn_btn"
                        onClick={() => {
                          handleDelete(props?.campaign_detail?.id);
                        }}
                      >
                        Delete
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="ms-auto me-4 cancel_cm_btn"
                      onClick={() => props.setModalShow(false)}
                    >
                      Cancel
                    </button>
                    {/* <button type='submit' className='cmn_btn ms-2'  >
                                        {!props?.campaign_detail ? "save" : } 
                                        </button>  */}
                    {/* : <button type='submit ' disabled={err} onClick={()=>{handleUpdate(values)}} className='cmn_btn ms-2'  >
                                            Update
                                        </button> } */}
                    {/* {!props.campaign_detail  ? <button type='submit' className='cmn_btn ms-2' disabled={err}  >save
                                        </button> : <button type='button' onClick={() => {handleUpdate(values)}} className='cmn_btn ms-2' disabled={err} >Update</button>} */}
                    <button
                      type="submit"
                      className="cmn_btn ms-2"
                      disabled={err}
                    >
                      {!props.campaign_detail ? "Save" : "Update"}{" "}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewCompaignSaas;
