import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const GetLanguages = createAsyncThunk(
  'users/GetLanguages',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}textresource/getlanguages`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)
export const GetRoles = createAsyncThunk(
  'users/GetRoles',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}user/roles/get`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)
export const GetGroups = createAsyncThunk(
  'users/GetGroups',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}user/groups/get`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)
export const GetAllCategories = createAsyncThunk(
  'users/GetAllCategories',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}permissions/getallcategories`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)


export const GetPermissionsBySubgroup = createAsyncThunk(
  'users/GetPermissionsBySubgroup',
  async ({ token, subgroupId }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}permissions/getpermissionsbysubgroup/${subgroupId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)


export const GetSubGroups = createAsyncThunk(
  'users/GetSubGroups',
  async ({ token, groupId }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}permissions/getsubgroups/${groupId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const GetUser = createAsyncThunk(
  'users/GetUser',
  async ({ token, userId }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}user/getbyid/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const SaveUser = createAsyncThunk(
  'users/Saveuser',
  async (values, thunkAPI) => {
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}user/save`, values, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + values.token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        console.log(e)
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const SaveSubGroup = createAsyncThunk(
  'permission/SaveSubGroup',
  async (values, thunkAPI) => {
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}permissions/savesubgroup`, values, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + values.token
        }
      }).then((response) => {
        return response.data;
      }).catch((e) => {
        console.log(e)
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }

        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)




export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    languages: [],
    roles: [],
    groups: [],
    subgroups: [],
    allcategories: [],
    userData: {},
    permissions_by_subgroup: [],
    isFetchingGetLanguages: false,
    isSuccessGetLanguages: false,
    isErrorGetLanguages: false,
    isFetchingGetRoles: false,
    isSuccessGetRoles: false,
    isErrorGetRoles: false,
    isFetchingGetGroups: false,
    isSuccessGetGroups: false,
    isErrorGetGroups: false,
    isFetchingGetAllCategories: false,
    isSuccessGetAllCategories: false,
    isErrorGetAllCategories: false,
    isFetchingGetSubGroups: false,
    isSuccessGetSubGroups: false,
    isErrorGetSubGroups: false,
    isFetchingSaveUser: false,
    isSuccessSaveUser: false,
    isErrorSaveUser: false,
    isFetchingGetUser: false,
    isSuccessGetUser: false,
    isErrorGetUser: false,
    isFetchingGetPermissionsBySubgroup: false,
    isSuccessGetPermissionsBySubgroup: false,
    isErrorGetPermissionsBySubgroup: false,
    isFetchingSaveSubGroup: false,
    isSuccessSaveSubGroup: false,
    isErrorSaveSubGroup: false,
    error: "",
    successmessage: ""
  },
  reducers: {
    clearGetlanguagesState: (state) => {
      state.isFetchingGetLanguages = false;
      state.isSuccessGetLanguages = false;
      state.isErrorGetLanguages = false;
      return state;
    },
    clearGetroles: (state) => {
      state.isErrorGetRoles = false;
      state.isSuccessGetRoles = false;
      state.isFetchingGetRoles = false;
    },
    clearGetGroups: (state) => {
      state.isErrorGetGroups = false;
      state.isSuccessGetGroups = false;
      state.isFetchingGetGroups = false;
    },
    clearGetAllCategories: (state) => {
      state.isErrorGetAllCategories = false;
      state.isSuccessGetAllCategories = false;
      state.isFetchingGetAllCategories = false;
    },
    clearGetSubGroups: (state) => {
      state.isErrorGetSubGroups = false;
      state.isSuccessGetSubGroups = false;
      state.isFetchingGetSubGroups = false;
    },
    clearSaveUser: (state) => {
      state.isErrorSaveUser = false;
      state.isSuccessSaveUser = false;
      state.isFetchingSaveUser = false;
      state.successmessage = "";
      state.error = "";
    },
    clearGetUser: (state) => {
      state.isErrorGetUser = false;
      state.isSuccessGetUser = false;
      state.isFetchingGetUser = false;
      state.successmessage = "";
      state.error = "";
    },
    clearGetPermissionsBySubgroup: (state) => {
      state.isErrorGetPermissionsBySubgroup = false;
      state.isSuccessGetPermissionsBySubgroup = false;
      state.isFetchingGetPermissionsBySubgroup = false;
      state.successmessage = "";
      state.error = "";
    },
    clearSaveSubGroup: (state) => {
      state.isErrorSaveSubGroup = false;
      state.isSuccessSaveSubGroup = false;
      state.isFetchingSaveSubGroup = false;
      state.successmessage = "";
      state.error = "";
    },


  },
  extraReducers: {

    [GetLanguages.fulfilled]: (state, { payload }) => {
      state.languages = payload.data;
      state.isFetchingGetLanguages = false;
      state.isSuccessGetLanguages = true;
      state.isErrorGetLanguages = false;
    },
    [GetLanguages.rejected]: (state, { payload }) => {
      state.isFetchingGetLanguages = false;
      state.isErrorGetLanguages = true;
      state.error = payload
    },
    [GetLanguages.pending]: (state) => {
      state.isFetchingGetLanguages = true
    },
    [GetRoles.fulfilled]: (state, { payload }) => {
      state.roles = payload.data;
      state.isFetchingGetRoles = false;
      state.isSuccessGetRoles = true;
      state.isErrorGetRoles = false;
    },
    [GetRoles.rejected]: (state, { payload }) => {
      state.isFetchingGetRoles = false;
      state.isErrorGetRoles = true;
      state.error = payload
    },
    [GetRoles.pending]: (state) => {
      state.isFetchingGetRoles = true;
    },
    [GetGroups.fulfilled]: (state, { payload }) => {
      state.groups = payload.data;
      state.isFetchingGetGroups = false;
      state.isSuccessGetGroups = true;
      state.isErrorGetGroups = false;
    },
    [GetGroups.rejected]: (state, { payload }) => {
      state.isFetchingGetGroups = false;
      state.isErrorGetGroups = true;
      state.error = payload
    },
    [GetGroups.pending]: (state) => {
      state.isFetchingGetGroups = true;
    },
    [GetAllCategories.fulfilled]: (state, { payload }) => {
      state.allcategories = payload.data;
      state.isFetchingGetAllCategories = false;
      state.isSuccessGetAllCategories = true;
      state.isErrorGetAllCategories = false;
    },
    [GetAllCategories.rejected]: (state, { payload }) => {
      state.isFetchingGetAllCategories = false;
      state.isErrorGetAllCategories = true;
      state.error = payload
    },
    [GetAllCategories.pending]: (state) => {
      state.isFetchingGetAllCategories = true;
    },
    [GetSubGroups.fulfilled]: (state, { payload }) => {
      state.subgroups = payload.data;
      state.isFetchingGetSubGroups = false;
      state.isSuccessGetSubGroups = true;
      state.isErrorGetSubGroups = false;
    },
    [GetSubGroups.rejected]: (state, { payload }) => {
      state.isFetchingGetSubGroups = false;
      state.isErrorGetSubGroups = true;
      state.error = payload
    },
    [GetSubGroups.pending]: (state) => {
      state.isFetchingGetSubGroups = true;
    },
    [SaveUser.fulfilled]: (state, { payload }) => {
      state.successmessage = payload.data
      state.isFetchingSaveUser = false;
      state.isSuccessSaveUser = true;
      state.isErrorSaveUser = false;
    },
    [SaveUser.rejected]: (state, { payload }) => {
      state.isFetchingSaveUser = false;
      state.isErrorSaveUser = true;
      state.error = payload
    },
    [SaveUser.pending]: (state) => {
      state.isFetchingSaveUser = true;
    },
    [GetUser.fulfilled]: (state, { payload }) => {
      state.userData = payload.data
      state.isFetchingGetUser = false;
      state.isSuccessGetUser = true;
      state.isErrorGetUser = false;
    },
    [GetUser.rejected]: (state, { payload }) => {
      state.isFetchingGetUser = false;
      state.isErrorGetUser = true;
      state.error = payload
    },
    [GetUser.pending]: (state) => {
      state.isFetchingGetUser = true;
    },
    [GetPermissionsBySubgroup.fulfilled]: (state, { payload }) => {
      state.permissions_by_subgroup = payload.data
      state.isFetchingGetPermissionsBySubgroup = false;
      state.isSuccessGetPermissionsBySubgroup = true;
      state.isErrorGetPermissionsBySubgroup = false;
    },
    [GetPermissionsBySubgroup.rejected]: (state, { payload }) => {
      state.isFetchingGetPermissionsBySubgroup = false;
      state.isErrorGetPermissionsBySubgroup = true;
      state.error = payload
    },
    [GetPermissionsBySubgroup.pending]: (state) => {
      state.isFetchingGetPermissionsBySubgroup = true;
    },
    [SaveSubGroup.fulfilled]: (state, { payload }) => {
      state.isFetchingSaveSubGroup = false;
      state.isSuccessSaveSubGroup = true;
      state.isErrorSaveSubGroup = false;
    },
    [SaveSubGroup.rejected]: (state, { payload }) => {
      state.isFetchingSaveSubGroup = false;
      state.isErrorSaveSubGroup = true;
      state.error = payload
    },
    [SaveSubGroup.pending]: (state) => {
      state.isFetchingSaveSubGroup = true;
    }
  }
});

export const { clearGetlanguagesState, clearGetroles,
  clearGetGroups, clearGetAllCategories,
  clearGetSubGroups, clearSaveUser,
  clearGetUser, clearGetPermissionsBySubgroup,
  clearSaveSubGroup } = UserSlice.actions;
export const userSelector = (state) => state.user;