import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from "../Loader";
import { validateToken } from '../../app/features/Auth/validateTokenSlice';
import { changeNewUserPassword } from '../../app/features/Auth/chnageNewUserPasswordSlice';
import { clear_password_reset_state } from '../../app/features/Auth/chnageNewUserPasswordSlice';
export const ChangeUserPassword = () => {
    const params = useParams()
    const token = params.token;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => { return state.validate_user_token })
    const password_data = useSelector((state) => { return state.new_user_password_change })
    console.log(password_data, "this is the data that is come after")
    const [new_password, setNew_password] = useState("")
    const [loadershown, setloadershown] = useState(false);
    const [noToken, setNoToken] = useState(false)
    const [notValidToken, setNotValidToken] = useState(false)
    const [confirm_password, setConfirm_password] = useState("")
    const permission = JSON.parse(localStorage.getItem('userdata'));
    const userId = permission?.id;

    useEffect(() => {
        if (!token) {
            setNoToken(true)
        }
        else {
            dispatch(validateToken({ token }))
            setNoToken(false)
        }
    }, [])

    useEffect(() => {
        if (!data?.data?.data) {
            setNotValidToken(true)
        } else {
            setNotValidToken(false)
        }
        if(data?.loading){
            setloadershown(true)
        }
        else {
            setloadershown(false)
        }
    }, [data])

    const handleChangePassword = (e) => {
        e.preventDefault()
        if (new_password !== "" && confirm_password !== "") {
            if (new_password !== confirm_password) {
                toast.error("Password Doesn't Matched !!")
            } else {
                dispatch(changeNewUserPassword({ new_password, token }))
            }
        }
        else {
            toast.error("All Fields Are Required !!")
        }
    }

    useEffect(() => {
        if (password_data?.loading) {
            setloadershown(true)
        }
        else {
            setloadershown(false)
        }

        if (password_data?.data?.data) {
            toast.success("Password Reset Successfully !!")
            dispatch(clear_password_reset_state())
            navigate("/")
        }
    }, [password_data])



    return (
        <>
            <div className="sufee-login d-flex align-content-center flex-wrap">
                <Loader showLoader={loadershown} />
                <div className="container">
                    <div className="login-content">
                        <div className="log_in_form">
                            <div className="login-logo"><a href="index.html"><img src="/assets/images/mariano.png" className="" /></a></div>
                            <div className="login-form">
                                {notValidToken ? <h1>Invalid Token</h1> :
                                    <form className="">
                                        <div className="mb-3"><input name="password" placeholder="New password" type="password" id="exampleForm.ControlInput1" className="form-control" value={new_password} onChange={(e) => setNew_password(e.target.value)} /></div>
                                        <div className="mb-3"><input name="password" placeholder="Password" type="password" id="exampleForm.ControlTextarea1" className="form-control" value={confirm_password} onChange={(e) => setConfirm_password(e.target.value)} /></div>
                                        <button type="submit" className="mt-5 btn btn-primary" onClick={(e) => handleChangePassword(e)}>Update Password</button>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangeUserPassword