import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./campaign.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Edit_campaign from "./Edit_campaign";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import {
  AiOutlineSearch, AiOutlinePlus
} from "react-icons/ai";
import {
  FaChevronRight
} from "react-icons/fa";
import Loader from "../Loader";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import { toast } from "react-hot-toast";
import Moment from "react-moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ExchangeRates,
  deligateSelector,
} from "../../app/features/CampaignManager/DelegateSlice";
import { FiAlertCircle } from 'react-icons/fi';
import Spinner from 'react-bootstrap/Spinner';

const MediaplanSaas = () => {
  const dispatch = useDispatch();
  const { dealCurrencies } = useSelector(deligateSelector);

  const location = useLocation();
  const [show, setShow] = useState(false);
  const [editCampaign, setShowEditCampaign] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showClient, setShowClient] = useState(false)
  const navigate = useNavigate()
  const handleCloseClient = () => setShowClient(false);
  const handleShowClient = () => setShowClient(true);
  const handleCloseEditCampaign = () => setShowEditCampaign(false);
  const handleShowEditCampaign = () => setShowEditCampaign(true);
  const [clientEmail, setClientEmail] = useState("")
  const [clientName, setClientName] = useState("")
  const [loadershown, setloadershown] = useState(false);
  const [advertise_data, setAdvertise_data] = useState([])
  const [campaign_data, setCampaign_data] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total_pages, setTotal_Pages] = useState(null)
  const [campaign_name, setCampaign_Name] = useState("")
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [campaign_budget, setCampaign_budget] = useState(null)
  const [selected_campaign_id, setSelected_campaign_id] = useState(null)
  const [deal_currency, setDeal_currency] = useState('')
  const [campaign_budget_usd, setCampaign_budget_usd] = useState(null)
  const [active_button, setActive_button] = useState(false)
  const [campaign_active_id, setCampaign_active_id] = useState(null)
  const [campaign_active_status_id, setCampaign_active_status_id] = useState(null)
  const [showCampaignStatus, setShowCampaignStatus] = useState(false);
  const [campaign_id, setCampaign_id] = useState(null)
  const [campaign_searched_name, setCampaign_searched_name] = useState("")
  const [campaign_status_id, setCampaign_status_id] = useState(null)
  const [campaign_start_date, setCampaign_start_date] = useState("")
  const [campaign_end_date, setCampaign_end_date] = useState("")
  const [campaign_name_list, setCampaign_name_list] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [show_div, setShow_div] = useState(false)
  const [searched_value, setSearched_value] = useState("")
  const [search, setSearch] = useState(true)
  const pickerref = useRef(null);
  const [blue_tick, setBlue_tick] = useState(false)
  const [all_user_list, setAll_user_list] = useState([])
  const [owner_email_id, setOwner_email_id] = useState(null)
  const [owner_email, setOwner_email] = useState("Select Owner")
  const [statusText, setStatusText] = useState("")
  const [sort, setSort] = useState(false)
  const [campaign_status, setCampaign_status] = useState(null)

  const handleCloseCampaignStatus = () => setShowCampaignStatus(false);
  const handleShowCampaignStatus = () => setShowCampaignStatus(true);
  //this below variable is used to get the advertiser_id from the localstorage and we used this in get all clients endpoint and for all permissions nitin 20/09/2023
  const permission = JSON.parse(localStorage.getItem('userdata'));

  const {
    //here in this path we get the client id from the prvious page and here we can hit a endpoint to get all the information of selected client nitin 31/08/2023
    client_id, company_name, email, accessReport, country, country_id, deal_status_id, advertiser_id, currency,client_deal_id
  } = location.state ? location.state : location;

  //setting the above values in a state to update the current client nitin 31/08/2023


  //this function is used to update the client information
  const handleUpdateClient = () => {
    //implementing some validations to manage empty values 
    if (clientName === "") {
      toast.error("username can't be empty")
    }
    else if (clientEmail === "") {
      toast.error("email can't be empty")
    }
    else {
      setloadershown(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "id": client_id,
        "company_name": clientName,
        "access_report": accessReport,
        "email": clientEmail,
        "country_id": country_id
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/advertiserClient/update`, requestOptions)
        .then(response => response.text())
        .then(result => { toast.success("client updated successfully"); handleCloseClient(); get_Advertiser_detail(); get_all_campaigns() })
        .catch(error => console.log('error', error));
    }
  }

  //this function is used to get the data of selected advertiser nitin 04/09/2023
  useEffect(() => {
    get_Advertiser_detail();
    //this below function will call to get all the campaigns nitin 05/09/2023
    get_all_campaigns();
  }, []);

  const get_Advertiser_detail = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET', // Corrected to 'GET'
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/advertiserClient/get/${client_id}`, requestOptions)
      .then(response => response.json())
      .then(result => { setAdvertise_data(result?.data) })
      .catch(error => toast.error('Internal server error occurred !!'));
  }

  useEffect(() => {
    if (advertise_data) {
      setClientEmail(advertise_data?.email);
      setClientName(advertise_data?.company_name)
    }
  }, [advertise_data])

  //this below function is used to get all the campaigns based on the advertisers nitin 05/09/2023  updated on 15/09/2023
  useEffect(() => {
    if (page !== 1 || pageSize !== 10) {
      get_all_campaigns()
    }
  }, [page, pageSize])

  // this will only run when we have these values that are in get all campaign function nitin 21/09/2023
  useEffect(() => {
    get_all_campaigns()
  }, [campaign_id, campaign_searched_name, campaign_status_id, campaign_start_date, campaign_end_date])

  const get_all_campaigns = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "campaignId": campaign_id ? campaign_id : null,
      "kite_advertiser_client_id": client_id,
      "statusId": campaign_status_id ? campaign_status_id : null,
      "date_from": campaign_start_date ? campaign_start_date : null,
      "date_to": campaign_end_date ? campaign_end_date : null,
      "name": campaign_searched_name ? campaign_searched_name : null
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaignsAdvertiserClient/${page}/${pageSize}`, requestOptions)
      .then(response => response.json())
      .then(result => { setCampaign_data(result?.data?.results); setloadershown(false); setTotal_Pages(result?.data?.page_count) })
      .catch(error => toast.error('Internal Server Error Occurred !!'));
  }

  function dateFormatConverter(dateToFormat) {
    const formatDate = new Date(dateToFormat);
    const year = formatDate.getFullYear();
    const month = (formatDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formatDate.getDate().toString().padStart(2, '0');
    return year + "/" + month + "/" + day;
  }

  //this below function is used to add new campaign basically it is saving the new data in campaigns nitin 05/09/2023
  const handle_save_campaigns = () => {
    //adding some validations to stop entering null values nitin 05/09/2023
    if (campaign_name === "") {
      toast.error("Campaign name can't be empty")
    } else if (startDate === "") {
      toast.error("Please select Start date")
    } else if (endDate === "") {
      toast.error("Please select End date")
    } else if (campaign_budget === null) {
      toast.error("Budget field can't be empty")
    } else if (endDate < startDate) {
      toast.error("End date can't be lower than start date")
    } else {
      setloadershown(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "name": campaign_name,
        "date_from": dateFormatConverter(startDate),
        "kite_deal_id":client_deal_id ? client_deal_id : advertise_data?.kite_deal_id,
        "date_to": dateFormatConverter(endDate),
        "budge": campaign_budget,
        "budget_usd": campaign_budget_usd,
        "statusId": 2,
        "kite_advertiser_client_id": client_id
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/addCampaignAdvertiserClient`, requestOptions)
        .then(response => response.json())
        .then(result => { toast.success("New campaign saved successfully"); get_all_campaigns(); handleClose(); setCampaign_Name(""); setStartDate(""); setEndDate(""); setCampaign_budget(null); get_Advertiser_detail(); setCampaign_budget_usd(null) })
        .catch(error => { toast.error('Some Internal Error Occurred!!'); setloadershown(false) });
    }
  }

  //this function is used for navigation we go to next page lineitems from here 05/09/2023
  const go_to_next = (id, status_id) => {
    navigate('/campaignsaas/LineItemsSaas', {
      state: {
        campaign_id: id,
        client_id: client_id,
        status_id: status_id,
        deal_currency: deal_currency,
        deal_status_id: deal_status_id,
        advertiser_id: advertiser_id,
        currency: currency,
        campaign_status: campaign_status,
        client_deal_id : client_deal_id,
        dealId : advertise_data?.deal_id
      }
    })
  }

  //this below function is used to update the status of campaign nitin 08/09/2023
  const update_campaign_status = () => {
    handleCloseCampaignStatus()
    setloadershown(true)


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": campaign_active_id,
      "status_id": campaign_active_status_id
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/update-campaign-status`, requestOptions)
      .then(response => response.json())
      .then(result => { get_all_campaigns(); toast.success("Status Updated Successfully") })
      .catch(error => toast.error("Internal Error Occurred While Updating the status"));
  }

  //this below function is used to set the id of campaign edit selected field nitin 15/09/2023
  const handle_set_campaign_id = (id) => {
    handleShowEditCampaign()
    setSelected_campaign_id(id)
  }

  // this below endpoint is used to get all the dealCurrency nitin 19/09/2023
  useEffect(() => {
    if (advertise_data?.kite_deal_id) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/mp-currency/${advertise_data?.deal_id}`, requestOptions)
        .then(response => response.json())
        .then(result => { setDeal_currency(result?.data) })
        .catch(error => console.log('error', error));
    }
  }, [advertise_data?.kite_deal_id])

  // this below function is used to get all the deal curriences values nitin 19/09/2023
  useEffect(() => {
    if (deal_currency != "") {
      dispatch(ExchangeRates({ dealCurrency: deal_currency }));
    }
  }, [deal_currency]);

  // this below function is used to calculate the budget usd nitin 19/09/2023
  const calculate_budget_usd = (e) => {
    let value = dealCurrencies['USD'] * e.target.value;
    if (value) {
      setCampaign_budget_usd(value)
    }
  }

  // this below function is used to enable the save button nitin 19/09/2023
  useEffect(() => {
    if (campaign_name !== "" && startDate !== "" && endDate !== "" && campaign_budget !== "") {
      setActive_button(true)
    } else if (campaign_name === "" || startDate === "" || endDate === "" || campaign_budget === "") {
      setActive_button(false)
    }
  }, [campaign_name, startDate, endDate, campaign_budget])

  const show_campaign_status = (id, status_id, campaign_status) => {
    if (status_id == 2) {
      setStatusText(" Are you sure you want to Active this campaign ?");
    }
    if (status_id == 28) {
      setStatusText("  Are you sure you want to put as Inactive this campaign ?");
    }
    if (status_id == 1) {
      setStatusText(" Are you sure you want to put as Draft ?");
    }
    if (status_id == 3) {
      setStatusText("Are you sure you want to Pause this campaign ?");
    }
    if (campaign_status == 1 && status_id == 1) {
      toast.error("You cannot change the status to draft again !!")
    }
    else if (campaign_status == 1 && (status_id == 3 || status_id == 4)) {
      toast.error("You can only change the status to Active !!")
    }
    else if (campaign_status == 2 && (status_id == 1 || status_id == 2)) {
      toast.error("You can only change the status of campaign Pause and Inactive !!")
    }
    else if (campaign_status == 3 && (status_id == 1 || status_id == 3)) {
      toast.error("You can only change the status of campaign Active and Inactive !!")
    }
    else
      handleShowCampaignStatus()
    setCampaign_active_id(id)
    setCampaign_active_status_id(status_id)
  }

  const handleApply = (event, picker) => {
    setCampaign_start_date(picker.startDate.format("YYYY-MM-DD"))
    setCampaign_end_date(picker.endDate.format("YYYY-MM-DD"))
    setSearch(true)
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };


  const handle_search_with_id = (e) => {
    if (/[a-zA-Z]/.test(e.target.value)) {
    } else {
      setCampaign_id(e.target.value)
      setSearch(true)
    }

  }

  const handle_search_with_name = (e) => {
    if (/[a-zA-Z]/.test(e.target.value)) {
      setSpinner(true)
      setShow_div(true)
      setSearch(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "kite_advertiser_client_id": client_id,
        "name": e.target.value
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/filter-campaign`, requestOptions)
        .then(response => response.json())
        .then(result => { setSpinner(false); setCampaign_name_list(result?.data) })
        .catch(error => console.log('error', error));
    }
  }

  useEffect(() => {
    if (campaign_name !== "" && startDate !== "" && endDate !== "" && campaign_budget !== null) {
      setBlue_tick(true)
    }
  }, [campaign_name, startDate, endDate, campaign_budget])

  useEffect(() => {
    get_all_users()
  }, [])

  const get_all_users = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'))
    var raw = JSON.stringify({
      "advertiser_id": advertiser_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getuserEmail`, requestOptions)
      .then(response => response.json())
      .then(result => { setAll_user_list(result?.data) })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (owner_email_id !== null) {
      handle_save_owner()
    }
  }, [owner_email_id])

  const handle_save_owner = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "userid": owner_email_id,
      "id": client_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/saveuserEmail`, requestOptions)
      .then(response => response.text())
      .then(result => { toast.success("Owner assigned successfully") })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": client_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/reflectedUseremail`, requestOptions)
      .then(response => response.json())
      .then(result => setOwner_email(result?.data[0]?.email))
      .catch(error => console.log('error', error));
  }, [])

  // this below function is used to sort the data based on the fields nitin 11/10/2023
  const handle_sort = (field_name) => {
    setSort(!sort)
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "campaignId": campaign_id ? campaign_id : null,
      "kite_advertiser_client_id": client_id,
      "statusId": campaign_status_id ? campaign_status_id : null,
      "date_from": campaign_start_date ? campaign_start_date : null,
      "date_to": campaign_end_date ? campaign_end_date : null,
      "name": campaign_searched_name ? campaign_searched_name : null,
      "FieldName": field_name,
      "Order": sort ? "desc" : "asc"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaignsAdvertiserClient/${page}/${pageSize}`, requestOptions)
      .then(response => response.json())
      .then(result => { setCampaign_data(result?.data?.results); setloadershown(false); setTotal_Pages(result?.data?.page_count) })
      .catch(error => toast.error('Internal Server Error Occurred !!'));

  }

  const handle_get_lineitem_bid_usd_budget_usd = () => {
    if (campaign_budget) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "deal_id": advertise_data?.deal_id,
        "inversion_budget": campaign_budget,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/calculatebudgetusd`, requestOptions)
        .then(response => response.json())
        .then(result => {setCampaign_budget_usd(result?.data?.inversion_goal_usd)})
        .catch(error => console.log('error', error));
    }
  }

  return (
    <div>
      <div className="content_outer">
        <Loader showLoader={loadershown} />

        <div className="cmn_header_top ">
          <h3>
            <img src="/assets/images/chevron_left.svg" onClick={() => navigate('/campaignsaas', {
              state: {
                status_id: deal_status_id,
                client_id: advertiser_id,
                currency: currency,
                check_calue : true,
                deal_currency: deal_currency,
                path: (permission?.Permissions?.[25] ? '/adminSaas' : undefined)
              }
            })} />
            Campaign Manager Saas</h3>
          <div className="flex-grow-1 text-center">
            <div className="cmn_header__search position-relative">
              <AiOutlineSearch size={24} />
              <input type="text" value={searched_value} placeholder="Search by Id or Advertiser" onBlur={(e) => handle_search_with_id(e)} onChange={(e) => { handle_search_with_name(e); setSearched_value(e.target.value) }} onClick={() => { setShow_div(false); setCampaign_name_list([]) }} />
              {show_div && <div className="search_dropown">
                {spinner &&
                  <div className="p-3 text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>

                  </div>
                }
                <ul>
                  {
                    campaign_name_list?.map((data, index) => {
                      return (
                        <li key={index} onClick={() => { setCampaign_searched_name(data?.name); setShow_div(false) }}>{data?.name}</li>
                      )
                    })
                  }
                </ul>
              </div>}
            </div>
          </div>
          {(deal_status_id == 1 || deal_status_id == 2) && (permission?.Permissions[13]) &&
            <div className="">
              <button onClick={handleShow} className="cmn_btn ms-0"> <AiOutlinePlus className="me-2" size={24} />Add new campaign</button></div>
          }
        </div>
        <div className="content pt-0">
          <div className="App">
            <div className="mediaplan_details kite_space_cmn saas_info">
              <div className="title__active"><h3>{advertise_data?.company_name}</h3></div>
              <Row>
                <Col lg={8}>
                  <div className="advert_tabel">
                    <div className="media_plan_info_list">
                      <ul className="align-items-center">
                        <li>
                          <strong>ID</strong>
                          <p>{advertise_data?.id}</p>
                        </li>
                        <li>
                          <strong>Company</strong>
                          <p>{advertise_data?.company_name}</p>
                        </li>
                        <li>
                          <strong>Country</strong>
                          <p>{advertise_data?.country?.value}</p>
                        </li>
                        <li>
                          <strong>E-mail</strong>
                          <p>{advertise_data?.email}</p>
                        </li>
                        <li>
                          {/* <strong>Access report</strong> */}
                          <strong>Access report</strong>

                          <p>{advertise_data?.access_report ? "On" : "Off"}</p>
                        </li>
                        <li>
                          {(deal_status_id == 1 || deal_status_id == 2) && (permission?.Permissions[13]) && <div className="pdf_icon " title="Edit Client" style={{ cursor: "pointer" }} onClick={() => { handleShowClient() }}><img src="/assets/images/edit_fill.svg" /></div>}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg={2}>
                  {/* <ul className="budget">
                    <li>
                      Budget
                    </li>
                    <li className="progress_result w-100">
                      <div className="w-100">
                        <ProgressBar className="bar_color w-100" now={0} />
                      </div>
                      <p>Assigned: <strong>{advertise_data?.budgetCount?.toLocaleString()}</strong></p>
                    </li>

                  </ul> */}
                  <ul className="budget buget_wraper">
                    <li>
                      <strong>Budget Assigned({currency ? currency : deal_currency}) :</strong>
                      <p></p>
                      <p>{currency == "USD" || deal_currency == "USD" ? "$" + advertise_data?.budgetCount?.toLocaleString() : advertise_data?.budgetCount?.toLocaleString()}</p>
                    </li>
                  </ul>
                </Col>
                <Col lg={2}>
                  <ul className="budget buget_wraper">
                    <li className="w-100">
                      <strong>Owners</strong>
                      <Dropdown className="owner_filter owners_drop_down flex-grow-1">
                        {/* first */}
                        <Dropdown.Toggle id="dropdown-basic" className="w-100">
                          {owner_email}
                          <svg className="ms-auto" width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                            <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {all_user_list?.map((data, index) => {
                            return (
                              <Dropdown.Item key={index} onClick={() => { setOwner_email_id(data?.userid); setOwner_email(data?.email) }}>{data?.email}</Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div className="cmn__filters campiagn_filter filter_menu w-100">
              <ul className="w-100">
                <li><img src="/assets/images/filter_list.svg" /></li>
                <li>
                  <Dropdown className="owner_filter">
                    {/* first */}
                    <Dropdown.Toggle id="dropdown-basic">
                      Status
                      <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                        <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => { setCampaign_status_id(2); setSearch(true) }}>Active</Dropdown.Item>
                      <Dropdown.Item onClick={() => { setCampaign_status_id(1); setSearch(true) }}>Draft</Dropdown.Item>
                      <Dropdown.Item onClick={() => { setCampaign_status_id(4); setSearch(true) }}>Inactive</Dropdown.Item>
                      <Dropdown.Item onClick={() => { setCampaign_status_id(3); setSearch(true) }}>Pause</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                {/* <li>
                  <Form.Group className=" owner_filter mt-0 round_select" controlId="formBasicEmail">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        showDropdowns: true,
                      }}
                      onApply={handleApply}
                      onCancel={handleCancel}
                      ref={pickerref}
                    >
                      <input placeholder="yyyy/mm/dd" value={campaign_start_date && campaign_end_date ? campaign_start_date + " " + campaign_end_date : "Start Date"} className="form-control" />
                    </DateRangePicker>

                  </Form.Group>

                </li> */}
                <button
                  className="clear__btn"
                  onClick={() => { setCampaign_searched_name(""); setCampaign_start_date(""); setCampaign_end_date(""); setCampaign_status_id(null); setCampaign_id(null); setSearched_value(""); setSearch(false) }}
                >
                  Clear
                </button>

              </ul>
            </div>
            {/* filter ends */}
            <div className="advert_tabel campign_index_table saas_mediaplan_table">
              <Table
                responsive
              >
                <thead>
                  <tr style={{ cursor: "pointer" }}>
                    <th className="text-start" onClick={() => handle_sort("id")}>Campaign ID <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start" onClick={() => handle_sort("name")}>Campaign Name <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start" onClick={() => handle_sort("budge")}>Budget in {currency ? currency : deal_currency} <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start" onClick={() => handle_sort("date_from")}>Start Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start" onClick={() => handle_sort("date_to")}>End Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start" onClick={() => handle_sort("status_id")}>Status <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  {campaign_data?.length == 0 && <div className="no_data"><img className="nodata" src="/assets/images/notfound.svg" /></div>}
                  {/* using a map function to setting up the data in table nitin 05/09/2023 */}
                  {campaign_data?.map((campaignData, index) => {
                    return (
                      <tr key={index} >
                        <td onClick={() => go_to_next(campaignData?.id, campaignData?.status_id)}>{campaignData?.id}</td>
                        <td onClick={() => go_to_next(campaignData?.id, campaignData?.status_id)}>{campaignData?.name}</td>
                        <td onClick={() => go_to_next(campaignData?.id, campaignData?.status_id)}>{campaignData?.budge?.toLocaleString()}</td>
                        <td onClick={() => go_to_next(campaignData?.id, campaignData?.status_id)}><Moment format="DD/MM/YYYY">
                          {campaignData?.date_from}
                        </Moment></td>
                        <td onClick={() => go_to_next(campaignData?.id, campaignData?.status_id)}><Moment format="DD/MM/YYYY">
                          {campaignData?.date_to}
                        </Moment></td>
                        <td>
                          <Dropdown className="status_dropdown media_plan_dropdown status_design">
                            <Dropdown.Toggle className={`filter_btn`}>
                              {campaignData?.status_id == 1 && <img src="/assets/images/draft_FILL.svg" />}

                              {campaignData?.status_id == 1 && <span>Draft</span>}
                              {campaignData?.status_id == 3 && <img src="/assets/images/pause_circle.svg" />}

                              {campaignData?.status_id == 3 && <span>Pause</span>}
                              {campaignData?.status_id == 2 && <img src="/assets/images/check_circle.svg" />}

                              {campaignData?.status_id == 2 && <span>Active</span>}
                              {campaignData?.status_id == 28 && <img src="/assets/images/cancel_FILL.svg" />}

                              {campaignData?.status_id == 28 && <span>Inactive</span>}
                              <FaChevronRight className="ms-auto" />
                            </Dropdown.Toggle>

                            {permission?.Permissions[13] && campaignData?.status_id != 28 && <Dropdown.Menu>

                              <Dropdown.Item onClick={() => show_campaign_status(campaignData?.id, 1, campaignData?.status_id)} >
                                <img src="/assets/images/draft_FILL.svg" />
                                Draft
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => show_campaign_status(campaignData?.id, 2, campaignData?.status_id)}>
                                <img src="/assets/images/check_circle.svg" />
                                Active
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => show_campaign_status(campaignData?.id, 3, campaignData?.status_id)}>
                                <img src="/assets/images/pause_circle.svg" />
                                Pause
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => show_campaign_status(campaignData?.id, 28, campaignData?.status_id)}>
                                <img src="/assets/images/cancel_FILL.svg" />
                                Inactive
                              </Dropdown.Item>

                            </Dropdown.Menu>}

                          </Dropdown>
                        </td>


                        <td className="pe-3">
                          <div className="icons_downloads">

                            {(deal_status_id == 1 || deal_status_id == 2) && (permission?.Permissions[13]) && <div className="pdf_icon" title="Edit Campaign" style={{ cursor: "pointer" }}>
                              <img src="/assets/images/edit_fill.svg" onClick={() => { handle_set_campaign_id(campaignData?.id); setCampaign_status(campaignData?.status_id) }} />
                            </div>}
                            <div className="pdf_icon" title="View Line Items" style={{ cursor: "pointer" }} onClick={() => go_to_next(campaignData?.id, campaignData?.status_id)}><img src="/assets/images/description_file.svg" /></div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </Table>
              {campaign_data?.length != 0 && <div className="cmn__pagination">
                <ul>
                  <span>Rows per page</span>
                  <li className="no__rows">
                    <select
                      name=""
                      id=""
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option value={10}>Select</option>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                  </li>
                  <li className="total_pages me-3">
                    <span className="active_pages">{page}</span> of <span>{total_pages}</span>
                  </li>
                  {page !== 1 && <li>
                    <BsChevronBarLeft
                      onClick={() => setPage(1)}
                    />
                  </li>}
                  {page !== 1 && <li>
                    <BsChevronLeft
                      onClick={() => setPage(page - 1)}
                    />
                  </li>}
                  {page !== total_pages && <li
                  >
                    <BsChevronRight
                      onClick={() => setPage(page + 1)}
                    />
                  </li>}
                  {page !== total_pages && <li>
                    <BsChevronBarRight
                      onClick={() => setPage(total_pages)}
                    />
                  </li>}
                </ul>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      {/* add new campaign popup*/}
      <Modal
        show={showCampaignStatus}
        onHide={handleCloseCampaignStatus}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>
            <h4 className="text-center border-bottom pb-5">

              {statusText}
            </h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={handleCloseCampaignStatus}
              >
                Cancel
              </button>
              <button
                className="cmn_btn ms-2 "
                onClick={() => {
                  update_campaign_status()
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show} onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="saas_modal_head">
          <Loader showLoader={loadershown} />
          <Modal.Title id="contained-modal-title-vcenter">
            New Campaign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <Row>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">Campaign Name</label>
                <input className="form-control" type="text" placeholder="Campaign Name*" value={campaign_name} onChange={(e) => setCampaign_Name(e.target.value)} />

              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position" for="campaignName">Start date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  minDate={new Date()}
                  onChange={date =>
                    endDate=='' || date < endDate ?
                    setStartDate(date) :
                    (toast.error("Start date must be before end date"))
                  }
                />
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position" for="campaignName">End date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  minDate={new Date()}
                  onChange={date =>
                    date > startDate ?
                    setEndDate(date) :
                    (toast.error("End date must be after start date"))
                  }
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">Budget({currency ? currency : deal_currency})</label>
                <input className="form-control" type="text" placeholder="Budget" value={campaign_budget} onChange={(e) => { setCampaign_budget(e.target.value) }} onBlur={() => handle_get_lineitem_bid_usd_budget_usd()}/>

              </div>
            </Col>
            <Col lg={6}>
              Budget USD : {campaign_budget_usd && "$"}{campaign_budget_usd?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
            </Col>

          </Row>
          <div className="model_btn">
            <button
              className="cancel_cm_btn paddingCancelButton"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button className={blue_tick ? "cmn_btn" : "cmn_btn cancel_cmn ms-2"}
              //this click function is used to save the new campaign nitin 05/09/2023
              onClick={() => handle_save_campaigns()}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* add new campaign popup */}
      <Modal
        show={showClient} onHide={handleCloseClient}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="">
          <Loader showLoader={loadershown} />
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Client info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <Row>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">Mail</label>
                <input className="form-control" type="email" placeholder="name@mail.com" value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} />

              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">Username</label>
                <input className="form-control" type="text" placeholder="lorem Ipsum" value={clientName} onChange={(e) => setClientName(e.target.value)} />
              </div>
            </Col>
            {/* <Col lg={12}>
              <div className="client_rest">
                <button className="cmn_btn cmn_bordered_btn"><svg className="me-3" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M9.75 15.826c-.862 0-1.7-.163-2.512-.488a6.701 6.701 0 0 1-2.12-1.331l.807-.806c.5.462 1.085.828 1.753 1.097.67.268 1.36.403 2.072.403 1.563 0 2.903-.56 4.022-1.678 1.119-1.12 1.678-2.46 1.678-4.022 0-1.563-.56-2.903-1.678-4.022C12.653 3.86 11.312 3.3 9.75 3.3c-1.562 0-2.903.56-4.022 1.678C4.61 6.098 4.05 7.439 4.05 9v.337l1.313-1.312.787.787-2.662 2.663L.825 8.813l.788-.787 1.312 1.312v-.337c0-.95.178-1.838.535-2.663a6.898 6.898 0 0 1 1.462-2.165A6.898 6.898 0 0 1 7.088 2.71a6.643 6.643 0 0 1 2.662-.534c.95 0 1.838.178 2.663.534a6.898 6.898 0 0 1 2.165 1.463 6.897 6.897 0 0 1 1.463 2.165c.356.825.534 1.713.534 2.663 0 1.9-.662 3.512-1.987 4.837-1.325 1.325-2.938 1.988-4.838 1.988zm-1.5-3.919a.63.63 0 0 1-.46-.197.63.63 0 0 1-.196-.46V9c0-.174.072-.327.216-.459a.763.763 0 0 1 .534-.196V7.5c0-.388.137-.719.412-.994.275-.275.607-.412.994-.412.388 0 .719.137.994.412.275.275.412.606.412.994v.844c.213 0 .391.065.535.196a.604.604 0 0 1 .215.46v2.25a.63.63 0 0 1-.196.46.63.63 0 0 1-.46.196h-3zm.656-3.562h1.688V7.5a.815.815 0 0 0-.244-.6.815.815 0 0 0-.6-.244.815.815 0 0 0-.6.244.815.815 0 0 0-.244.6v.844z" fill="#4759f7" />
                </svg>Reset Password</button>
              </div>
            </Col> */}

          </Row>
          <div className="model_btn">
            <button
              className="cancel_cm_btn"
              onClick={handleCloseClient}
            >
              Cancel
            </button>
            <button className="cmn_btn cancel_cmn ms-2" type="submit"
              // this click function is used to update the client information
              onClick={() => handleUpdateClient()}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* this modal is for edit the campaign nitin 15/09/202 */}
      <Modal
        show={editCampaign} onHide={handleCloseEditCampaign}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="">
          <Loader showLoader={loadershown} />
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Campaign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <Edit_campaign
            selected_campaign_id={selected_campaign_id}
            client_id={client_id}
            handleCloseEditCampaign={handleCloseEditCampaign}
            get_all_campaigns={get_all_campaigns}
            deal_currency={currency ? currency : deal_currency}
            get_Advertiser_detail={get_Advertiser_detail}
            campaign_status={campaign_status}
            client_deal_id = {client_deal_id}
            kite_deal_id = {advertise_data?.kite_deal_id}
          />
        </Modal.Body>
      </Modal>
      {/* edit client popup */}
    </div>
  );
};
export default MediaplanSaas;

