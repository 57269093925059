import React, { useState, useEffect, useRef } from "react";
import "./campaign.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import ProgressBar from 'react-bootstrap/ProgressBar'
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  AiOutlineCheckCircle,
  AiOutlineSearch
} from "react-icons/ai";
import { FiAlertCircle } from 'react-icons/fi';

import { MdOutlineHandshake } from "react-icons/md"
import { BiCalendar } from "react-icons/bi"
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import {
  LineItemsSelector,
  getInventories,
  getLineitemFormats,
  getOfferTypes,
  getCountries,
  getLineItemStatus,
  linefilter,
  getCampaign,
  changeLineItemStatus,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  campaignManagerSelector,
  deal_currency,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import {
  CommonSelector,
  updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";
import ReactPaginate from "react-paginate";
import {
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";

import Modal from "react-bootstrap/Modal";
import NewLineItem from "./newLineItem";
import NewCreatives from "./newCreatives";
import NewCompaign from "./newCompaign";
import DuplicateLineItem from "./duplicateLineitem";
import {
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
} from "rctx-contextmenu";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import { Row, Col } from "react-bootstrap";
import { CChart } from "@coreui/react-chartjs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getDevices } from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  changeCampaignStatus,
} from "../../app/features/CampaignManager/MediaPlanManager";
import EditLineItems from "./editLineItems";
import AddLineItems from "./AddLineItems";
import Spinner from 'react-bootstrap/Spinner';
import { upperCaseFirstLetter, lowerCaseAllWordsExceptFirstLetters } from "../../utils";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
// import Moment from 'react-moment';
const LineItems = () => {
  const [activeTab, setActiveTab] = useState(false);

  const location = useLocation();
  console.log(location, "thius is te location")
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [creativemodalShow, setCreativeModalShow] = useState(false);
  const [modalcampaignShow, setModalCampaignShow] = useState(false);
  const [loadershown, setloadershown] = useState(false);
  const [lineitemarray, setLineitemarray] = useState([]);
  const [lineitem_start_date, setLinelineitem_end_date, setLineitem_end_date] =
    useState("");
  const [page, setPage] = useState(1);
  const [statusmodalShow, setStatusModalShow] = useState(false);
  const [editLineItemModal, setEditLineItemModal] = useState(false)
  const [lineItemId, setLineItemId] = useState(null);
  const [modal_open, setModal_open] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [duplicate_modal, setDuplicate_modal] = useState(false);
  const [menu_item, setMenu_item] = useState(true);
  const [optimizeData, setOptimizeData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [asc, setAsc] = useState(false);
  const [status_modal, setStatus_Modal] = useState(false)
  const [campaignSearch, setCampaignSearch] = useState("")
  const [searchCampaignId, setSearchCampaignId] = useState("")
  const [filteredLineData, setFilteredLineData] = useState([]);
  const [pageNo, setPageNo] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [page_Count, setPage_count] = useState(0)
  const [current_Page, setCurrent_Page] = useState(1)
  const [inventoryId, setInventoryId] = useState()
  const [formatId, setFormatId] = useState()
  const [offerId, setOfferId] = useState()
  const [countryId, setCountryId] = useState()
  const [statusId, setStatusId] = useState()
  const [inventoryTypeId, setInventoryTypeId] = useState()
  const [formatTypeId, setFormatTypeId] = useState()
  const [offerTypeId, setOfferTypeId] = useState()
  const [countryTypeId, setCountryTypeId] = useState()
  const [statusTypeId, setStatusTypeId] = useState()
  const [reloadResult, setReloadResult] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchSet, setSearch] = useState(false)
  const [tempInventory, setTempInventory] = useState("Inventory")
  const [tempFormat, setTempFormat] = useState("Format")
  const [tempOffer, setTempOffer] = useState("Offer")
  const [tempCountry, setTempCountry] = useState("Country")
  const [tempStatus, setTempStatus] = useState("Status")
  const [startend, setStartend] = useState({ start: "", end: "" });
  const [daterange, setrange] = useState("");
  const pickerref = useRef(null);
  const [id, setId] = useState();
  const [billable, setBillable] = useState(false)
  const [campId, setCampId] = useState()
  const [editId, setEditId] = useState()
  const [editStatus, setEditStatus] = useState("")
  const [statusLineItem, setStatusLineItem] = useState(false)
  const [creativeStatusModal, setCreativeStatusModal] = useState(false)
  const [creativeCount, setCreativeCount] = useState(0)
  const [lineitem_list, setLineitem_list] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [show_div, setShow_div] = useState(false)
  const [pdfTrue, setPdfTrue] = useState(false)
  console.log(pdfTrue,"pdfTrue")
  const [hide_filter,setHide_filter] = useState(true)
  const [total_records,setTotal_records] = useState(null)
  const [statusmodel, SetStatusModel] = useState({
    messageText: "",
    buttonText: "",
    buttonClass: "",
    lineItemId: "",
    lineItemStatus: "",
  });
  const {
    dealid,
    deal_id,
    campaignId,
    advertiser,
    clientId,
    total_budget,
    sum_budget,
    startdate,
    enddate,
    currency_code,
    remainingBudget,
    campaignStatus
  } = location.state ? location.state : location;
  const ID = campaignId
  const {
    filtered_items,
    campaign_detail,
    line_item_status,
    inserted_lineitem_id,
    devices,
    isSuccesschangeLineItemStatus
  } = useSelector(LineItemsSelector);
  const { dealCurrency, deal_detail } = useSelector(campaignManagerSelector);

  const [searchfilter, SetSearchfilter] = useState([]);
  const date = Date.now();
  const [showBillableMenu, setShowBillableMenu] = useState(true)
    


  const [filter, setFilter] = useState({
    inventoryId: {
      name: "Inventory Type",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
    formatId: {
      name: "Format",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },

    offerTypeId: {
      name: "Offer Type",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
    countryId: {
      name: "Country",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
    statusId: {
      name: "Status",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
  });

  const [filtervalue, setFilterValue] = useState({
    inventoryId: "",
    formatId: "",
    offerTypeId: "",
    countryId: "",
    statusId: "",
  });

  const [selectedfilter, SetSelectedfilter] = useState({
    inventoryId: {
      name: "",
      value: "",
      key: "inventoryId",
    },
    formatId: {
      name: "",
      value: "",
      key: "formatId",
    },
    offerTypeId: {
      name: "",
      value: [],
      key: "offerTypeId",
    },
    countryId: {
      name: "",
      value: "",
      key: "countryId",
    },
    statusId: {
      name: "",
      value: "",
      key: "statusId",
    },
  });

  useEffect(() => {

    dispatch(getInventories({ token: localStorage.getItem("token") }))
      .then((result) => {
        setInventoryId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getLineitemFormats({ token: localStorage.getItem("token") }))
      .then((result) => {
        setFormatId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getOfferTypes({ token: localStorage.getItem("token") }))
      .then((result) => {
        setOfferId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getCountries({ token: localStorage.getItem("token") }))
      .then((result) => {
        setCountryId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getLineItemStatus({ token: localStorage.getItem("token") }))
      .then((result) => {
        setStatusId(result?.payload?.data)
      })
      .catch((err) => { });
    getcampain();
  }, []);

  useEffect(() => {
    setFilteredLineData(filtered_items?.results);
  }, [filtered_items?.results]);

  useEffect(() => {
    setBillable(campaign_detail.is_billable)
    setCampId(campaign_detail.id)
  }, [])

  useEffect(() => {
    if (campaign_detail.is_billable === 2 && !(permission?.SubGroupid.includes(5))) {
      setShowBillableMenu(false);
    }
  }, [campaign_detail.is_billable])

  useEffect(() => {
    if (devices.length == 0) {
      dispatch(getDevices({ token: localStorage.getItem("token") }));
    }
  }, []);

  useEffect(() => {
    if (deal_id && dealCurrency == "") {
      dispatch(
        deal_currency({ token: localStorage.getItem("token"), dealId: dealid })
      );
    }
    if (Object.keys(campaign_detail)?.length > 0) {
      dispatch(
        updateBreadCrumb([
          {
            name: advertiser,
            params: { dealid: dealid, deal_id: deal_id },
            path: "campaign/mediaplan",
          },
          {
            name: campaign_detail?.name,
            params: {
              dealid: dealid,
              deal_id: deal_id,
              campaignId: campaignId,
              advertiser: advertiser,
              clientId: clientId,
            },
            path: "campaign/lineitems",
          },
        ])
      );
    }
  }, [deal_id, campaign_detail]);

  const getcampain = () => {
    setloadershown(true)
    dispatch(
      getCampaign({
        token: localStorage.getItem("token"),
        campaignId: campaignId,
      })
    );
    setloadershown(false)
  };

  useEffect(() => {
    onRefresh();
  }, [campaign_detail]);

  const onRefresh = () => {
    return campaign_detail;
  };

  const filter_search = (e) => {
    let filtered_array = [];
    if (e.target.value.length > 2) {
      Object.keys(filter).filter((element, index) => {
        if (element == "inventoryId") {
          filter[element].values.filter((inventory, index) => {
            if (inventory.description) {
              if (
                inventory.description
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: inventory.id,
                    value: inventory.description,
                    name: element,
                  },
                });
              }
            }
          });
        }

        if (element == "formatId") {
          filter[element].values.filter((format, index) => {
            if (format.name) {
              if (
                format.name.toLowerCase().includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: format.id,
                    value: format.name,
                    name: element,
                  },
                });
              }
            }
          });
        }
        if (element == "offerTypeId") {
          filter[element].values.filter((offers, index) => {
            if (offers.descripcion) {
              if (
                offers.descripcion
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: offers.id,
                    value: offers.descripcion,
                    name: element,
                  },
                });
              }
            }
          });
        }
        if (element == "countryId") {
          filter[element].values.filter((country, index) => {
            if (country.value) {
              if (
                country.value
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: country.id,
                    value: country.value,
                    name: element,
                  },
                });
              }
            }
          });
        }
        if (element == "statusId") {
          filter[element].values.filter((status, index) => {
            if (status.description) {
              if (
                status.description
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: status.id,
                    value: status.description,
                    name: element,
                  },
                });
              }
            }
          });
        }
      });
    }
    SetSearchfilter(filtered_array);
  };

  const managefilter = (values) => {
    switch (values.name) {
      case "inventoryId":
        setFilterValue((prev_val) => ({ ...prev_val, inventoryId: values.id }));
        SetSelectedfilter((prev_val) => ({
          ...prev_val,
          inventoryId: {
            name: "Inventory Type",
            value: values.value,
            key: "inventoryId",
          },
        }));
        break;
      case "formatId":
        setFilterValue((prev_val) => ({ ...prev_val, formatId: values.id }));
        SetSelectedfilter((prev_val) => ({
          ...prev_val,
          formatId: {
            name: "Format",
            value: values.value,
            key: "formatId",
          },
        }));
        break;
      case "offerTypeId":
        setFilterValue((prev_val) => ({ ...prev_val, offerTypeId: values.id }));
        SetSelectedfilter((prev_val) => ({
          ...prev_val,
          offerTypeId: {
            name: "Offer Type",
            value: values.value,
            key: "offerTypeId",
          },
        }));
        break;
      case "countryId":
        setFilterValue((prev_val) => ({ ...prev_val, countryId: values.id }));
        SetSelectedfilter((prev_val) => ({
          ...prev_val,
          countryId: {
            name: "Country",
            value: values.value,
            key: "countryId",
          },
        }));
        break;
      case "statusId":
        setFilterValue((prev_val) => ({ ...prev_val, statusId: values.id }));
        SetSelectedfilter((prev_val) => ({
          ...prev_val,
          statusId: {
            name: "Status",
            value: values.value,
            key: "statusId",
          },
        }));
        break;
    }
  };

  useEffect(() => {
    searchFilter();
  }, [page]);

  const searchFilter = () => {
    let posted_array = Object.assign({}, filtervalue);

    Object.keys(posted_array).forEach((key) => {
      if (
        posted_array[key] === "" ||
        (typeof posted_array[key] === "object" && posted_array[key].length == 0)
      ) {
        delete posted_array[key];
      }
    });
    const u = new URLSearchParams(posted_array).toString();
  };

  const billableOptions = [
    {
      id: 0,
      description: 'Not to bill',
    },
    {
      id: 1,
      description: 'Ready to bill',
    },
    {
      id: 2,
      description: 'Billed',
    }
  ]

  const gotonext = (lineItem, campaignId, status_id) => {
    navigation("/campaign/creatives", {
      state: {
        lineItem,
        startDate: campaign_detail?.date_from,
        endDate: campaign_detail?.date_to,
        creativemodalShow,
        campaignId: campaignId,
        advertiser: advertiser,
        campaignName: campaign_detail?.name,
        deal_id: deal_id,
        clientId: clientId,
        total_Budget: campaign_detail?.budge,
        sum_Budget: campaign_detail?.lineItemTotalBudget,
        status_id: status_id,
        dealCurrency: dealCurrency
      },
    });
  };

  const StatusPopup = (id, status, name, creativeCount) => {
    setCreativeCount(creativeCount)
    if (status === 1) {
      setStatusLineItem(true)
    } else {
      if (campaignStatus !== "ACTIVE") {
        switch (name) {
          case "ACTIVE":
            setStatusLineItem(true)
            break;
          case "DRAFT":
            SetStatusModel({
              ...statusmodel, // Copy the old fields
              messageText: "Do you want to confirm the status change for the selected IDs",
              buttonText: "Confirm",
              buttonClass: "cmn_btn ",
              lineItemId: id,
              lineItemStatus: status,
            });
            setStatusModalShow(true);
            break;
          case "INACTIVE":
            SetStatusModel({
              ...statusmodel, // Copy the old fields
              messageText: "Do you want to confirm the status change for the selected IDs",
              buttonText: "Confirm",
              buttonClass: "cmn_btn",
              lineItemId: id,
              lineItemStatus: status,
            });
            setStatusModalShow(true);
            break;
          case "PAUSE":
            SetStatusModel({
              ...statusmodel, // Copy the old fields
              messageText: "Do you want to confirm the status change for the selected IDs",
              buttonText: "Confirm",
              buttonClass: "cmn_btn ms-2 ",
              lineItemId: id,
              lineItemStatus: status,
            });
            setStatusModalShow(true);
            break;
        }

      } else {


        switch (name) {
          case "ACTIVE":
            SetStatusModel({
              ...statusmodel, // Copy the old fields
              messageText: "Do you want to confirm the status change for the selected IDs",
              buttonText: "Confirm",
              buttonClass: "cmn_btn ",
              lineItemId: id,
              lineItemStatus: status,
            });
            setStatusModalShow(true);
            break;
          case "DRAFT":
            SetStatusModel({
              ...statusmodel, // Copy the old fields
              messageText: "Do you want to confirm the status change for the selected IDs",
              buttonText: "Confirm",
              buttonClass: "cmn_btn ",
              lineItemId: id,
              lineItemStatus: status,
            });
            setStatusModalShow(true);
            break;
          case "INACTIVE":
            SetStatusModel({
              ...statusmodel, // Copy the old fields
              messageText: "Do you want to confirm the status change for the selected IDs",
              buttonText: "Confirm",
              buttonClass: "cmn_btn",
              lineItemId: id,
              lineItemStatus: status,
            });
            setStatusModalShow(true);
            break;
          case "PAUSE":
            SetStatusModel({
              ...statusmodel, // Copy the old fields
              messageText: "Do you want to confirm the status change for the selected IDs",
              buttonText: "Confirm",
              buttonClass: "cmn_btn ms-2 ",
              lineItemId: id,
              lineItemStatus: status,
            });
            setStatusModalShow(true);
            break;
        }
      }
    }
  };
  const updateStatus = () => {
    // setloadershown(true);
    dispatch(
      changeLineItemStatus({
        token: localStorage.getItem("token"),
        lineitem_id: statusmodel.lineItemId,
        status_id: statusmodel.lineItemStatus,
      })
    )
      .then((res) => {
        setloadershown(false);
        setStatusModalShow(false);
        getStatusResolved()
        setStatusLineItem(false)
      })

  };

  useEffect(() => {

  })
  const resetstatus = () => {
    SetStatusModel({
      ...statusmodel, // Copy the old fields
      messageText: "",
      buttonText: "",
      buttonClass: "",
      lineItemId: "",
      lineItemStatus: "",
    });
  };
  const reset_filter = () => {
    let posted_array = Object.assign({}, filtervalue);
    Object.keys(posted_array).forEach((key) => {
      if (
        posted_array[key] !== "" ||
        (typeof posted_array[key] === "object" && posted_array[key].length > 0)
      ) {
        if (typeof posted_array[key] === "object") {
          posted_array[key] = [];
          SetSelectedfilter((prev_val) => ({
            ...prev_val,
            [key]: { ...selectedfilter[key], value: [] },
          }));
        } else {
          posted_array[key] = "";
          SetSelectedfilter((prev_val) => ({
            ...prev_val,
            [key]: { ...selectedfilter[key], value: "" },
          }));
        }
      }
    });
    setFilterValue(posted_array);
  };
  const editCampaign = () => {
    setModalCampaignShow(true);
  };

  const handleDuplicate = () => {
    setDuplicate_modal(true);
  };

  const handleCreatives = () => {
    navigation("/campaign-creatives", {
      state: {
        campaignId,
        advertiser,
        start_date: campaign_detail?.date_from,
        end_date: campaign_detail?.date_to,
        product: campaign_detail?.productNavigation?.name,
        currency: currency_code,
        status: campaign_detail?.statusNavigation?.description,
        remaining_budget: parseFloat(
          campaign_detail?.budge - campaign_detail?.lineItemTotalBudget
        ).toFixed(2),
      },
    });
  };

  const handleToast = () => {
    toast.error("Sorry this campaign not have any creatives");
  };

  useEffect(() => {
    if (lineitemarray.length != 0) {
      handleCreativeItems();
    }
  }, []);

  const handleCreativeItems = () => {
    const new_arr = [];
    campaign_detail?.creatives?.map((data) => {
      new_arr.push(...new_arr, data.lineitemId);
      setLineitemarray(new_arr);
    });
  };

  const handleSort = (field) => {
    setAsc(!asc);
    let assc;
    if (asc) {
      assc = "asc";
    } else {
      assc = "desc";
    }

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=1&FieldName=${field}&Order=${assc}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setFilteredLineData(result?.data?.results);
      })
      .catch((error) => toast.error("some internal error occured!!"));
  };

  const percentage = (campaign_detail?.lineItemTotalBudget / campaign_detail?.budge) * 100

  useEffect(() => {
    getPaginateData()
  }, [pageLimit, pageNo])

  const getPaginateData = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=${pageNo}&pageSize=${pageLimit}`, requestOptions)
      .then(response => response.json())
      .then(result => {console.log(result,"this is the result"); setFilteredLineData(result?.data?.results); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page); setloadershown(false);setTotal_records(result?.data?.row_count);!hide_filter && setPdfTrue(true) })
      .catch(error => toast.error('internal server error occured'));
  }

  // useEffect(() => {
  //   getSearchedData()
  // }, [inventoryTypeId, formatTypeId, offerTypeId, statusTypeId, countryTypeId, searchCampaignId, campaignSearch, startend])

  const getSearchedData = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=${pageNo}&pageSize=${pageLimit}`;

    if (statusTypeId) {
      url += `&statusId=${statusTypeId}`;
    }
    if (inventoryTypeId) {
      url += `&inventoryId=${inventoryTypeId}`;
    }
    if (formatTypeId) {
      url += `&formatId=${formatTypeId}`;
    }
    if (offerTypeId) {
      url += `&offerTypeId=${offerTypeId}`;
    }
    if (countryTypeId) {
      url += `&countryId=${countryTypeId}`;
    }
    if (searchCampaignId) {
      url += `&Lineitemid=${searchCampaignId}`;
    }
    if (campaignSearch) {
      url += `&name=${campaignSearch}`;
    }
    if (startend.start !== null) {
      url += `&date_from=${startend.start}`;
    }
    if (startend.end !== null) {
      url += `&date_to=${startend.end}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        setFilteredLineData(result?.data?.results);
        setloadershown(false)

      })
      .catch(error => toast.error('internal server error occurred'));
  }

  const handleIdAndCampaign = (e) => {
    if (/[a-zA-Z]/.test(e.target.value)) {
    } else {
      setSearchCampaignId(e.target.value)
    }
  }

  const handleApply = (event, picker) => {
    setSearch(true)
    picker.element.val(
      picker.startDate.format("DD/MM/YYYY") +
      " - " +
      picker.endDate.format("DD/MM/YYYY")
    );
    setStartend({
      start: picker.startDate.format("YYYY-MM-DD"),
      end: picker.endDate.format("YYYY-MM-DD"),
    });
    setrange(
      picker.startDate.format("YYYY-MM-DD") +
      " - " +
      picker.endDate.format("YYYY-MM-DD")
    );
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const permission = JSON.parse(localStorage.getItem('userdata'));

  const checkBillablePermissons = (selectedValue) => {
    return (
      (permission?.Permissions[23] && campaign_detail?.is_billable == 0 && selectedValue == 1) ||
      (permission?.Permissions[23] && campaign_detail?.is_billable == 1 && selectedValue == 0) ||
      (permission?.SubGroupid.includes(5) && campaign_detail?.is_billable == 1 && selectedValue == 2) ||
      (permission?.SubGroupid.includes(5) && campaign_detail?.is_billable == 2 && selectedValue == 0) ||
      (permission?.SubGroupid.includes(5) && campaign_detail?.is_billable == 2 && selectedValue == 1)
    )
  }

  const handleBillable = (selectedValue) => {
    if (checkBillablePermissons(selectedValue)) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "campaignId": campaignId,
        "is_billable": selectedValue
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/send-billMail`, requestOptions)
        .then(response => response.json())
        .then(result => {
          (campaign_detail?.is_billable == 0 && selectedValue == 1) && toast.success(`Campaign id ${campaignId} is Ready For billing`);
          (campaign_detail?.is_billable == 1 && selectedValue == 2) && toast.success(`Campaign id ${campaignId} is already billed`);
          (campaign_detail?.is_billable == 1 && selectedValue == 0) && toast.success(`Campaign id ${campaignId} is NOT Ready For billing`);
          (campaign_detail?.is_billable == 2 && selectedValue == 0) && toast.success(`Campaign id ${campaignId} is NOT Ready For billing`);
          (campaign_detail?.is_billable == 2 && selectedValue == 1) && toast.success(`Campaign id ${campaignId} is Ready For billing`);
          getcampain()
        })
        .catch(error => toast.error("Some Internal Error occured"));
    } else {
      toast.error('You dont have permissions to make this change');
    }
  }

  const handleEditLineItem = (id, status) => {
    setEditLineItemModal(true)
    setEditId(id)
    setEditStatus(status)
  }

  const handleStatusUpdate = () => {
    dispatch(
      changeCampaignStatus({
        token: localStorage.getItem("token"),
        campaign_id: campaign_detail?.id,
        status_id: 2,
      })
    ).then((result) => {
      getcampain();
      dispatch(
        changeLineItemStatus({
          token: localStorage.getItem("token"),
          lineitem_id: lineItemId,
          status_id: 2,
        })
      )
        .then((res) => {
          setloadershown(false);
          setStatusModalShow(false);
          getStatusResolved()
          setStatusLineItem(false)
          creativeCount !== 0 && setCreativeStatusModal(true)
        })
    });

  }

  const getStatusResolved = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=${pageNo}&pageSize=${pageLimit}`, requestOptions)
      .then(response => response.json())
      .then(result => { setFilteredLineData(result?.data?.results); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page); setloadershown(false) })
      .catch(error => toast.error('internal server error occured'));

  }

  const handleCreativeStatus = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": lineItemId,
      "status_id": 2
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineitemstatus-active`, requestOptions)
      .then(response => response.text())
      .then(result => { toast.success("Creatives turned on successfully"); setCreativeStatusModal(false); setloadershown(false) })
      .catch(error => { toast.error("Internal server error occurred"); setCreativeStatusModal(false) });
  }

  const handle_search_lineitem_name = (e) => {
    if (!/[a-zA-Z]/.test(e.target.value)) {
      setShow_div(false);
      setLineitem_list([]);
    }
    if (/[a-zA-Z]/.test(e.target.value)) {
      setSpinner(true)
      setShow_div(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "name": e.target.value,
        "campaign_id": campaignId
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManager/filter-lineitem`, requestOptions)
        .then(response => response.json())
        .then(result => { setLineitem_list(result?.data); setSpinner(false) })
        .catch(error => console.log('error', error));
    }
  }


  let current = new Date();
  let cDate = current.getDate() + '/' + (current.getMonth() + 1) + '/' + current.getFullYear();

  let hours = current.getHours();
  let minutes = current.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12' in PM

  let cTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;

  let dateTime = cDate + ' ' + cTime;

  const export_pdf = () => {
    setloadershown(true);
  
    html2canvas(document.getElementsByClassName('content')[0], {
      scale: 2, // Adjust scale as needed
      scrollX: 0,
      scrollY: 0,
    }).then((canvas) => {
      console.log(pageLimit,"")
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const ratio = Math.min(pdfWidth / canvas.width, pdfHeight / canvas.height);
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save("Lineitem_General.pdf");
      setPdfTrue(false);
      setHide_filter(true)
      setloadershown(false);
      setPageLimit(10)
    }).catch((error) => {
      console.error("Error generating PDF:", error);
      setloadershown(false);
    });
  };
  
  

  useEffect(() => {
    if(pdfTrue){
      export_pdf()
    }
  }, [pdfTrue])



  return (
    <div className="content_outer">
      <Loader showLoader={loadershown} />
      <div className="cmn_header_top">
        <h3>

          <img src="/assets/images/chevron_left.svg" onClick={() => navigation("/campaign/mediaplan", {
            state: {
              dealid,
              deal_id,
              campaignId,
              advertiser,
              clientId,
              total_budget,
              sum_budget,
              startdate,
              enddate,
              currency_code,
              remainingBudget,
              campaignStatus
            }
          })} />
          Campaign Manager</h3>
        <div className="cmn_header__search position-relative">
          <AiOutlineSearch size={24} />	<input type="text" placeholder="Search by Id or Line Item" value={searchValue} onBlur={(e) => handleIdAndCampaign(e)} onChange={(e) => { handle_search_lineitem_name(e); setSearchValue(e.target.value); setSearch(true) }} onClick={() => { setShow_div(false); setLineitem_list([]) }} />
          {show_div && <div className="search_dropown">
            {spinner &&
              <div className="p-3 text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>

              </div>
            }
            <ul>
              {
                lineitem_list?.map((data, index) => {
                  return (
                    <li key={index} onClick={() => { setCampaignSearch(data?.name); setShow_div(false); setSearchValue(data?.name) }}>{data?.name}</li>
                  )
                })
              }
            </ul>
          </div>}
        </div>
        {(permission?.Permissions[23] || permission?.SubGroupid.includes(5)) &&
          <div className="billableDivDropdown">
            {showBillableMenu ?
              <Dropdown>
                <Dropdown.Toggle className="billableDropdown">
                  {campaign_detail?.is_billable == 0 && 'Not to bill'}
                  {campaign_detail?.is_billable == 1 && 'Ready to bill'}
                  {campaign_detail?.is_billable == 2 && 'Billed'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {billableOptions.map((option) =>
                    (
                      (campaign_detail?.is_billable !== option.id &&
                        ((permission?.SubGroupid.includes(5) && option.id !== 0) ||
                          (!(permission?.SubGroupid.includes(5)) && option.id !== 2)))
                    ) && (
                      <Dropdown.Item
                        key={option.id}
                        onClick={() => {
                          !(campaign_detail?.is_billable == 0 && option.id == 2) ?
                            handleBillable(option.id) :
                            toast.error('It is not possible to make this change')
                        }}
                      >
                        {option.description}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>

              </Dropdown>
              :
              <Dropdown className="billableDropdownBilled">Billed</Dropdown>
            }
          </div>
        }
        <div className="bill_btn" title="Print Pdf" onClick={() => {setHide_filter(false);setPageLimit(total_records);setPageNo(1) }}>
          <button className="cmn_btn cmn_bordered_btn">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="24" width="24" className="me-2" xmlns="http://www.w3.org/2000/svg"><path d="M96 96V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64H160V256zM224 96c0 35.3-28.7 64-64 64V96h64zM576 256v64H512c0-35.3 28.7-64 64-64zM512 96h64v64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V360c0 66.3 53.7 120 120 120H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72V120z"></path></svg>
            Print Pdf
          </button>
        </div>
        {permission?.Permissions[5] && <div className="">
          {campaign_detail?.lineItemTotalBudget <
            campaign_detail?.budge ? (
            <button
              className="cmn_btn ms-0"
              onClick={() => {
                setModalShow(true);
                reset_filter();
                setEditId()
              }}
            >
              Add New Line Item{" "}
              <FontAwesomeIcon className="ms-2" icon={faPlus} />
            </button>
          ) : (
            <button
              className="cmn_btn ms-0"
              onClick={() => {
                setModal_open(true);
              }}
            >
              New Line Item{" "}
              <FontAwesomeIcon className="ms-2" icon={faPlus} />
            </button>
          )}
        </div>}

      </div>
      <div className="content">
        <div className="App">
          <div className="mediaplan_details kite_space_cmn">
            {pdfTrue ? <div className=" p-3 pdf_head mb-5">
              <div className="d-flex justify-content-between align-items-center mb-5">
              <img src="/assets/images/kidtech.svg" width="150" />
                <img src="/assets/images/mariano.png" width="150" />
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <h2 style={{ fontSize: "32px", fontWeight: "bold" }} className="mb-0 header_title">{advertiser}</h2>
                <h2 style={{ fontSize: "32px" }} className="mb-0 header_title">{dateTime}</h2>
              </div>
            </div> : null}
            <div className="app">
              {menu_item && (
                <ContextMenu id="my-context-menu-1">
                  <ContextMenuItem onClick={handleDuplicate}>
                    Duplicate
                  </ContextMenuItem>
                </ContextMenu>
              )}
            </div>
            <div className="title__active">
              <h3>{deal_detail?.campaign}</h3>
              <div className="status_badge">
                {campaign_detail?.statusNavigation?.description == "PAUSE " && <p><AiOutlineCheckCircle size={24} className="me-2" />Pause</p>}
                {campaign_detail?.statusNavigation?.description == "ACTIVE" && <p><AiOutlineCheckCircle size={24} className="me-2" />Active</p>}
                {campaign_detail?.statusNavigation?.description == "DRAFT" && <p><AiOutlineCheckCircle size={24} className="me-2" />DRAFT</p>}
                {campaign_detail?.statusNavigation?.description == "INACTIVE" && <p><AiOutlineCheckCircle size={24} className="me-2" />INACTIVE</p>}
              </div>
            </div>
            <Row>
              <Col lg={filteredLineData?.length === 0 ? 8 : 8}>
                <div className="advert_tabel">
                  <div className="media_plan_info_list">

                    <ul className="header__media">
                      <li>
                        <MdOutlineHandshake size={24} />
                        <strong>Campaign Id</strong><p> {campaign_detail?.id}</p>
                      </li>
                      <li>
                        <BiCalendar size={20} />
                        <strong>Start Date:</strong>
                        <p>
                          {""}
                          <Moment format="DD/MM/YYYY">
                            {campaign_detail?.date_from}
                          </Moment>
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li>
                        <strong>End Date:</strong>
                        <p>
                          {""}
                          <Moment format="DD/MM/YYYY">
                            {campaign_detail?.date_to}
                          </Moment>
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li><strong>Flight days</strong><p>{<p>{moment(campaign_detail?.date_to).diff(
                        moment(campaign_detail?.date_from),
                        "days"
                      )}</p>}</p></li>
                      <li className="block_divider"><span></span></li>
                      <li><strong>Remaining days</strong><p>{Date.now() > moment(campaign_detail?.date_from) ? moment(campaign_detail?.date_to).diff(
                        moment(),
                        "days"
                      ) : moment(campaign_detail?.date_to).diff(
                        moment(campaign_detail?.date_from),
                        "days"
                      )}</p></li>
                    </ul>
                    <ul>
                      <li>
                        <strong>Advertiser</strong><p> {advertiser}</p>
                      </li>

                      <li>
                        <strong>Product</strong>
                        <p> {campaign_detail?.productNavigation?.name}</p>
                      </li>
                      <li>
                        <strong>Currency</strong><p> {currency_code}</p>
                      </li>

                      <li>
                        <strong>Status</strong>
                        <p> {campaign_detail?.statusNavigation?.description}</p>
                      </li>
                      <li>
                        {permission?.Permissions[5] && <div className="pdf_icon " title="Edit Campaign" onClick={editCampaign}><img src="/assets/images/edit_fill.svg" /></div>}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4} >

                <div>

                  <ul className="budget single_budget">
                    <li>
                      Budget
                    </li>
                    <li className="progress_result">
                      <div className="w-100">
                        <ProgressBar className="bar_color w-100" now={percentage} />
                      </div>
                      <p>Total: <strong> {campaign_detail?.budge?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}</strong></p>
                    </li>
                    <li className="total_used">
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Used : <strong>{campaign_detail?.lineItemTotalBudget ? campaign_detail?.lineItemTotalBudget?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        }) : "0"}</strong></p>
                      </div>
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Remaining : <strong>{(campaign_detail?.budge -
                          campaign_detail?.lineItemTotalBudget)?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</strong></p>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="">
          <div className="new_compaign">
            {/* tabs starts here */}
            <div className="mediaplan__tabs">
              <div className=" border-bottom-0 mb-0">


                <div className="">
                  <div className="campiagn_outer border-0 p-0">
                    {/* <h3 className="filter_title">Filter</h3> */}
                   { hide_filter && <div className="cmn__filters campiagn_filter filter_menu without_filter_head line_items_filter">
                      <ul>
                        <li><img src="/assets/images/filter_list.svg" /></li>
                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {tempInventory} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {inventoryId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setInventoryTypeId(option?.id); setSearch(true); setTempInventory(option?.description) }}>{option?.description}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li>

                          <Form.Group className=" owner_filter mt-0 round_select" controlId="dateselect">
                            {/* <Form.Label className="center_label lbl_position dropdown-basic">Start Date</Form.Label> */}
                            <DateRangePicker
                              initialSettings={{
                                autoUpdateInput: false,
                                showDropdowns: true,
                              }}
                              onApply={handleApply}
                              onCancel={handleCancel}
                              ref={pickerref}
                            >
                              <Form.Control
                                type="text"
                                placeholder="yyyy/mm/dd"
                                className="form-control"
                                // value={startend.start + " " + startend.end}
                                value={startend.start && startend.end ? startend.start + " " + startend.end : "Start Date"}
                              />
                            </DateRangePicker>
                          </Form.Group>

                        </li>
                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {tempFormat}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {formatId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setFormatTypeId(option?.id); setSearch(true); setTempFormat(option?.name) }}>{option?.name}</Dropdown.Item>
                                  </>
                                )

                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>

                        <li className="smallerLi">
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {tempOffer}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {offerId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setOfferTypeId(option?.id); setSearch(true); setTempOffer(option?.descripcion) }}>{option?.descripcion}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>

                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {tempCountry}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {countryId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setCountryTypeId(option?.id); setSearch(true); setTempCountry(option?.labelKey) }}>{option?.labelKey}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>


                        <li className="smallerLi">
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(tempStatus))}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {statusId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setStatusTypeId(option?.id); setSearch(true); setTempStatus(option.description) }}>{upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(option.description))}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <button className="cmn_search_btn"
                          onClick={() => (inventoryTypeId || offerTypeId || statusTypeId || countryTypeId || formatTypeId || campaignSearch) ? getSearchedData() : toast.error("Search field can't be empty")}
                        >
                          Search
                        </button>
                        <button className={!searchSet ? "clear__btn" : "clear__btn1"} onClick={() => {
                          getPaginateData()
                          setStatusTypeId();
                          setCountryTypeId();
                          setOfferTypeId();
                          setFormatTypeId();
                          setInventoryTypeId();
                          setSearchValue("");
                          setCampaignSearch("")
                          setSearchCampaignId("")
                          setSearchValue("")
                          setSearch(false)
                          setTempInventory("Inventroy")
                          setTempCountry("Country")
                          setTempFormat("Format")
                          setTempOffer("offer")
                          setTempStatus("Status")
                          setStartend({
                            start: "",
                            end: "",
                          });
                          pickerref.current.value = "";

                        }}>Clear</button>



                      </ul>
                    </div>}

                    <div className="advert_tabel campign_index_table">
                      <ContextMenuTrigger id="my-context-menu-1" >
                        <Table
                          responsive
                          className={
                            filtered_items?.results?.length === 0
                              ? "no_data"
                              : null
                          }
                        >
                          <thead>
                            <tr>
                              <th
                                onClick={() => handleSort("id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Line Item ID
                              </th>
                              <th
                                onClick={() => handleSort("name")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                LI Name{" "}
                              </th>
                              <th
                                onClick={() => handleSort("inventory_type_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Inventory Type{" "}
                              </th>
                              <th
                                onClick={() => handleSort("format_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Format{" "}
                              </th>
                              <th
                                onClick={() =>
                                  handleSort("inversion_offerType_id")
                                }
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Offer Type
                              </th>
                              <th
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Country{" "}
                              </th>
                              <th
                                onClick={() => handleSort("budget")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Budget in{" "} {currency_code}
                              </th>
                              <th
                                onClick={() => handleSort("sell")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Sell Rate in{" "}{currency_code}
                              </th>
                              <th
                                onClick={() => handleSort("inversion_goal")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Goal{" "}
                              </th>
                              <th
                                onClick={() => handleSort("date_from")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Start date
                              </th>
                              <th
                                onClick={() => handleSort("date_to")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                End date
                              </th>
                              <th
                                onClick={() => handleSort("status_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Status{" "}
                              </th>
                              <th
                                onClick={() => handleSort("status_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Delivery Status{" "}
                              </th>
                              <th style={{ opacity: "0" }}>pdf</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredLineData?.length === 0 && (
                              <div className="No_Data">
                                {" "}
                                <img
                                  className="nodata"
                                  src="/assets/images/notfound.svg"
                                />
                              </div>
                            )}
                            {(
                              filteredLineData?.map((curelem, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.id}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.name}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                    >
                                      {
                                        curelem?.inventoryNavigation
                                          ?.description
                                      }
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.formatNavigation?.name}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {
                                        curelem?.offerTypeNavigation
                                          ?.descripcion
                                      }
                                    </td>
                                    {
                                      <td
                                        onContextMenu={() => {
                                          setId(curelem?.id);
                                          setMenu_item(true);
                                        }}
                                        className="text-start"
                                        onClick={() => {
                                          gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                            ?.description);
                                        }}
                                      >
                                        {/* {curelem?.countryList?.map((data) => data?.country?.labelKey)} */}
                                        {curelem?.countryList?.flatMap(
                                          (data, index) => {
                                            return `${data?.country?.ab?.toUpperCase()},`;
                                          }
                                        )}
                                      </td>
                                    }
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.inversion_budget?.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.offerTypeNavigation?.id === 6 ||
                                        curelem?.offerTypeNavigation?.id === 7
                                        ? curelem?.inversion_sell_rate?.toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 4 }
                                        )
                                        : curelem?.inversion_sell_rate?.toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 2 }
                                        )}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {parseInt(
                                        curelem?.inversion_goal
                                      )?.toLocaleString()}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      <Moment format="DD/MM/YYYY">
                                        {curelem?.date_from}
                                      </Moment>
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      <Moment format="DD/MM/YYYY">
                                        {curelem?.date_to}
                                      </Moment>
                                    </td>

                                    <td>
                                      <Dropdown className="status_dropdown media_plan_dropdown status_design">
                                        <Dropdown.Toggle className={`filter_btn ${curelem?.lineItemStatusNavigation?.description.toLowerCase()}`}>

                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}
                                          <span>  {
                                            upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(curelem?.lineItemStatusNavigation
                                              ?.description))
                                          }</span>
                                          <FaChevronRight className="ms-auto" />
                                        </Dropdown.Toggle>
                                        {permission?.Permissions[5] && (
                                          <Dropdown.Menu>
                                            {line_item_status?.map(
                                              (status, index) => {
                                                return (
                                                  <Dropdown.Item
                                                    className={status?.description.toLowerCase()}
                                                    key={`status_${index}`}
                                                    onClick={() => {
                                                      StatusPopup(
                                                        curelem?.id,
                                                        status?.id,
                                                        status?.description,
                                                        curelem?.creativeCount
                                                      );
                                                      setLineItemId(curelem?.id)
                                                    }}
                                                  >
                                                    {status?.description == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                                    {status?.description == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                                    {status?.description == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                                    {status?.description == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}
                                                    {upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(status?.description))}
                                                  </Dropdown.Item>
                                                );
                                              }
                                            )}
                                          </Dropdown.Menu>
                                        )}
                                      </Dropdown>
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-center"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.platform_delivering_status ? <img title="This lineitem has at least 1 impression delivered" src="/assets/images/check_circle.svg" /> : <img title="This lineitem do not have any impressions delivered" src="/assets/images/cancel_FILL.svg" />}
                                    </td>

                                    <td className="text-start" >
                                      <div className="icons_downloads">
                                        <div className="pdf_icon" title="View Placement List"
                                          onContextMenu={() => {
                                            setId(curelem?.id);
                                            setMenu_item(true);
                                          }}
                                          onClick={() =>
                                            navigation(
                                              `/campaignlist/${curelem?.id}`
                                            )
                                          }>
                                          <img src="/assets/images/play_circle.svg" />
                                        </div>
                                        {permission?.Permissions[5] && <div className="pdf_icon" title="Edit Line Item"
                                          onContextMenu={() => {
                                            setId(curelem?.id);
                                            setMenu_item(true);
                                          }}
                                          onClick={() => {
                                            handleEditLineItem(curelem?.id, curelem?.lineItemStatusNavigation?.description)
                                          }}>
                                          <img src="/assets/images/edit_fill.svg" />
                                        </div>}
                                        <div className="pdf_icon" title="View Creatives" onClick={() => {
                                          gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                            ?.description);
                                        }}>
                                          <img src="/assets/images/description_file.svg" />
                                        </div>
                                        <div className="pdf_icon" title="Change history" style={{ cursor: "pointer" }}>
                                          <img src="/assets/images/clock.svg" onClick={() => navigation("/lineItemshistory", { state: { id: curelem?.id, pathname: location?.pathname, campaignId: campaignId, advertiser: advertiser, currency_code: currency_code, dealid: dealid, deal_id: deal_id } })} />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </Table>
                      </ContextMenuTrigger>
                      {(filteredLineData?.length !== 0) &&(hide_filter) && <div className="cmn__pagination">
                        <ul>
                          <span>Rows per page</span>
                          <li className="no__rows">
                            <select
                              name=""
                              id=""
                              onChange={(e) => setPageLimit(e.target.value)}

                            >
                              {/* <option value="5">5</option> */}
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                            </select>
                          </li>
                          <li className="total_pages me-3">
                            <span className="active_pages">{current_Page}</span> of <span>{page_Count}</span>
                          </li>
                          {pageNo != 1 && <li>
                            <BsChevronBarLeft onClick={() => setPageNo(1)} />
                          </li>}
                          {pageNo != 1 && <li

                          >
                            <BsChevronLeft onClick={() => setPageNo(pageNo - 1)} />
                          </li>}
                          {pageNo != page_Count && <li

                          >
                            <BsChevronRight onClick={() => setPageNo(pageNo + 1)} />
                          </li>}
                          {pageNo != page_Count && <li>
                            <BsChevronBarRight onClick={() => setPageNo(page_Count)} />
                          </li>}
                        </ul>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tabs ends here */}
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Line Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddLineItems
            dealid={dealid}
            dealCurrency={dealCurrency}
            start_date={campaign_detail?.date_from}
            end_date={campaign_detail?.date_to}
            budget_validate={campaign_detail?.budge - campaign_detail?.lineItemTotalBudget}
            campaignId={campaignId}
            setEditLineItemModal={setModalShow}
            onRefresh={searchFilter}
            getcampain={getPaginateData}
            getData={getcampain}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={editLineItemModal}
        onHide={() => setEditLineItemModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Line Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <NewLineItem
            setEditLineItemModal={setEditLineItemModal}
            dealid={dealid}
            deal_id={dealid}
            campaignId={campaignId}
            dealCurrency={dealCurrency}
            searchFilter={getPaginateData}
            setCreativeModalShow={setEditLineItemModal}
            total_Budget={campaign_detail?.budge}
            sum_Budget={campaign_detail?.lineItemTotalBudget}
            startDate={campaign_detail?.date_from}
            endDate={campaign_detail?.date_to}
            filter={searchFilter}
            lineItemId={editId}
            getData={getcampain}
            getcampain={getPaginateData}
            getLineItemData={true}
            newData={true}
            onRefresh={searchFilter}
            offerHide={true}
            status_id={editStatus}
            editStatus={editStatus}
          /> */}
          {/* edit line */}
          <EditLineItems
            setEditLineItemModal={setEditLineItemModal}
            lineItemId={editId}
            startDate={campaign_detail?.date_from}
            endDate={campaign_detail?.date_to}
            total_Budget={campaign_detail?.budge}
            sum_Budget={campaign_detail?.lineItemTotalBudget}
            dealid={dealid}
            campaignId={campaignId}
            onRefresh={searchFilter}
            getcampain={getPaginateData}
            getData={getcampain}
            editStatus={editStatus}
            dealCurrency={dealCurrency}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={creativemodalShow}
        onHide={() => setCreativeModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header closeButton>
          <Modal.Title>New Creative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCreatives
            setCreativeModalShow={setCreativeModalShow}
            dealid={dealid}
            deal_id={deal_id}
            campaignId={campaignId}
            lineItem={inserted_lineitem_id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={statusmodalShow}
        onHide={() => {
          setStatusModalShow(false);
          resetstatus();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>

          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>

            <h4 className="text-center border-bottom pb-5"> {statusmodel?.messageText}</h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={() => setStatusModalShow(false)}
              >
                Cancel
              </button>
              <button
                className={statusmodel?.buttonClass}
                onClick={() => {
                  updateStatus();
                }}
              >
                {statusmodel?.buttonText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalcampaignShow}
        onHide={() => setModalCampaignShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {campaign_detail ? "Update Campaign" : "New Campaign"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCompaign
            setModalShow={setModalCampaignShow}
            dealid={dealid}
            deal_id={deal_id}
            clientId={clientId}
            start_date={startdate}
            total_Budget={total_budget}
            sum_Budget={sum_budget}
            end_date={enddate}
            dealCurrency={dealCurrency}
            searchFilter={getCampaign}
            campaign_detail={campaign_detail}
            onRefresh={searchFilter}
            getcampain={getcampain}
            budget_used={campaign_detail?.lineItemTotalBudget}
            currency_code={currency_code}
            remainingBudget={remainingBudget}
            campaignStat={campaign_detail?.statusNavigation?.description}
            lineitem_count={campaign_detail?.lineitemCount}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modal_open}
        onHide={() => setModal_open(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6 className="text-center">
            Because there is no extra budget decrease the budget from Line-item
            and return .
          </h6>
        </Modal.Body>
      </Modal>

      <Modal
        show={duplicate_modal}
        onHide={() => setDuplicate_modal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Duplicate LineItem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DuplicateLineItem
            id={id}
            start_date={campaign_detail?.dateFrom}
            end_date={campaign_detail?.dateTo}
            setDuplicate_modal={setDuplicate_modal}
            getcampain={getcampain}
            searchFilter={searchFilter}
            dealCurrency={dealCurrency}
          />
        </Modal.Body>
      </Modal>



      <Modal
        show={statusLineItem}
        onHide={() => setStatusLineItem(false)}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >


        <Modal.Body className="p-4">
          <div className='text-center mb-4'>
            <FiAlertCircle className='alert_icon' size={64} />
          </div>
          <h4 className="text-center border-bottom pb-5">The Campaign is {campaignStatus}, Do you want to Active Campaign!!</h4>
          <div className="d-flex justify-content-end mt-5">
            <button className="me-4 cancel_cm_btn" onClick={() => setStatusLineItem(false)}>Cancel</button>
            <button className="cmn_btn ms-2 " onClick={handleStatusUpdate}>Confirm</button>
          </div>

        </Modal.Body>
      </Modal>


      <Modal
        show={creativeStatusModal}
        onHide={() => setCreativeStatusModal(false)}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >


        <Modal.Body className="p-4">
          <div className='text-center mb-4'>
            <FiAlertCircle className='alert_icon' size={64} />
          </div>
          <h4 className="text-center border-bottom pb-5"> Do you want to turn on all creatives ?</h4>
          <div className="d-flex justify-content-end mt-5">
            <button className=" me-4 cancel_cm_btn" onClick={() => setCreativeStatusModal(false)}>Cancel</button>
            <button className="cmn_btn ms-2" onClick={handleCreativeStatus}>Confirm</button>
          </div>




        </Modal.Body>
      </Modal>
    </div>
  );
};
export default LineItems;
